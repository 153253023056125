<div class="card">
    <div class="grid mb-7">
        <div class="col-12 xs:col-7">
            <div class="flex">
                <!-- Miniaturas -->
                <div class="flex flex-column w-2 justify-content-between" style="row-gap: 1rem;">
                    <ng-container *ngIf="!loading; else loadingSkeleton">
                        <img *ngFor="let image of productImages"
                            class="w-full cursor-pointer border-2 border-transparent transition-colors transition-duration-150 border-round"
                            [class.border-primary]="image === mainImage" [src]="image" (click)="changeMainImage(image)">
                    </ng-container>

                    <!-- Skeleton enquanto carrega -->
                    <ng-template #loadingSkeleton>
                        <p-skeleton width="100%" height="70px" *ngFor="let _ of [1,2,3,4]">
                        </p-skeleton>
                    </ng-template>
                </div>

                <!-- Imagem Principal -->
                <div class="pl-3 w-10 flex justify-content-center items-center">
                    <ng-container *ngIf="!loading; else loadingMainImage">
                        <img class="w-full h-auto border-2 border-transparent border-round object-contain max-h-96"
                            [src]="mainImage">
                    </ng-container>

                    <!-- Skeleton da imagem principal -->
                    <ng-template #loadingMainImage>
                        <div
                            style="width: 100%; height: 320px;">
                            <p-skeleton width="100%" height="100%"></p-skeleton>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>



        <div class="col-12  py-3 lg:pl-6">
            <div *ngIf="product">
                <div class="flex align-items-center text-xl font-medium text-900 mb-4">
                    {{ selectedChildProduct?.title || product.title }}
                </div>
                <div class="flex align-items-center justify-content-between mb-5">
                    <!-- Preço com desconto (compare_at_price) -->
                    <div *ngIf="(selectedChildProduct && +selectedChildProduct.compare_at_price > +selectedChildProduct.price) || 
                        (!selectedChildProduct && +product.compare_at_price > +product.price)"
                        class="flex-1 text-left mr-3">
                        <span class="text-900 font-medium text-3xl block line-through">
                            {{ selectedChildProduct?.compare_at_price || product.compare_at_price |
                            currency:'BRL':'symbol':'1.2-2' }}
                        </span>
                    </div>

                    <!-- Preço atual -->
                    <div class="flex-1 text-left">
                        <span class="text-900 font-medium text-3xl block">
                            {{ selectedChildProduct?.price || product.price | currency:'BRL':'symbol':'1.2-2' }}
                        </span>
                    </div>

                    <!-- Avaliação (estrelas) -->
                    <div class="flex-1 text-right">
                        <!-- <span>
                            <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                            <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                            <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                            <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                            <i class="pi pi-star text-600 mr-1"></i>
                        </span> -->
                    </div>
                </div>




            </div>

            <!-- Atributos Dinâmicos -->
            <!-- Atributos Dinâmicos -->
            <div *ngFor="let attribute of attributes" class="mb-5">
                <!-- Nome do Atributo -->
                <div class="mb-3 flex align-items-center justify-content-between">
                    <span class="font-bold text-900">{{ attribute.name }}</span>
                </div>
                <!-- Valores do Atributo -->
                <div class="grid grid-nogutter align-items-center">
                    <div *ngFor="let value of attribute.values"
                        class="col h-3rem border-1 border-300 text-900 inline-flex justify-content-center align-items-center flex-shrink-0 border-round mr-3 cursor-pointer hover:surface-100 transition-duration-150 transition-colors"
                        [class.border-blue-500]="selectedAttributes[attribute.name] === value"
                        [class.text-blue-500]="selectedAttributes[attribute.name] === value"
                        (click)="selectAttribute(attribute.name, value)">
                        {{ value }}
                    </div>
                </div>
            </div>


            <div class="font-bold text-900 mb-3">Quantity</div>
            <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between">
                <p-inputNumber [(ngModel)]="quantity" [showButtons]="true" buttonLayout="horizontal"
                    spinnerMode="horizontal" inputStyleClass="w-3rem text-center" decrementButtonClass="p-button-text"
                    incrementButtonClass="p-button-text" [min]="0" [max]="maxQuantity"
                    class="p-element p-inputwrapper p-inputwrapper-filled" [ngStyle]="{ width: '5rem' }">
                </p-inputNumber>

                <div class="flex align-items-center flex-1 mt-3 sm:mt-0 ml-0 sm:ml-7 btnCart">
                    <button pButton pRipple label="Add to Cart" (click)="addToCart()"
                        [disabled]="selectedChildProduct?.tracked && inventoryQuantity === 0"
                        class="p-element p-ripple flex-1 mr-5 p-button p-component">
                    </button>

                </div>
                <div *ngIf="selectedChildProduct?.tracked && inventoryQuantity === 0" class="text-red-500 mt-2">
                    Item sem estoque!
                </div>
            </div>


        </div>
    </div>
    <p-tabView>
        <p-tabPanel header="Details">
            <div class="text-900 font-bold text-3xl mb-4 mt-2">Product Details</div>
            <p class="line-height-3 text-600 p-0 mx-0 mt-0 mb-4" *ngIf="product" [innerHTML]="product.description"></p>
        </p-tabPanel>
    </p-tabView>
</div>


<p-dialog header="Aguarde" [(visible)]="loading" [modal]="true" [closable]="false" [showHeader]="true">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>