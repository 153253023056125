import { Directive, ElementRef, HostListener, Renderer2, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appDraggable]',
  standalone: true
})
export class DraggableDirective implements OnChanges, OnInit {
  @Input() resetPosition: boolean = false;
  @Input() position: string = 'center';
  private isDragging = false;
  private startX = 0;
  private startY = 0;
  private x = 0;
  private y = 0;
  private initialX = 0;
  private initialY = 0;
  private dragThreshold = 5;

  @Output() dragStart = new EventEmitter<void>();
  @Output() dragEnd = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'move');
  }

  ngOnInit(): void {
    this.setInitialPosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['position']) {
      this.setInitialPosition();
    }
    if (changes['resetPosition'] && this.resetPosition) {
      this.resetDragPosition();
    }
  }

  private setInitialPosition(): void {
    let top: string | null = null;
    let left: string | null = null;
    let bottom: string | null = null;
    let right: string | null = null;

    const dialogWidth = this.elementRef.nativeElement.offsetWidth;
    const dialogHeight = this.elementRef.nativeElement.offsetHeight;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    switch (this.position) {
      case 'topleft':
        top = '0px';
        left = '0px';
        break;
      case 'topright':
        top = '0px';
        right = '0px';
        break;
      case 'bottomleft':
        bottom = '0px';
        // left = '200px';
        left = '0px';
        break;
      case 'bottomright':
        bottom = '0px';
        right = '0px';
        break;
      default: // 'center'
        top = `${(viewportHeight - dialogHeight) / 2}px`;
        left = `${(viewportWidth - dialogWidth) / 2}px`;
        break;
    }

    // Set initial `top`, `left`, `bottom`, `right` based on calculated position
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', top);
    this.renderer.setStyle(this.elementRef.nativeElement, 'left', left);
    this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', bottom);
    this.renderer.setStyle(this.elementRef.nativeElement, 'right', right);

    // Set initial offsets
    this.initialX = left ? parseInt(left, 10) : (viewportWidth - dialogWidth) / 2;
    this.initialY = top ? parseInt(top, 10) : (viewportHeight - dialogHeight) / 2;
    this.x = 0;
    this.y = 0;

    // Reset transform to ensure it starts without offset
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `translate(0, 0)`);

    // Reset transform on parent 'p-element'
    const parentElement = this.findClosestParentWithClass(this.elementRef.nativeElement, 'p-element');
    if (parentElement) {
      this.renderer.setStyle(parentElement, 'transform', `translate(0, 0)`);
    }
  }

  private resetDragPosition(): void {
    this.x = 0;
    this.y = 0;
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `translate(0, 0)`);
  }

  // Mouse events
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.isDragging = false;
    this.startX = event.clientX - this.x;
    this.startY = event.clientY - this.y;
    event.preventDefault();

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  private onMouseMove = (event: MouseEvent): void => {
    this.handleDrag(event.clientX, event.clientY);
  };

  private onMouseUp = (): void => {
    this.stopDragging();
  };

  // Touch events
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    const touch = event.touches[0];
    this.isDragging = false;
    this.startX = touch.clientX - this.x;
    this.startY = touch.clientY - this.y;
    event.preventDefault();

    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);
  }

  private onTouchMove = (event: TouchEvent): void => {
    const touch = event.touches[0];
    this.handleDrag(touch.clientX, touch.clientY);
  };

  private onTouchEnd = (): void => {
    this.stopDragging();
  };

  // Handle drag logic to update position
  private handleDrag(clientX: number, clientY: number): void {
    const dx = Math.abs(clientX - this.startX);
    const dy = Math.abs(clientY - this.startY);

    if (!this.isDragging && (dx > this.dragThreshold || dy > this.dragThreshold)) {
      this.isDragging = true;
      this.dragStart.emit();
    }

    if (this.isDragging) {
      this.x = clientX - this.startX;
      this.y = clientY - this.startY;

      // Apply only `translate(x, y)` without affecting initial position set by `top` and `left`
      //this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `translate(${this.x}px, ${this.y}px)`);

      // Aplica o movimento nos elementos pais relevantes
      this.applyTransformToParentElements();
    }
  }

  private applyTransformToParentElements(): void {
    const parentElement = this.findClosestParentWithClass(this.elementRef.nativeElement, 'p-element');
    const contentElement = this.findClosestParentWithClass(this.elementRef.nativeElement, 'p-dialog-content');

    // Aplica o mesmo transform aos elementos pais
    const transformValue = `translate(${this.x}px, ${this.y}px)`;

    if (parentElement) {
      this.renderer.setStyle(parentElement, 'transform', transformValue);
    }

    // if (contentElement) {
    //   this.renderer.setStyle(contentElement, 'transform', transformValue);
    // }
  }

  private findClosestParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
    let parent = element.parentElement;
    while (parent) {
      if (parent.classList.contains(className)) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  }

  private stopDragging(): void {
    if (this.isDragging) {
      this.dragEnd.emit();
    }
    this.isDragging = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
  }
}
