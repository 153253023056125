import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-shopify-auth',
  template: '<p>Processing Shopify authentication...</p>'
})
export class ShopifyAuthComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private productService: ProductService, private http: HttpClient) { }

  ngOnInit(): void {
    // Capturar os parâmetros da URL
    this.route.queryParams.subscribe(params => {
      const shop = params['shop'];
      const code = params['code'];
      const state = params['state'];

      if (shop && code) {
        // Enviar o código ao back-end para troca pelo access_token
        this.processAuthorization(code, shop);
      } else {
        console.error('Shopify auth: parâmetros ausentes');
        // Redirecionar ou exibir um erro ao usuário
      }
    });
  }

  private processAuthorization(code: string, shop: string): void {
    console.log('Código recebido:', code);
    console.log('Loja:', shop);

    const url = `https://${shop}/admin/oauth/access_token`;
    const body = {
      client_id: '0d45482ed6adf4a59e4e8fc7f367b9ab',
      client_secret: '296b8187f4439cfb18ba30feb0414404',
      code: code,
    };

    this.http.post(url, body).subscribe({
      next: (response: any) => {
        const accessToken = response.access_token;

        if (accessToken) {
          this.saveAccessToken(shop, accessToken);
        } else {
          console.error('Erro ao obter o access_token');
        }
      },
      error: (error: any) => {
        console.error('Erro na autenticação Shopify:', error);
      },
    });
  }

  private saveAccessToken(shop: string, accessToken: string): void {
    const customPayload = {
      access_token: accessToken, // Adiciona o access_token
      form_value: {
        domain: shop, // Domínio da loja
      },
    };
  
    this.productService.createAppsStore2('shopify', customPayload).subscribe({
      next: (response) => {
        console.log('Integração criada com sucesso:', response);
        // Redireciona ou atualiza o estado após sucesso
        //this.router.navigate(['/apps']); 
      },
      error: (error) => {
        console.error('Erro ao salvar o app da loja:', error);
      },
    });
  }
}
