import { Component, ElementRef, ViewChild, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { PedidosService } from 'src/app/services/pedidos.service';
import { AvatarService } from 'src/app/services/avatar.service';
import { ChangeDetectorRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { Observable, fromEvent } from 'rxjs';
import { from, defer } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { tap, catchError, finalize, switchMap } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn
} from '@angular/forms';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnDestroy {
  pageOptions: any[] = [];
  activeOption: number = 1; 
  pageConfigs?: any;
  user_name: string = '';
  user_sobrenome: string = '';
  chat_name: string = '';
  email: string = '';
  senha_atual: string = '';
  nova_senha: string = '';
  confirm_senha: string = '';
  logoPreviewUrl: any = null;
  logoSelected: boolean = false;
  sliderValueViews: number = 333;
  sliderValueSlicks: number = 0;
  selectedFile: File | null = null;
  constructor(
    private pedidosService: PedidosService,
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private avatarService: AvatarService,
    private confirmationService: ConfirmationService
  ) {
    this.issueForm = this.fb.group({
      // defina os controles do formulário aqui
      // exemplo: 'name': ['']
    });
  }

  @ViewChild('sidebar') sidebar!: ElementRef;
  ngAfterViewInit() {
  }

  changeEmal: boolean = false;
  emailFisrt?: string;
  user_id?: number;
  displaySidebar: boolean = false;

  manageOverlay() {
    const overlays = document.querySelectorAll('.p-component-overlay');
    const overlayEnters = document.querySelectorAll('.p-component-overlay-enter');

    overlays.forEach((overlay: Element) => {
      (overlay as HTMLElement).style.display = 'none';
    });

    overlayEnters.forEach((overlayEnter: Element) => {
      (overlayEnter as HTMLElement).style.display = 'none';
    });

    this.cdr.detectChanges();
  }
  manageOverlay2() {
    const overlay = document.querySelector('.p-component-overlay') as HTMLElement;
    if (this.displaySidebar) {
      overlay.style.display = 'block';
    }
  }
  toggleSidebar() {
    this.displaySidebar = false;
    setTimeout(() => {
      this.manageOverlay();
    }, 100);

  }

  displayAguarde: boolean = false;
  ngOnInit() {
    this.displayAguarde = true;


    // const overlay = document.querySelector('.p-component-overlay') as HTMLElement;
    // console.log(overlay.style.display)
    // console.log('disssssssssssssssssss')

    this.pageOptions = [
      { name: 'Infos', value: 1 },
      { name: 'Segurança', value: 2 },
      { name: 'Assinatura', value: 3 },
    ];
    this.pageConfigs = this.pageOptions[0];


    let user_id = localStorage.getItem('user_id');
    if (user_id === null) {
      console.error('User ID is not available in localStorage');
      // Você pode tratar esse caso como achar melhor
    } else {
      let numericUserId = parseInt(user_id, 10);
      this.user_id = numericUserId;
      this.pedidosService.detailUser(numericUserId).subscribe({
        next: (response) => {

          this.user_name = response.name;
          this.user_sobrenome = response.surname;
          this.chat_name = response.username;
          this.email = response.email;
          this.emailFisrt = response.email;
          if (response.photo) {
            this.logoPreviewUrl = response.photo.split('?')[0];
            this.imgUrl = response.photo.split('?')[0];
          }


          this.displayAguarde = false;
          this.toggleSidebar();
          // setTimeout(() => {
          //   this.displayAguarde = false;
          // }, 10000)
        },
        error: (error) => {

        }
      });
    }


    /// quantidade de slicks countslicks
    this.pedidosService.countSlicks().subscribe({
      next: (res) => {
        console.log('Slicks e Views', res);
    
        // Configurando o slider de Slicks
        if (res.slicks > 10) {
          this.sliderValueSlicks = 10;
          this.showCountSlicks = false;
        } else {
          this.sliderValueSlicks = res.slicks;
          this.showCountSlicks = true; // Mostra o valor dos slicks quando for <= 10
        }
    
        // Configurando o slider de Views
        if (res.views > 1000) {
          this.sliderValueViews = 1000; // Limite para o slider de views
          this.showCountViews = false;
        } else {
          this.sliderValueViews = res.views;
          this.showCountViews = true;
        }
      },
      error: (error) => {
        console.error("Erro ao buscar dados de Slicks e Views:", error);
      }
    });
    

  }
  openDialogEmail() {
    console.log('openDialogEmail');
    this.showDialog = true
    console.log('openDialogEmail: ' + this.showDialog);
  }
  loadingBtnSecurity: boolean = false;
  changePassword() {
    //this.checkLogin();
    this.loadingBtnSecurity = true;
    if (this.checkPass && !this.errorPassword && !this.errorConfirmPassword) {
      this.issueForm = this.fb.group({
        password: this.nova_senha
      });
      this.pedidosService.changePassword(this.issueForm.value).subscribe({
        next: (res: any) => {
          console.log('res', res);
          this.loadingBtnSecurity = false;
          this.displaySidebar = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Senha alterada',
            detail: 'A senha foi alterada com sucesso!'
          });
        },
        error: (error) => {
          this.loadingBtnSecurity = false;
          console.log('caiu no erro: ' + error)
          let message = '';
          this.saveUser = false;
          if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 422 || error.status === 405) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          if (message) {
            this.showErrorDialog(message);
          }
        }
      });
    }
  }
  checkPass: boolean = false;
  btnSaveSecurity: boolean = true;
  private debounceTimer: any = null;
  closeSidebar() {
    this.displaySidebar = false;
  }

  checkLogin() {
    this.errorPasswordAtual = false;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      console.log(this.email);
      console.log(this.senha_atual);
      this.pedidosService.login(this.email, this.senha_atual).subscribe({
        next: (res: any) => {
          console.log('res', res);
          if (res.access_token) {
            localStorage.setItem('access_token', res.access_token);

            if (!this.errorPassword && !this.errorConfirmPassword && this.nova_senha.length >= 6 && this.nova_senha == this.confirm_senha) {
              this.displaySidebar = true;
              this.btnSaveSecurity = false;
              setTimeout(() => {
                this.manageOverlay();
              }, 1);
            }


          }
          this.checkPass = true;
        },
        error: (error: any) => {
          this.displaySidebar = false;
          this.checkPass = false;
          this.errorPasswordAtual = true;
          this.btnSaveSecurity = true;
          // this.messageService.add({
          //   severity: 'error',
          //   summary: 'Senha Errada',
          //   detail: 'A senha atual não é a correta!'
          // });

        }
      });
    }, 1500);

  }
  showCountSlicks: boolean = true;
  showCountViews: boolean = true;
  generateUID() {
    return uuidv4();
  }
  selecionarOpcao(opcao: number): void {
    this.activeOption = opcao;
  }

  removelogo(event: Event): void {
    this.displaySidebar = true;
    setTimeout(() => {
      this.manageOverlay();
    }, 1);
    event.stopPropagation();
    this.logoPreviewUrl = null;
    this.selectedFile = null;
    this.showImageDeleteButton = false;
    this.disableSave = false;
  }
  erroTamanhoImagem: boolean = false;
  erroTipoImagem: boolean = false;
  onLogoSelect(event: any): void {
    this.erroTamanhoImagem = false;
    this.erroTipoImagem = false;
    if (event.files && event.files.length > 0) {
      this.selectedFile = event.files[0]; // Atribuir o arquivo selecionado à variável selectedFile

      // Verificar o tamanho do arquivo (máximo 1MB)
      if (this.selectedFile && this.selectedFile.size > 1048576) {
        this.erroTamanhoImagem = true;
        this.selectedFile = null;
        this.logoPreviewUrl = false;
        return;
      }

      // Verificar o tipo do arquivo (webp, png, jpg, jpeg)
      const fileType = this.selectedFile?.type;
      if (fileType && !['image/webp', 'image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
        this.erroTipoImagem = true;
        this.selectedFile = null;
        this.logoPreviewUrl = false;
        return;
      }



      if (this.selectedFile) { // Verifica se realmente existe um arquivo antes de prosseguir
        this.displaySidebar = true;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.logoPreviewUrl = e.target.result; // Atribui a URL da imagem para visualização

          if (!this.errorName) {
            this.disableSave = false;
          } else {
            this.disableSave = true;
          }

        };
        reader.readAsDataURL(this.selectedFile);
      }
    } else {
      this.selectedFile = null; // Resetar selectedFile se nenhum arquivo for selecionado
      this.logoPreviewUrl = null;
    }
  }
  errorName: boolean = false;
  errorEmail: boolean = false;
  issueForm?: FormGroup;
  // checkInput() {
  //   this.errorName = !this.user_name.trim(); // Atualiza errorName baseado se user_name está vazio ou não
  //   if (!this.errorName) {
  //     this.disableSave = true;
  //     this.displaySidebar = true;
  //     this.manageOverlay();
  //   } 
  // }

  checkInput1() {
    this.errorName = this.user_name.trim().length === 0;
    if (this.errorName) {
      this.disableSave = true;
      this.displaySidebar = false;
    } else {
      this.disableSave = false;
      this.displaySidebar = true;
      setTimeout(() => {
        this.manageOverlay();
      }, 1);
    }

    // const overlay = document.querySelector('.p-component-overlay') as HTMLElement;
    // const overlayEnter = document.querySelector('.p-component-overlay-enter') as HTMLElement;
    // //if (this.displaySidebar) {
    //   console.log('456')
    //   overlay.style.display = 'none';
    //   overlayEnter.style.display = 'none';
    // //}
  }
  checkInput2() {
    this.errorName = this.user_name.trim().length === 0;
    if (this.errorName) {
      this.disableSave = true;
      this.displaySidebar = false;
    } else {
      this.displaySidebar = true;
      this.disableSave = false;
      setTimeout(() => {
        this.manageOverlay();
      }, 1);
    }
  }
  checkInput3() {
    this.errorName = this.user_name.trim().length === 0;
    if (this.errorName) {
      this.disableSave = true;
      this.displaySidebar = false;
    } else {
      this.displaySidebar = true;
      this.disableSave = false;
      setTimeout(() => {
        this.manageOverlay();
      }, 1);
    }
  }
  checkInputEMail() {
    this.errorEmail = !this.email.trim();
    if (this.email !== this.emailFisrt) {
      this.changeEmal = true; //
    }
  }
  saveUser: boolean = false;
  imagePreviewUrl: string | null = null;
  showImageDeleteButton: boolean = false;
  disableSave: boolean = true;
  saveUserProfile() {



    this.issueForm = this.fb.group({
      name: this.user_name,
      surname: this.user_sobrenome,
      username: this.chat_name,
      email: this.emailFisrt,
      photo: this.hasNewLogo ? this.imgUrl : this.logoPreviewUrl,
    });
    if (this.user_id !== undefined) {
      this.pedidosService.saveUser(this.user_id, this.issueForm.value).subscribe({
        next: (response) => {
          this.loadingBtn = false;
          this.displayAguarde = false;
          this.saveUser = false;
          this.disableSave = true;
          this.hasNewLogo = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Usuário Salvo',
            detail: 'O usuário foi salvo com sucesso!'
          });

          this.displaySidebar = false;
        },
        error: (error) => {
          this.displayAguarde = false;
          this.disableSave = true;
          console.log('caiu no erro: ' + error)
          let message = '';
          this.saveUser = false;
          if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 422) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          if (message) {
            this.showErrorDialog(message);
          }
        }

      });
    }
  }
  loadingBtn: boolean = false;
  hasNewLogo: boolean = false;
  imgUrl: string = '';
  saveProfile() {
    this.checkInput1(); // Verifica se o campo está vazio ao tentar salvar
    this.checkInputEMail();
    if (this.errorName) {
      return;
    }
    if (this.errorEmail) {
      return;
    }
    this.displayAguarde = true;
    this.loadingBtn = true;
    console.log('Saving profile');
    if (this.selectedFile) {
      const imageUpload$ = this.uploadFile(this.selectedFile, `uploads/images/${this.generateUID()}.jpeg`, 'image/jpeg', 2);
      imageUpload$.pipe().subscribe({
        next: (url) => {
          console.log(url); // logoPreviewUrl
          this.imgUrl = url.split('?')[0];
          this.disableSave = true;
          this.hasNewLogo = true;
          //this.cdr.detectChanges();
          ////// agora ep e salvar
          this.saveUserProfile();
          this.avatarService.updateAvatar(this.logoPreviewUrl);
          this.displaySidebar = false;
        },
        error: (error) => {

        }
      });
    } else {
      this.saveUserProfile();
    }

  }
  handleSidebarOpen() {
    setTimeout(() => {
      this.manageOverlay();
    }, 1);
  }
  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
      }
    });
  }
  calculateLeftPosition(value: number): string {
    const max = 1000; // Substitua pelo seu valor máximo
    const percentage = (value / max) * 100;
    return `${percentage}%`;
  }
  calculateLeftPosition2(value: number): string {
    const max = 10; // Substitua pelo seu valor máximo
    const percentage = (value / max) * 100;
    return `${percentage}%`;
  }
  uploadFile(file: File, storageKey: string, contentTypeSend: string, bar: number): Observable<string> {
    return new Observable<string>(observer => {
      // Inicia o upload
      uploadData({
        key: storageKey,
        data: file,
        options: {
          accessLevel: 'guest',
          contentType: contentTypeSend,
          onProgress: (progressEvent) => {
            const progress = (progressEvent.transferredBytes / (progressEvent.totalBytes ?? 1)) * 100;
          }
        }
      });

      // Aguarda a conclusão do upload antes de emitir a URL rxjs
      defer(() => from(getUrl({ key: storageKey }))).pipe(
        switchMap(response => {
          observer.next(response.url.toString()); // Emite a URL
          observer.complete();
          return [];
        })
      ).pipe().subscribe({
        error: error => {
          observer.error(error); // Emite erro em caso de falha ao obter a URL
        }
      });
    });
  }
  errorPassword: boolean = false;
  errorPasswordAtual: boolean = false;
  errorConfirmPassword: boolean = false;
  checkPassword(): void {
    this.errorPassword = !this.nova_senha || this.nova_senha.length < 6;
    if (!this.errorConfirmPassword && !this.errorPassword && this.checkPass && this.nova_senha.length >= 6 && this.nova_senha == this.confirm_senha) {
      this.btnSaveSecurity = false;
      this.displaySidebar = true;
      setTimeout(() => {
        this.manageOverlay();
      }, 1);
    } else {
      this.displaySidebar = false;
    }
    if (this.nova_senha !== this.confirm_senha) {
      this.displaySidebar = false;
      this.errorConfirmPassword = true;
    }
  }
  checkConfirmPassword(): void {
    this.errorConfirmPassword = this.nova_senha !== this.confirm_senha;
    console.log(!this.errorConfirmPassword)
    console.log(!this.errorPassword)
    console.log(this.checkPass)
    if (!this.errorConfirmPassword && !this.errorPassword && this.checkPass && this.nova_senha.length >= 6 && this.nova_senha == this.confirm_senha) {
      this.btnSaveSecurity = false;
      this.displaySidebar = true;
      setTimeout(() => {
        this.manageOverlay();
      }, 1);
    } else {
      this.displaySidebar = false;
    }
    if (this.nova_senha !== this.confirm_senha) {
      this.displaySidebar = false;
      this.errorConfirmPassword = true;
    }
  }
  display: boolean = false;

  forgot() {
    this.display = true; // Abre o diálogo
  }
  confirmForgot() {
    this.display = false; // Fecha o diálogo
    if (typeof this.emailFisrt === 'string') { // Confirma que emailFisrt é uma string
      this.pedidosService.forgot(this.emailFisrt).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Email Enviado',
            detail: 'O email foi enviado com sucesso!'
          });
        },
        error: (error) => {
          // Lidar com o erro aqui
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve um problema ao enviar o email.'
          });
        }
      });
    } else {
      // Caso emailFisrt não seja uma string válida, lidar com isso aqui
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Email inválido.'
      });
    }
  }
  newEmail: string = '';
  showDialog: boolean = false;
  // Função para validar email
  isEmailValid: boolean = false;
  isValidEmail(email: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  debounceTimeout:any;
  onEmailChange(value: string) {
    this.errorEmail = false;
    clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(() => {
      this.isEmailValid = this.isValidEmail(value);
      this.errorEmail = !this.isValidEmail(value);
    }, 750);

    // Oculta o erro enquanto o usuário está digitando
    this.errorEmail = false;
  }
  isLoading: boolean = false;
  errorMessage: string = '';
  updateEmail() {
    if (this.isValidEmail(this.newEmail)) {
      this.errorEmail = false;
      // Lógica para enviar o email de confirmação
      console.log('Email válido:', this.newEmail);
    } else {
      this.errorEmail = true;
    }
    this.errorMessage = '';

    if (this.newEmail) {
      // Lógica para atualizar o email
      console.log('Email atualizado para:', this.newEmail);
      this.showDialog = false;  // Fecha o diálogo
      this.issueForm = this.fb.group({
        new_email: this.newEmail
      });
      if (this.user_id) {
        this.pedidosService.changeEmail(this.issueForm.value, this.user_id).subscribe({
          next: (response) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Email enviado',
              detail: 'O email de confirmação foi enviado com sucesso!'
            });
          },
          error: (error) => {
            this.isLoading = false;
            console.log('caiu no erro: ' + error)
            let message = '';
            this.saveUser = false;
            if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 422) {
              message = error.error.message;
            } else if (error.status === 500) {
              message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            }

            // if (message) {
            //   this.showErrorDialog(message);
            // }


            if ([400, 403, 401, 422].includes(error.status)) {
              this.errorMessage = error.error.message;
            } else if (error.status === 500) {
              this.errorMessage = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
            } else {
              this.errorMessage = "Erro desconhecido, tente novamente.";
            }
          }

        });
      }

      ///////////////////////
    }

  }

  ngOnDestroy() {
    // Restaura os overlays para o estado padrão ao sair da página
    const overlays = document.querySelectorAll('.p-component-overlay');
    const overlayEnters = document.querySelectorAll('.p-component-overlay-enter');
  
    overlays.forEach((overlay: Element) => {
      (overlay as HTMLElement).style.display = ''; // Remove a alteração manual
    });
  
    overlayEnters.forEach((overlayEnter: Element) => {
      (overlayEnter as HTMLElement).style.display = ''; // Remove a alteração manual
    });
  }
  
}
