import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { ProductService } from 'src/app/services/product.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressBarModule } from 'primeng/progressbar';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-show-products',
  standalone: true,
  templateUrl: './show-products.component.html',
  styleUrls: ['./show-products.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TabViewModule,
    InputNumberModule,
    ButtonModule,
    SplitButtonModule,
    SelectButtonModule,
    DialogModule,
    SkeletonModule,
    ProgressBarModule
  ],
})
export class ShowProductsComponent implements OnInit {
  @Input() sku: number = 0;
  constructor(
    private cdr: ChangeDetectorRef,
    private productService: ProductService,
    private route: ActivatedRoute
  ) { }

  product: any; // Produto principal
  productImages: string[] = [];
  mainImage: string = '';
  childProducts: any[] = [];
  attributes: { name: string; values: string[] }[] = [];
  selectedAttributes: { [key: string]: string } = {};
  selectedChildProduct: any = null;
  quantity: number = 1;
  inventoryQuantity: number = 0; // Estoque do produto filho selecionado
  maxQuantity: number = 0; // Quantidade máxima permitida

  ngOnInit(): void {
    console.log('chamou detalahr produto');

    // ✅ Verifica se o SKU já está presente e carrega o produto
    if (this.sku) {
      console.log('SKU recebido no ngOnInit:', this.sku);
      this.loadProductDetails(this.sku);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sku'] && changes['sku'].currentValue) {
      console.log('SKU recebido:', this.sku);
      this.loadProductDetails(this.sku);
    }
  }

  loadProductDetails(productId: number): void {
    let productIdStr = productId.toString();

    if (productIdStr) {
      this.loading = true;

      this.productService.getDetailProduct(productIdStr).subscribe({
        next: (response) => {
          this.product = response.product;
          this.productImages = this.product.images;
          this.mainImage = this.productImages[0];

          // 🔍 Busca pelos produtos filhos
          this.productService.getChildremProducts(productIdStr).subscribe({
            next: (childResponse) => {
              this.childProducts = childResponse.children;

              // ✅ Verifica se há filhos ou não
              if (this.childProducts.length > 1) {
                // Se houver filhos, busca atributos
                this.productService.getChildremProductsAttributes(productIdStr).subscribe({
                  next: (attributesResponse) => {
                    console.log('Retornou atributos');
                    this.processAttributes(attributesResponse.attributes);
                    this.loading = false;
                    this.cdr.detectChanges();
                  },
                  error: (error) => {
                    console.error('Erro ao carregar atributos dos produtos filhos:', error);
                    this.loading = false;
                  }
                });
              } else {
                // Se não houver filhos, não busca atributos
                console.log('Produto sem filhos. Pulando busca de atributos.');
                this.loading = false;
                this.cdr.detectChanges();
              }
            },
            error: (childError) => {
              console.error('Erro ao carregar produtos filhos:', childError);
              this.loading = false;
            }
          });
        },
        error: (error) => {
          console.error('Erro ao carregar os detalhes do produto:', error);
          this.loading = false;
        }
      });
    }
  }


  processAttributes(attributesResponse: any[]): void {
    const attributeMap: { [key: string]: Set<string> } = {};
    attributesResponse.forEach((item) => {
      item.node.selectedOptions.forEach((option: { name: string; value: string }) => {
        if (!attributeMap[option.name]) {
          attributeMap[option.name] = new Set();
        }
        attributeMap[option.name].add(option.value);
      });
    });
    this.attributes = Object.keys(attributeMap).map((key) => ({
      name: key,
      values: Array.from(attributeMap[key]),
    }));
  }

  selectAttribute(attributeName: string, value: string): void {
    this.selectedAttributes[attributeName] = value;
    const selectedChild = this.childProducts.find((child) =>
      Object.keys(this.selectedAttributes).every(
        (key) =>
          child.selectedOptions?.some(
            (opt: any) => opt.name === key && opt.value === this.selectedAttributes[key]
          )
      )
    );
    if (selectedChild) {
      this.selectedChildProduct = selectedChild;
      this.updateProductDetails(selectedChild);
    } else {
      this.selectedChildProduct = null;
      this.resetProductDetails();
    }
  }

  updateProductDetails(child: any): void {
    this.product.title = child.title;
    this.product.price = child.price;
    this.product.compare_at_price = child.compare_at_price || null;

    // Atualiza a imagem principal se o produto filho tiver uma imagem (thumbnail)
    if (child.thumbnail) {
      this.mainImage = child.thumbnail;
    }

    if (child.tracked) {
      this.inventoryQuantity = child.inventory_quantity || 0;
      this.maxQuantity = this.inventoryQuantity;
      if (this.quantity > this.maxQuantity) {
        this.quantity = this.maxQuantity;
      }
    } else {
      // Caso `tracked` seja false, não limitamos a quantidade
      this.inventoryQuantity = Infinity;
      this.maxQuantity = Infinity;
    }
  }


  changeMainImage(image: string): void {
    this.mainImage = image;
  }

  resetProductDetails(): void {
    this.product.title = this.product.title;
    this.product.price = this.product.price;
    this.product.compare_at_price = this.product.compare_at_price || null;
    this.inventoryQuantity = 0;
    this.maxQuantity = 0;
  }
  cartProductIds: string[] = [];
  addToCart(): void {
    this.cartProductIds = [];
    const productId = this.selectedChildProduct ? this.selectedChildProduct.id : this.product.id;
    this.cartProductIds.push(productId);
    console.log('Produto adicionado ao carrinho:', productId);
    console.log('Carrinho atual:', this.cartProductIds);
  }
  loading: boolean = true;

}
