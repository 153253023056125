import { Component, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, ViewContainerRef, ComponentRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PVideoPlayerComponent } from '../p-video-player/p-video-player.component';
import { ModalstateService } from 'src/app/services/modalstate.service';
import { Item } from 'src/app/models/liveshop';
import { SidebarModule } from 'primeng/sidebar';
interface VideoItem {
  uid: string;
  id: number;
  src: string;
  poster: string;
  thumbnail?: string;
  products?: any[];
}

@Component({
  selector: 'app-video-modal',
  standalone: true,
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PVideoPlayerComponent, SidebarModule],
  encapsulation: ViewEncapsulation.Emulated
})
export class VideoModalComponent implements OnInit, OnChanges {
  @Input() arraySlicksFull: Item[] = []; // Array completo de slicks
  @Input() videoUrls: VideoItem[] = [];  // URLs dos vídeos recebidos do componente pai
  @Input() show: boolean = true;                  // Controla a visibilidade do modal
  @Input() selectedVideo?: VideoItem;    // Vídeo selecionado
  @Output() close = new EventEmitter<void>(); // Evento para fechar o modal
  @Input() totalAssets: number = 0;
  @Input() widget_id?: number;
  @Input() store_id?: number = 163;
  @Input() template_id?: number;
  @Input() height: number | undefined;
  @Input() pageToVideos: number = 0;
  aspectRatio: string | undefined;
  private worker?: Worker;

  isPlayerVisible = false;

  // Referência ao container onde o componente será criado
  @ViewChild('videoPlayerContainer', { read: ViewContainerRef, static: true }) videoPlayerContainer!: ViewContainerRef;
  private videoPlayerRef?: ComponentRef<PVideoPlayerComponent> | null;

  constructor(private modalStateService: ModalstateService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    //this.aspectRatio = '16/9';
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Modal aberto');
    if (changes['show'] && changes['show'].currentValue === true) {
      console.log('Modal aberto');
      this.cdr.detectChanges();
      this.modalStateService.emitModalOpened(true);
      this.sortSlicksAndSendToPlayer();
    }
  }

  private setupSrcs() {
    console.log('Chamando setupSrcs para o vídeo:', this.selectedVideo);
    this.isPlayerVisible = true;

    if (this.selectedVideo) {
      const selectedSlick = this.arraySlicksFull.find(item => item.content.uid === this.selectedVideo?.uid);
      if (selectedSlick && selectedSlick.media) {
        this.selectedThumbnail = selectedSlick.media.thumbnail;
        this.isFullScreen = selectedSlick.media.aspect_ratio === '2';
      }
    }


    this.createVideoPlayerComponent(); // Cria o componente dinamicamente
    this.cdr.detectChanges();
  }
  private createVideoPlayerComponent() {
    this.videoPlayerContainer.clear();
    this.videoPlayerRef = undefined;
    this.videoPlayerRef = this.videoPlayerContainer.createComponent(PVideoPlayerComponent);

    if (this.videoPlayerRef.instance) {
      this.videoPlayerRef.instance.store_id = this.store_id;
      //this.videoPlayerRef.instance.slicks = this.arraySlicksFull;
      this.videoPlayerRef.instance.slicks = this.transformVideoToSlick(this.selectedVideo!);

      console.log('slick completo: ')
      console.log(this.videoPlayerRef.instance.slicks)
      this.videoPlayerRef.instance.slick_id = this.selectedVideo?.uid;
      this.videoPlayerRef.instance.widget_id = this.widget_id;

      // // Passa o contêiner para o componente filho
      // const parentContainer = this.videoPlayerContainer.element?.nativeElement?.parentElement?.closest('.s-s-l-zIndexVideo');
      // this.videoPlayerRef.instance.appendToContainer = parentContainer;

      this.videoPlayerRef.instance.initializeSlicks(this.arraySlicksFull, this.totalAssets, this.pageToVideos);
      this.videoPlayerRef.instance.initializeAfterView();

      // Escutar o evento allPlayersReady para ocultar a imagem de pôster
      this.videoPlayerRef.instance.allPlayersReady.subscribe(() => {
        this.isPlayerVisible = true;  // Oculta o pôster e exibe o vídeo
        this.cdr.detectChanges();
      });
    }
  }
  private transformVideoToSlick(video: VideoItem): Item[] {
    return [
      {
        schedule: {
          date_from: new Date().toISOString(),  // Define a data atual ou ajuste conforme necessário
          date_to: null  // Ou defina uma data se necessário
        },
        media: {
          type: 'video',  // Ajuste conforme necessário
          src: video.src,
          pin: 0,  // Valor padrão ou ajuste conforme a regra de negócio
          time_to_pitch: '',
          thumbnail: video.thumbnail || null,
          duration: null,  // Ajuste se necessário
          stream_status: null,
          status: 1,  // Ajuste conforme a lógica (ativo/inativo)
          aspect_ratio: '16/9'  // Ajuste conforme necessário
        },
        content: {
          id: video.id,
          uid: video.uid,
          title: 'Título Padrão',  // Defina um título padrão ou ajuste conforme necessário
          description: 'Descrição Padrão',  // Defina uma descrição padrão ou ajuste conforme necessário
          cta: {
            components: {
              custom: [],
              whatsapp: [],
              products: {
                items: video.products || [],
              },
              promotions: {
                items: [],
              },
              links: [],
              extra: {
                ecom_layer: {
                  cart: { type: null, display: null, platform: null },
                  search_bar: { display: null },
                },
                chat_box: { display: { read: null, write: null } },
                promotions: { items: [] },
                player: {
                  auto_play: true,
                  fake_bar: false,
                  time_to_pitch: false,
                  time_to_pitch_value: '',
                  player_color: '#000000',
                  progress_bar_color: '#FFFFFF',
                },
                tags: '',
              },
              alerts: [],
            },
          },
        },
        extra: {
          ecom_layer: {
            cart: { type: null, display: null, platform: null },
            search_bar: { display: null },
          },
          chat_box: { display: { read: null, write: null } },
          promotions: { items: [] },
          player: {
            auto_play: true,
            fake_bar: false,
            time_to_pitch: false,
            time_to_pitch_value: '',
            player_color: '#000000',
            progress_bar_color: '#FFFFFF',
          },
          tags: '',
        },
        zIndex: 1
      }
    ];
  }
  



  private sortSlicksAndSendToPlayer() {
    if (typeof Worker !== 'undefined' && this.videoUrls[0]?.uid) {
      this.worker = new Worker(new URL('./slick-sort.worker', import.meta.url));
      this.worker.onmessage = ({ data }) => {
        this.arraySlicksFull = data;
        this.setupSrcs(); // Garante que o setup e a criação do componente ocorram após a ordenação
      };
      this.worker.postMessage({ slicks: this.arraySlicksFull, targetUid: this.videoUrls[0].uid });
    }
  }

  closeModal() {
    this.close.emit();
    this.isPlayerVisible = false;
    if (this.videoPlayerRef) {
      this.videoPlayerRef.destroy();
      this.videoPlayerRef = null;
    }
    this.modalStateService.emitModalOpened(false);
    console.log('Chamou closeModal')
    this.worker?.terminate(); // Finaliza o worker ao fechar
  }

  selectedThumbnail: string | null = null;
  isFullScreen = false;
}
