<div class="flex flex-column md:flex-row gap-5" style="min-height: 40vh">
    <div class="md:w-25rem card p-0" *ngIf="!showSidebar">
        <app-chat-sidebar></app-chat-sidebar>
    </div>

    <div class="flex-1 card p-0 ss-g-chat" style="width: 100% !important;left: 0;" *ngIf="showSidebar" [ngStyle]="{
        'position': showTopChat ? 'static' : 'absolute',
        'bottom': showTopChat ? 'auto' : '0.5rem',
        'background-color': showTopChat ? 'initial' : 'transparent',
        'border': showTopChat ? 'initial' : 'none',
        'box-shadow': showTopChat ? 'initial' : 'none',
        'max-width': '100%',
        'width': admin ? '100% ' : '100% !important'
    }">
        <!-- <div class="text-center" style="position: relative;bottom: 180px;padding-left: 1rem;padding: 1rem;" *ngIf="isLoading">
            Aguardando ...
            <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
        </div> -->
        <app-chat-box [user]="activeUser" [chatId]="chatId"  [showTopChat]="showTopChat"  (isLoadingChange)="handleLoading($event)"></app-chat-box>
    </div>
</div>