import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ConfirmationService } from 'primeng/api'; import { MessageService } from 'primeng/api';
import { Apps, StoreAppsResponse, StoreAppCreationResponse, StoreApps } from 'src/app/models/apps';
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {
  products: Apps[] = [];
  selectedProduct: Apps | null = null; // Para armazenar o produto selecionado
  showSidebar: boolean = false; // Controle da visibilidade da sidebar

  selectedStoreApp: StoreApps | null = null;

  constructor(private productService: ProductService, private confirmationService: ConfirmationService, private messageService: MessageService) { }
  store_id: number | undefined;
  ngOnInit() {
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    if (selectedStoreId) {
      this.store_id = Number(selectedStoreId);
    }

    this.productService.getAppsPublic().subscribe({
      next: (response) => {
        this.products = response.apps;
        this.checkStoreApps();
      },
      error: (error) => {
        console.error('Erro ao carregar os apps:', error);
      },
    });
  }
  checkStoreApps() {
    if (!this.products || !this.store_id) {
      return;
    }

    this.products.forEach((product) => {
      this.productService.getAppsStore(product.key).subscribe({
        next: (response) => {

          console.log('produc.key: '+product.key)
          if (!response.store_apps || response.store_apps.length === 0) {
            console.log('response.store_apps está vazio ou indefinido:', response.store_apps);
            return; // Interrompe a execução se store_apps não existir ou for vazio
          }

          const storeApp = response.store_apps?.find((app) => {
            console.log('app.key:', app.key); // Log para inspecionar app.key
            return app.key.includes(product.key) && app.store_id === this.store_id;
          });

          if (storeApp) {
            product.isPinned = true; // Adicione uma propriedade personalizada
          }
        },
        error: (error) => {
          console.error(`Erro ao buscar detalhes do app ${product.key}:`, error);
        },
      });
    });
  }


  displayAguarde: boolean = false;
  connected: boolean = false;
  maskSensitiveData(value?: string): string {
    if (!value || value.length <= 6) {
      return value || ''; // Retorna o valor original se for muito curto para mascarar
    }
    const start = value.slice(0, 3); // Três primeiros caracteres
    const end = value.slice(-3); // Três últimos caracteres
    const masked = '.'.repeat(value.length - 6); // Pontos no meio
    return `${start}${masked}${end}`;
  }
  
  onProductClick(product: Apps): void {
    this.selectedProduct = product;
    this.displayAguarde = true;

    this.productService.getAppsStore(product.key).subscribe({
      next: (response) => {
        if (response.store_apps && response.store_apps.length > 0) {
          const storeApp = response.store_apps[0];
          this.connected = true;
          this.selectedStoreApp = storeApp;

          // Adicionar domínio no primeiro input e token no segundo
          if (storeApp.form_value?.domain) {
            this.selectedStoreApp.form_value.domain = storeApp.form_value.domain;
          }
          if (storeApp.access_token) {
            this.selectedStoreApp.access_token = storeApp.access_token;
          }
        } else {
          this.connected = false;
          this.selectedStoreApp = null;
        }
        this.displayAguarde = false;
        this.showSidebar = true;
      },
      error: (error) => {
        console.error('Erro ao buscar detalhes do app:', error);
      },
    });
  }



  closeSidebar(): void {
    this.showSidebar = false;
    this.selectedProduct = null;
  }
  displayDialogSidebar: boolean = false;
  loadingBtn: boolean = false;
  confirmRemove() {
    this.confirmationService.confirm({
      message: 'Você realmente deseja remover esta integração?',
      header: 'Confirmação de Exclusão',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.displayDialogSidebar = true;
        this.loadingBtn = true;
        this.removeApp();
      },
      reject: () => {
      }
    });
  }

  removeApp() {
    if (this.selectedStoreApp) {
      this.productService.removeAppsStore(this.selectedStoreApp.uid).subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Integração Deletada',
            detail: 'A integração foi deletada com sucesso!'
          });
          this.connected = false;
          this.selectedStoreApp = null;
          this.loadingBtn = false;

          // Atualizar o estado do produto para remover o pin
          const product = this.products.find(p => p.key === 'shopify');
          if (product) {
            product.isPinned = false; // Remove o pin
          }
        },
        error: (error) => {
          this.loadingBtn = false;
          let message = '';
          if ([400, 403, 401, 404].includes(error.status)) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          if (message) {
            // Mostra a mensagem de erro no `p-confirmDialog`
            this.showErrorDialog(message);
          }
        },
      });
    }
  }

  shopifyConnection(){
    this.loadingBtn = true;

    // Gerar um state randômico
    const randomState = Math.random().toString(36).substring(2);
  
    const authUrl = `https://slicksell-test.myshopify.com/admin/oauth/authorize?client_id=0d45482ed6adf4a59e4e8fc7f367b9ab&scope=read_content,read_discounts,read_gift_cards,read_inventory,read_products,read_themes,write_pixels,write_products,write_script_tags,write_themes&redirect_uri=https://app.slicksell.com.br/auth/shopify&state=${randomState}`;
    
    window.location.href = authUrl;
  }

  connectApp(): void {
    // fazer request para 
    //https://slicksell-test.myshopify.com/admin/oauth/authorize?client_id=0d45482ed6adf4a59e4e8fc7f367b9ab&scope=read_content,read_discounts,read_gift_cards,read_inventory,read_products,read_themes,write_pixels,write_products,write_script_tags,write_themes&redirect_uri=https://app.slicksell.com.br/auth/shopify&state=random123


    this.shopifyConnection();

    this.loadingBtn = true;
    this.productService.createAppsStore('shopify').subscribe({
      next: (response) => {
        if (response.store_apps) {
          this.selectedStoreApp = response.store_apps; // Preenche os dados retornados
          this.connected = true;

          // Adicionar domínio no primeiro input e token no segundo
          if (this.selectedStoreApp.form_value?.domain) {
            this.selectedStoreApp.form_value.domain = this.selectedStoreApp.form_value.domain;
          }
          if (this.selectedStoreApp.access_token) {
            this.selectedStoreApp.access_token = this.selectedStoreApp.access_token;
          }

          // Atualizar o estado do produto para adicionar o pin
          const product = this.products.find(p => p.key === 'shopify');
          if (product) {
            product.isPinned = true; // Adiciona o pin
          }
        } else {
          this.selectedStoreApp = null;
          this.connected = false;
        }

        this.messageService.add({
          severity: 'success',
          summary: 'Integração Criada',
          detail: 'A integração foi criada com sucesso!'
        });
        this.loadingBtn = false;
      },
      error: (error) => {
        this.loadingBtn = false;
        let message = '';
        if ([400, 403, 401, 404].includes(error.status)) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      },
    });
  }

  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
        // Lógica de aceitação, se necessário
      }
    });
  }



  //closeSidebar(){}
}
