import { Injectable } from '@angular/core';
import { WatchedInterval } from '../models';
import { VideoMuteService } from 'src/app/services/video-mute.service';
import { Item } from 'src/app/models/liveshop';
@Injectable({
  providedIn: 'root'
})
export class SVideoPlayerService {

  private players: any[] = []; // Array para armazenar os players de vídeo

  constructor(private videoMuteService: VideoMuteService) { }

  // Método para alternar o mudo
  toggleMute(player: any, isMuted: boolean): boolean {
    if (isMuted) {
      player.muted(false); // Remover mute
      player.volume(1);
      return false;
    } else {
      player.muted(true); // Aplicar mute
      player.volume(0);
      return true;
    }
  }

  // Método para alternar play e pause
  togglePlayPause(player: any, isPaused: boolean): boolean {
    if (isPaused) {
      player.play()?.then(() => {
      }).catch((error: any) => {
        console.error("Erro ao tentar reproduzir o vídeo:", error);
      });
      return false;
    } else {
      player.pause();
      return true;
    }
  }

  // Método para buscar o player de um slide baseado no índice e se é duplicado
  getPlayerFromArray(index: number, isDuplicate: boolean, playersArray: any[]): any {
    return playersArray.find(p => p.index === index && p.isDuplicate === isDuplicate)?.player || null;
  }

  // Método para definir a lista de players, se necessário
  setPlayers(players: any[]): void {
    this.players = players;
  }

  // Método para obter o índice do slide ativo
  getActiveSlideIndex(swiperInstance: any): number {
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    return parseInt(activeSlide.getAttribute('data-swiper-slide-index'), 10);
  }


  calculateBufferedData(player: any) {
    const buffered = player.buffered();
    let totalBuffered = 0;
    for (let i = 0; i < buffered.length; i++) {
      totalBuffered += buffered.end(i) - buffered.start(i);
    }
    return totalBuffered;
  }
  watchedIntervals: WatchedInterval[] = [];
  calculateWatchedDuration() {
    if (this.watchedIntervals.length === 0) return 0;

    // Sort watched intervals by start time
    this.watchedIntervals.sort((a, b) => a.start - b.start);

    // Initialize total watched duration
    let totalDuration = 0;

    // Initialize variables to track the current merged interval
    let currentStart = this.watchedIntervals[0].start;
    let currentEnd = this.watchedIntervals[0].end;

    // Iterate through the sorted intervals and merge overlapping intervals
    for (let i = 1; i < this.watchedIntervals.length; i++) {
      const interval = this.watchedIntervals[i];

      if (interval.start <= currentEnd + 1) {
        // Merge overlapping or adjacent intervals
        currentEnd = Math.max(currentEnd, interval.end);
      } else {
        // Calculate duration of the current merged interval
        totalDuration += currentEnd - currentStart + 1;

        // Move to the next interval
        currentStart = interval.start;
        currentEnd = interval.end;
      }
    }

    // Add the duration of the last merged interval
    totalDuration += currentEnd - currentStart + 1;

    // Return the total watched duration
    return totalDuration;
  }

  // Método para reproduzir o vídeo atual, movido para o serviço
  async playVideoAtual(
    swiperInstance: any,
    realIndex: number, // Callback para obter o índice real do slide ativo
    getPlayerFromArray: (index: number, isDuplicate: boolean) => any, // Callback para obter o player de acordo com o índice
    isPaused: boolean,
    virtualIndex: number,
    slickInPlay: number,
    pauseOhtersCallback: () => void
  ): Promise<void> {
    if (!swiperInstance) {
      console.error("Swiper não está inicializado corretamente.");
      return;
    }

    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');
    let player = getPlayerFromArray(realIndex, isDuplicate);

    if (player) {
      const visibleSlide = Array.from(swiperInstance.slides).find((slide: any) => {
        return slide.classList.contains('swiper-slide-visible') &&
          slide.classList.contains('swiper-slide-active') &&
          !slide.classList.contains('swiper-slide-next') &&
          !slide.classList.contains('swiper-slide-prev');
      }) as HTMLElement;

      if (!visibleSlide) {
        return;
      }

      const visibleIndexStr = visibleSlide.getAttribute('data-swiper-slide-index');
      if (!visibleIndexStr) {
        console.error("Atributo 'data-swiper-slide-index' não encontrado.");
        return;
      }

      const visibleIndex = parseInt(visibleIndexStr, 10);
      let activeIndex = realIndex;

      if (activeIndex === visibleIndex) {
        if (player.readyState() >= 3 && player.paused()) {
          if (!isPaused) {
            //player.muted(this.videoMuteService.getMuteState());
            await player.play();
            slickInPlay = virtualIndex; // Atualiza o estado de `slickInPlay`
          }
        }
      }

      // Pausar todos os outros slides diferentes do `realIndex`
      swiperInstance.slides.forEach((slide: any, index: number) => {
        if (index !== swiperInstance.activeIndex) {
          const poster = slide.querySelector('.ss-l-poster') as HTMLElement;
          if (poster) {
            poster.classList.remove('ss-l-hidden');
          }
          const video = slide.querySelector('.ss-l-video') as HTMLElement;
          if (video) {
            video.classList.add('ss-l-hidden');
          }
        }
        const isCurrentDuplicate = slide.classList.contains('swiper-slide-duplicate');
        const realSlideIndex = parseInt(slide.getAttribute('data-swiper-slide-index') || '0', 10);

        // Identificar se o slide não é o ativo atual e não é duplicado do ativo
        if (realSlideIndex !== realIndex || (isDuplicate && index !== swiperInstance.activeIndex)) {
          let slidePlayer = getPlayerFromArray(realSlideIndex, isCurrentDuplicate);
          if (slidePlayer && !slidePlayer.paused()) {
            slidePlayer.pause();
          }
        }
      });

      // Pausar o player do slide visível próximo ou anterior com debounce
      const nextOrPrevVisibleSlide = Array.from(swiperInstance.slides).find((slide: any) => {
        return slide.classList.contains('swiper-slide-visible') &&
          (slide.classList.contains('swiper-slide-next') || slide.classList.contains('swiper-slide-prev'));
      }) as HTMLElement;

      if (nextOrPrevVisibleSlide) {
        const nextOrPrevIndexStr = nextOrPrevVisibleSlide.getAttribute('data-swiper-slide-index');
        if (nextOrPrevIndexStr) {
          const nextOrPrevIndex = parseInt(nextOrPrevIndexStr, 10);
          const isDuplicateNextOrPrev = nextOrPrevVisibleSlide.classList.contains('swiper-slide-duplicate');
          let nextOrPrevPlayer = getPlayerFromArray(nextOrPrevIndex, isDuplicateNextOrPrev);
          if (nextOrPrevPlayer && !nextOrPrevPlayer.paused()) {
            nextOrPrevPlayer.pause();
          }
        }
      }
    }

    // Chamar o callback para pausar outros players
    pauseOhtersCallback();
  }
  async playActiveVideo(
    swiperInstance: any, // Instância do Swiper
    getActiveSlideIndex: () => number, // Função callback para obter o índice ativo
    getPlayerFromArray: (index: number, isDuplicate: boolean) => any, // Função callback para obter o player
    pauseOhtersCallback: () => void, // Função callback para pausar outros players
    cdrDetectChanges: () => void, // Função callback para detectar mudanças no `ChangeDetectorRef`
    setPlayerState: (key: string, value: any) => void, // Função callback para definir estados globais no componente
    virtualIndex: number, // Índice virtual atual
    isPaused: boolean // Estado global de pausa do player
  ): Promise<void> {
    if (!swiperInstance) {
      console.error("Swiper não está inicializado corretamente.");
      return;
    }

    const realIndex = getActiveSlideIndex();
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const isDuplicate = activeSlide.classList.contains('swiper-slide-duplicate');

    // Obter o player associado ao índice real e se é duplicado ou não
    let player = getPlayerFromArray(realIndex, isDuplicate);
    cdrDetectChanges(); // Detecção de mudanças no componente

    if (player && player.paused()) {
      player.on('canplay', () => {
        const visibleSlide = swiperInstance.el.querySelector('.swiper-slide-visible') as HTMLElement;
        if (!visibleSlide) {
          return;
        }

        const visibleIndexStr = visibleSlide.getAttribute('data-swiper-slide-index');
        if (!visibleIndexStr) {
          console.error("Atributo 'data-swiper-slide-index' não encontrado.");
          return;
        }

        const visibleIndex = parseInt(visibleIndexStr, 10);
        let activeIndex = getActiveSlideIndex();

        if (realIndex === visibleIndex) {
          if (player.paused()) {
            try {
              if (!isPaused) {
                player.play()?.then(() => {
                  setPlayerState('slickInPlay', virtualIndex); // Atualizar `slickInPlay`
                }).catch((error: any) => {
                  console.error("Erro ao tentar reproduzir o vídeo: ", error);
                });
              }
            } catch (error) {
              console.error("Erro capturado durante a tentativa de tocar o vídeo:", error);
            }
          }
        }
      });
    }

    if (!player) {
      console.error('Player não foi inicializado corretamente.');
      return;
    }

    // Configuração inicial do player
    this.setupPlayerEvents(player, setPlayerState, pauseOhtersCallback, cdrDetectChanges, virtualIndex, realIndex);

    // Pausar outros players
    pauseOhtersCallback();
  }

  // Método auxiliar para configurar eventos do player
  private setupPlayerEvents(
    player: any,
    setPlayerState: (key: string, value: any) => void,
    pauseOhtersCallback: () => void,
    cdrDetectChanges: () => void,
    virtualIndex: number,
    realIndex: number
  ) {
    player.ready(() => {
      player.on('play', () => {
        setPlayerState('slickInPlay', virtualIndex);
        player.muted(this.videoMuteService.getMuteState());
      });

      player.on('timeupdate', () => {
        const currentTime = player.currentTime();
        setPlayerState('lastCurrentTime', currentTime);
        cdrDetectChanges();
      });

      player.on('playing', () => {
        setPlayerState('isPlaying', true);
        cdrDetectChanges();
      });

      player.on('pause', () => {
        setPlayerState('isPlaying', false);
        cdrDetectChanges();
      });

      player.on('ended', () => {
        setPlayerState('isPlaying', false);
        cdrDetectChanges();
        pauseOhtersCallback();
      });
    });
  }
}
