<div class="card shadow-none border-none col-12 p-3" *ngIf="videoUrls && videoUrls.length > 0">
    <!-- Vídeo abaixo do cabeçalho -->
    <div class="ss-l-video-container-widget s-s-l-inlineDiv p-0 min-h-30rem relative col-4"
        [style.width]="isCol4 ? '100%' : null" #inlineDiv [style.aspect-ratio]="aspectRatio">

        <!-- Vídeo -->
        <video *ngIf="selectedVideoUrl && videoUrls && videoUrls.length > 0" #videoPlayer [muted]="isMuted"
            [autoplay]="true" [loop]="true" (click)="openModal(selectedVideoUrl)" (loadedmetadata)="onVideoLoaded()"
            class="cursor-pointer">
            <source [src]="selectedVideoUrl.src" type="video/mp4" />
            Seu navegador não suporta a tag de vídeo.
        </video>

        <!-- Overlay Container -->
        <div class="ss-l-video-overlay">
            <!-- Top Bar -->
            <div class="top-bar">
                <div class="flex flex-wrap align-items-center">
                    <p-avatarGroup styleClass="mb-3 avatar-group-container">
                        <i *ngIf="showPrevVideoButton"
                            class="pi pi-arrow-circle-left avatar-arrow cursor-pointer text-white"
                            (click)="playPrevVideo()"></i>
                        <p-avatar *ngIf="showPrevVideoButton" [image]="prevVideoThumbnail" size="large"
                            shape="circle"></p-avatar>
                        <p-avatar [image]="selectedVideoThumbnail" size="large" shape="circle"
                            class="current-video"></p-avatar>
                        <p-avatar *ngIf="showNextVideoButton" [image]="nextVideoThumbnail" size="large" shape="circle"
                            class="cursor-pointer"></p-avatar>
                        <p-avatar *ngIf="selectedVideoUrl" class="cursor-pointer" (click)="openModal(selectedVideoUrl)"
                            [label]="'+' + videoUrls.length" shape="circle" size="large"
                            [style]="{ 'background-color': '#9c27b0', color: '#ffffff' }"></p-avatar>
                        <i *ngIf="showNextVideoButton"
                            class="pi pi-arrow-circle-right avatar-arrow cursor-pointer text-white"
                            (click)="playNextVideo()"></i>
                    </p-avatarGroup>
                </div>
            </div>
            <div class="product-carousel-container" *ngIf="showProducts && selectedVideoUrl?.products?.length"
                [style.bottom.px]="isMobile ? bottomBarHeight + 5 : bottomBarHeight + 0">
                <!-- Botão de controle para mostrar/ocultar o carousel -->
                <div class="carousel-toggle-container text-center cursor-pointer" (click)="toggleCarousel($event)">
                    <i [ngClass]="showCarousel ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" class="text-2xs"></i>
                </div>

                <!-- Product Carousel -->
                <!-- Product Carousel Dinâmico -->
                <div class="product-carousel mr-2 ml-2"
                    [ngClass]="{'show-carousel': showCarousel, 'hide-carousel': !showCarousel}">
                    <p-carousel *ngIf="showProducts && selectedVideoUrl?.products?.length"
                        [value]="selectedVideoUrl?.products || []" [numVisible]="1.5" [numScroll]="1" [circular]="false"
                        [showIndicators]="false">
                        <ng-template pTemplate="item" let-product>
                            <div class="product" (click)="openProductSidebar(product.sku)">
                                <div class="link-container cursor-pointer">
                                    <div class="link-card">
                                        <div class="text-xs ss-l-discount-badge font-bold">50% OFF</div>
                                        <div class="link-image">
                                            <img [src]="product.image || 'assets/imgTmp.webp'" alt="{{ product.name }}"
                                                class="image-style" loading="lazy">
                                        </div>
                                        <div class="link-name px-2">
                                            <div class="link-txt text-xs">{{ product.name }}</div>
                                            <div class="flex gap-1 align-items-center ss-l-values">
                                                <div *ngIf="product.price" class="link-value text-sm font-bold">
                                                    R$ {{ product.price }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>



                </div>

            </div>

            <!-- Bottom Bar -->
            <div class="bottom-bar" #bottomBar>
                <div class="info-container">
                    <div *ngIf="showDescription && selectedVideoUrl?.title" class="title-text text-left text-white">
                        <b>{{ selectedVideoUrl.title }}</b>
                    </div>
                    <div *ngIf="showDescription && selectedVideoUrl?.description" class="description text-left">
                        <div class="text-white w-full mb-2 cursor-pointer select-none ss-l-element"
                            (click)="handleDivClick($event)">
                            {{truncatedDesc}}
                            <a (click)="goVerMais(); $event.stopPropagation();" class="text-white cursor-pointer"
                                *ngIf="showBtnVerMais"><b>ver mais</b></a>
                        </div>
                    </div>
                </div>
                <div class="controls">
                    <button class="play-pause-btn" (click)="togglePlay()" pButton pRipple>
                        <i [ngClass]="isPlaying ? 'pi pi-pause' : 'pi pi-play'"></i>
                    </button>
                    <button class="mute-unmute-btn" (click)="toggleMute()" pButton pRipple>
                        <i [ngClass]="isMuted ? 'bi bi-volume-mute-fill' : 'bi bi-volume-up'"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>







<p-sidebar [(visible)]="showAllProductsSidebar2" position="right" 
  class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom ss-l-productsSidebar z-9 p-0">

  <app-show-products [sku]="selectedSku"></app-show-products>

</p-sidebar>




<!-- Modal Carousel -->
<app-video-modal [videoUrls]="selectedVideoUrls" [widget_id]="widget_id" [show]="isModalOpen" [height]="height"
    [arraySlicksFull]="arraySlicksFull" [selectedVideo]="selectedVideoUrls[0]" [totalAssets]="totalAssets"
    (close)="isModalOpen = false"></app-video-modal>