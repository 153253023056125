<div class="grid">
    <div class="col-12">
        <div class="card">
            <p-dataView #dv [value]="products" [paginator]="false" [rows]="9" filterBy="key" layout="grid">
                <ng-template pTemplate="header">
                    <div class="flex justify-end">
                        <p-dataViewLayoutOptions class="flex mb-2 mr-2"></p-dataViewLayoutOptions>
                    </div>
                </ng-template>
                <ng-template pTemplate="listItem" let-product>
                    <div class="col-12 cursor-pointer" (click)="onProductClick(product)">
                        <div class="flex flex-column md:flex-row align-items-center p-3 w-full">
                            <img [src]="product.logo" [alt]="product.key" class="my-4 md:my-0 w-4rem mr-5" />
                            <div class="flex-1 flex flex-column align-items-left text-left md:text-left">
                                <div class="flex justify-content-between align-items-center w-full">
                                    <div class="font-bold text-2xl">{{ product.key | titlecase }}</div>
                                    <p-tag *ngIf="product.isPinned" severity="info" [rounded]="true" value="Instalado"></p-tag>
                                </div>
                                <div class="mb-2">{{ product.description }}</div>
                                <div class="flex align-items-center mt-2">
                                    <i class="pi pi-tag mr-2"></i>
                                    <span class="font-semibold">{{ product.category }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                

                <ng-template pTemplate="gridItem" let-product>
                    <div class="col-12 lg:col-3 md:col-4 cursor-pointer" (click)="onProductClick(product)">
                        <div class="card m-3 border-1 surface-border">
                            <div class="flex flex-wrap gap-2 align-items-center justify-content-between ">
                                <div class="flex align-items-center">
                                    <i class="pi pi-tag mr-2"></i>
                                    <span class="font-semibold">{{ product.category }}</span>
                                </div>
                                <p-tag *ngIf="product.isPinned" severity="info"  [rounded]="true" value="Instalado"></p-tag>
                                <!-- <i *ngIf="product.isPinned" class="bi bi-pin-angle-fill"></i> -->
                            </div>
                            <div class="flex flex-column align-items-center text-center ">
                                <img [src]="product.logo" [alt]="product.key" class="w-2 md:w-4 lg:w-2 my-3 mx-0" />
                                <div class="text-2xl font-bold">{{ product.key | titlecase }}</div>
                                <div class="mt-1 text-sm">{{ product.description }}</div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>

        </div>
    </div>
</div>


<p-sidebar [(visible)]="showSidebar" class="ss-g-custom-sidebar-width" position="right">
    <ng-template pTemplate="header">
        <h3 style="margin-bottom: 0;">Apps > Shopify</h3>
    </ng-template>
    <div class="sidebar-content">
        <p>Você pode carregar seus vídeos e criar vídeos interativos - SlickShorts - ou transmitir um evento ao vivo -
            SlickLives</p>

        <div class="card shadow-none">
            <h3>Credenciais de Acesso</h3>

            <div class="flex flex-column mb-2">
                <label class="w-full ss-g-globalClassLabel">
                    Seu domínio Shopify
                    <div class="p-inputgroup mt-2">

                        <input id="domainInput" pInputText type="text" class="form-control"
                            [value]="selectedStoreApp?.form_value?.domain" readonly />
                        <span class="p-inputgroup-addon">.myshopify.com</span>
                    </div>
                </label>
            </div>

            <div class="flex flex-column mb-2">
                <label class="w-full ss-g-globalClassLabel">Token de acesso</label>
                <input id="accessToken" pInputText type="text" class="form-control"
                    [value]="maskSensitiveData(selectedStoreApp?.access_token || '')" readonly />
            </div>


            <div class="flex flex-column mb-2">
                <label class="w-full ss-g-globalClassLabel">Chave de acesso</label>
                <input id="accessKey" pInputText type="text" class="form-control"
                    [value]="maskSensitiveData(selectedStoreApp?.secret_token || '')" readonly />
            </div>


            <div class="flex flex-column mb-4">
                <label class="w-full ss-g-globalClassLabel">Chave secreta de API</label>
                <input id="apiSecret" pInputText type="text" class="form-control"
                    [value]="maskSensitiveData(selectedStoreApp?.secret_signature || '')" readonly />
            </div>

            <div class="flex justify-content-center">
                <button *ngIf="!connected" pButton pRipple [loading]="loadingBtn" label="Conectar"
                    (click)="connectApp()" class="w-full"></button>
                <button *ngIf="connected" pButton pRipple [loading]="loadingBtn" label="Desconectar"
                    (click)="confirmRemove()" class="w-full"></button>
            </div>
        </div>



    </div>

    <ng-template pTemplate="footer">
        <div style="text-align: right;">
            <button pButton pRipple label="Fechar" (click)="closeSidebar()" class="p-button-outlined "></button>
            <!-- <button pButton pRipple label="Upload" [loading]="loadingBtn" (click)="uploadSave()" [disabled]="uploadOn"
                class="ml-2 mr-2"></button> -->

        </div>
    </ng-template>

</p-sidebar>
<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
<p-dialog header="Aguarde" [(visible)]="displayAguarde" [modal]="true" [closable]="true" [showHeader]="true"
    [dismissableMask]="true">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>