import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { Apps, StoreAppsResponse, StoreAppCreationResponse } from 'src/app/models/apps';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductService {


  path: string = '';
  path2: string = '';
  path3: string = '';


  constructor(private httpClient: HttpClient, private http: HttpClient, private router: Router) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAccessToken()}`
    })
  };


  public getProducts() {
    if (environment.production) {
      return this.httpClient.get(`${environment.serverUrl}/${this.path}`, this.httpOptions);
    } else {
      return this.httpClient.get(`${environment.paymentGenetatorLinkURL}/${this.path}`, this.httpOptions);
    }

  }

  getAppsPublic(): Observable<{ apps: Apps[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
      }),
    };

    const url = environment.production
      ? `${environment.serverUrl}/admin/apps`
      : `${environment.paymentGenetatorLinkURL}/admin/apps`;

    return this.http.get<{ apps: Apps[] }>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar apps:', error);
        return throwError(error);
      })
    );
  }


  getDetailProduct(id: string): Observable<{ product: any }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
  
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }
  
    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/app_product/${id}`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/app_product/${id}`;
  
    return this.http.get<{ product: any }>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar detalhes do produto:', error);
        return throwError(error);
      })
    );
  }


  listProducts(): Observable<{ products: any[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
  
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }
  
    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/show_products`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/show_products`;
  
    return this.http.get<{ products: any[] }>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar detalhes do produto:', error);
        return throwError(error);
      })
    );
  }

  getChildremProducts(id: string): Observable<{ children: any[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
  
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }
  
    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/app_product/${id}/children`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/app_product/${id}/children`;
  
    return this.http.get<{ children: any[] }>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar produtos filhos:', error);
        return throwError(error);
      })
    );
  }
  
  getChildremProductsAttributes(id: string): Observable<{ attributes: any[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      }),
    };
  
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }
  
    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/app_product/${id}/children/attributes`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/app_product/${id}/children/attributes`;
  
    return this.http.get<{ attributes: any[] }>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar atributos dos produtos filhos:', error);
        return throwError(error);
      })
    );
  }
  
  

  getAppsStore(key: string): Observable<StoreAppsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
      }),
    };

    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }

    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/store_apps?key=${key}`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/store_apps?key=${key}`;

    return this.http.get<StoreAppsResponse>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar apps:', error);
        return throwError(error);
      })
    );
  }

  createAppsStore(key: string): Observable<StoreAppCreationResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
      }),
    };

    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }

    const payload = {
      key: key,
      status: 'active', // Status padrão
    };

    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/store_apps`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/store_apps`;

    return this.http.post<StoreAppCreationResponse>(url, payload, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao criar integração:', error);
        return throwError(error);
      })
    );
  }

  createAppsStore2(key: string, customPayload?: any): Observable<StoreAppCreationResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
      }),
    };
  
    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }
  
    const defaultPayload = {
      key: key,
      status: 'active', // Status padrão
    };
  
    // Mescla o payload padrão com o customizado, se fornecido
    const payload = { ...defaultPayload, ...customPayload };
  
    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/store_apps`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/store_apps`;
  
    return this.http.post<StoreAppCreationResponse>(url, payload, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao criar integração:', error);
        return throwError(error);
      })
    );
  }



  removeAppsStore(uid: string): Observable<StoreAppsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`,
      }),
    };

    const selectedStoreId = localStorage.getItem('SelectedStoreId');
    let store_id;
    if (selectedStoreId) {
      store_id = Number(selectedStoreId);
    }

    const url = environment.production
      ? `${environment.serverUrl}/admin/store/${store_id}/store_apps/${uid}`
      : `${environment.paymentGenetatorLinkURL}/admin/store/${store_id}/store_apps/${uid}`;

    return this.http.delete<StoreAppsResponse>(url, httpOptions).pipe(
      catchError((error) => {
        console.error('Erro ao buscar apps:', error);
        return throwError(error);
      })
    );
  }


  public getCollections() {
    if (environment.production) {
      return this.httpClient.get(`${environment.serverUrl}/${this.path3}`, this.httpOptions);
    } else {
      return this.httpClient.get(`${environment.paymentGenetatorLinkURL}/${this.path3}`, this.httpOptions);
    }

  }

  public getCategorys() {
    if (environment.production) {
      return this.httpClient.get(`${environment.serverUrl}/${this.path2}`, this.httpOptions);
    } else {
      return this.httpClient.get(`${environment.paymentGenetatorLinkURL}/${this.path2}`, this.httpOptions);
    }

  }


  getAccessToken(): string {
    return localStorage.getItem("access_token") || '';
  }

}
