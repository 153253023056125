import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/api/user';
import { ChatService } from './service/chat.service';
import { ChatWebSocketService } from 'src/app/services/chat-web-socket.service';
import { ChangeDetectorRef } from '@angular/core';
@Component({
    selector: 'app-chat-app',
    templateUrl: './chat.app.component.html'
})
export class ChatAppComponent implements OnInit, OnDestroy {
    @Input() showSidebar: boolean = true;
    @Input() showTopChat: boolean = true;
    @Input() admin?: boolean;
    subscription: Subscription;
    isLoading: boolean = false;
    activeUser!: User;
    handleLoading(isLoading: boolean) {
        this.isLoading = isLoading;
        this.cdr.detectChanges();
    }
    @Input() chatId: string = '';
    messages: any[] = [];
    messageContent: string = '';

    constructor(private chatService: ChatService, private chatWebSocketService: ChatWebSocketService, private cdr: ChangeDetectorRef) {
        this.subscription = this.chatService.activeUser$.subscribe(data => this.activeUser = data);
    }

    ngOnInit() {
        console.log('entrou em ChatAppComponent')
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.chatWebSocketService.disconnect();
    }

    fetchInitialMessages() {
        this.chatWebSocketService.getInitialMessages(this.chatId).subscribe((messages: any[]) => {
            this.messages = messages;
        });
    }
}
