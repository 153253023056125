import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugify'
})
export class SlugifyPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return value
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Substitui caracteres não alfanuméricos por "-"
      .replace(/^-+|-+$/g, '');   // Remove "-" no início ou no final da string
  }
}
