<div class="grid">
    <div class="mb-4 w-1/2 mx-auto text-center">
        <p-selectButton class="w-full" [options]="pageOptions" (ngModelChange)="selecionarOpcao($event.value)"
            [(ngModel)]="pageConfigs" optionLabel="name">
            <ng-template let-option pTemplate="item">
                <div class="flex align-items-center justify-content-center w-full">
                    <i [ngClass]="option.icon" class="mr-2"></i>
                    <span class="font-bold">{{ option.name }}</span>
                </div>
            </ng-template>
        </p-selectButton>
    </div>
</div>
<div class="grid ss-l-marginProfileConfig" *ngIf="activeOption == 1">
    <div class="col-12 xl:col-6">
        <div class="card">
            <div class="flex align-items-center justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">
                    Informações do usuário
                </span>
            </div>
            <div>
                Mantenha seus dados atualizados.
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Nome
                    <div class="p-inputgroup mt-2">
                        <input type="text" id="title" pInputText class="w-full" [(ngModel)]="user_name"
                            (ngModelChange)="checkInput1()">
                    </div>
                </label>
                <div class="flex align-items-center justify-content-between mt-2">
                    <!-- Mensagem de Erro à esquerda -->
                    <small *ngIf="errorName" class="p-error">
                        O nome não pode estar vazio
                    </small>
                </div>
            </div>

            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Sobrenome
                    <div class="p-inputgroup mt-2">
                        <input type="text" id="title" (ngModelChange)="checkInput2()" pInputText class="w-full"
                            [(ngModel)]="user_sobrenome">
                    </div>
                </label>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Nome que aparecerá no chat
                    <div class="p-inputgroup mt-2">
                        <input type="text" id="title" (ngModelChange)="checkInput3()" pInputText class="w-full"
                            [(ngModel)]="chat_name">
                    </div>
                </label>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Email
                    <div class="p-inputgroup mt-2">
                        <input type="text" pInputText class="w-full" [(ngModel)]="email"
                            (ngModelChange)="checkInputEMail()" disabled>
                        <span class="p-inputgroup-addon" style="padding: 0;">
                            <button pButton type="button" icon="pi pi-pencil" (click)="openDialogEmail()"
                                class="p-button-outlined border-none"></button>
                        </span>
                    </div>
                </label>
                <div class="flex align-items-center justify-content-between mt-2">
                    <!-- Mensagem de Erro à esquerda -->
                    <!-- <small *ngIf="errorEmail" class="p-error">
                        O email não pode estar vazio
                    </small> -->
                </div>
            </div>

            <span class="ss-g-globalClassLabel flex-start" style="align-self:flex-start">Adicione uma foto ao seu
                perfil</span>
            <div class="mt-2"></div>
            <p-fileUpload #fileUpload name="demo[]" accept="image/*" [multiple]="false" *ngIf="!logoPreviewUrl"
                (onSelect)="onLogoSelect($event)" [customUpload]="true" [showCancelButton]="false"
                class="cursor-pointer" styleClass="border-1 surface-border surface-card border-round">
                <ng-template pTemplate="content">
                    <div (click)="fileUpload.choose()">
                        <div class="content">
                            <!-- Exibir apenas se nenhum arquivo for selecionado -->
                            <div *ngIf="!logoSelected"
                                class="flex flex-column justify-content-center align-items-center">
                                <i class="pi pi-file text-4xl text-primary"></i>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-fileUpload>

            <div *ngIf="logoPreviewUrl" class="image-container">
                <img [src]="logoPreviewUrl" class="image-preview">
                <button *ngIf="logoPreviewUrl" pButton pRipple type="button" icon="pi pi-times" class="close-button"
                    (click)="removelogo($event)"></button>
            </div>
            <div class="flex align-items-center justify-content-between">
                <!-- Mensagem de Erro à esquerda -->
                <small *ngIf="erroTamanhoImagem" class="p-error">
                    Tamanho da imagem deve ser de no máximo 1 mega
                </small>
            </div>
            <div class="flex align-items-center justify-content-between">
                <!-- Mensagem de Erro à esquerda -->
                <small *ngIf="erroTipoImagem" class="p-error">
                    Tipo da imagem deve ser .png, .webp, .jpg ou jpeg
                </small>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-6">

    </div>
</div>
<div class="grid h-full" *ngIf="activeOption == 2">
    <div class="col-12 xl:col-6">
        <div class="card">
            <div class="flex align-items-center justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">
                    Alteração de senha
                </span>
            </div>
            <div>
                Para realizar a alteração de senha, siga as instruções abaixo.
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Senha atual
                    <div class="p-inputgroup mt-2">
                        <input type="password" id="title" (ngModelChange)="checkLogin()" pInputText class="w-full"
                            [(ngModel)]="senha_atual">
                    </div>
                    <a class="link-right cursor-pointer" (click)="forgot()"
                        style="float: right; margin-top: 8px;">Esqueci minha
                        senha</a>
                </label>
                <div *ngIf="errorPasswordAtual" class="flex align-items-center justify-content-between mt-2">
                    <!-- Mensagem de Erro à esquerda -->
                    <span class="p-error">
                        A senha atual não é a correta!
                    </span>
                </div>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Nova senha
                    <div class="p-inputgroup mt-2">
                        <input type="password" id="title" pInputText class="w-full" [(ngModel)]="nova_senha"
                            (ngModelChange)="checkPassword()">
                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="errorPassword" class="p-error">
                            A senha deve ter pelo menos 6 caracteres
                        </small>
                    </div>
                </label>
            </div>
            <div class="col-12 mb-4 mt-4 p-0">
                <label class="w-full ss-g-globalClassLabel">
                    Confirmar senha
                    <div class="p-inputgroup mt-2">
                        <input type="password" id="title" pInputText class="w-full" [(ngModel)]="confirm_senha"
                            (ngModelChange)="checkConfirmPassword()">
                    </div>
                    <div class="flex align-items-center justify-content-between mt-2">
                        <!-- Mensagem de Erro à esquerda -->
                        <small *ngIf="errorConfirmPassword" class="p-error">
                            As senhas não coincidem.
                        </small>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-6">

    </div>
</div>
<div class="grid h-full" *ngIf="activeOption == 3">
    <div class="col-12 xl:col-6">
        <div class="card">
            <div class="flex align-items-center justify-content-between mb-2">
                <span class="text-900 text-xl font-semibold">
                    Limite e uso
                </span>
                <span class="ml-auto">
                    <p-tag value="Beta" severity="warning"></p-tag>
                </span>
            </div>
            <div class="mb-4">
                Acompanhe os limites de sua conta e entenda quando fazer um upgrade ou suas faturas são calculadas.
            </div>
            <label class="w-full ss-g-globalClassLabel">Visualizações</label>
            <div class="mt-4"></div>
            <div style="position: relative; width: 100%;">
                <div
                    style="position: absolute; left: 0; right: 0; top: -20px; display: flex; justify-content: space-between;">
                    <span>0</span>
                    <span>1000</span>
                </div>
                <div class="slider-container">
                    <p-slider [(ngModel)]="sliderValueViews" [min]="0" [max]="1000" [disabled]="true"
                        styleClass="w-full" showTicks="true" [step]="1">
                    </p-slider>
                    <!-- Mostrar valor atual/max ao lado direito -->
                    <div class="custom-tick-label" style="position: absolute; right: 0; top: -20px;">
                        {{ sliderValueViews }}/1000
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <label class="w-full ss-g-globalClassLabel mt-2">Slicks</label>
                <div class="mt-4"></div>
                <div style="position: relative; width: 100%;">
                    <div
                        style="position: absolute; left: 0; right: 0; top: -20px; display: flex; justify-content: space-between;">
                        <span>0</span>
                        <span>10</span>
                    </div>
                    <div class="slider-container">
                        <p-slider [(ngModel)]="sliderValueSlicks" [min]="0" [max]="10" [disabled]="true"
                            styleClass="w-full hide-handle" showTicks="true" [step]="1"></p-slider>
                        <div class="custom-tick-label" style="position: absolute; right: 0; top: -20px;">
                            {{ sliderValueSlicks }}/10
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-6">

    </div>
</div>


<p-confirmDialog header="Confirmação" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<p-dialog header="Recuperação de Senha" [(visible)]="display" [modal]="true" [style]="{width: '350px'}"
    [baseZIndex]="10000">
    <div class="confirmation-content">
        <p>Você receberá um email para recuperar a senha. Deseja continuar?</p>
    </div>
    <p-footer>
        <button type="button" pButton label="Sim" icon="pi pi-check" (click)="confirmForgot()"></button>
        <button type="button" pButton label="Não" icon="pi pi-times" (click)="display=false"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>
<!-- <p-dialog header="Insira novo email" [(visible)]="showDialog" [modal]="true" [style]="{'width': '50vw'}">
    <div>
        <input type="text" pInputText [(ngModel)]="newEmail" placeholder="Digite o novo email">
    </div>
    <div class="actions">
        <button pButton label="Enviar" icon="pi pi-check" (click)="updateEmail()"></button>
        <button pButton label="Cancelar" icon="pi pi-times" (click)="showDialog = false"></button>
    </div>
</p-dialog> -->




<p-sidebar [(visible)]="displaySidebar" (onShow)="handleSidebarOpen()" #sidebar id="ss-l-sideBarBottom"
    class="ss-g-custom-sidebar-width" position="bottom" [baseZIndex]="1102">
    <ng-template pTemplate="footer">
        <div class="w-full  text-right p-0" *ngIf="activeOption == 1">
            <button pButton pRipple label="Cancelar" (click)="closeSidebar()" class="p-button-outlined mr-2"></button>
            <button pButton pRipple label="Salvar" [disabled]="disableSave" [loading]="loadingBtn"
                (click)="saveProfile()" class="primary"></button>
        </div>
        <div class="w-full  text-right p-0" *ngIf="activeOption == 2">
            <button pButton pRipple label="Cancelar" (click)="closeSidebar()" class="p-button-outlined mr-2"></button>
            <button pButton pRipple label="Salvar" [disabled]="btnSaveSecurity" [loading]="loadingBtnSecurity"
                (click)="changePassword()" class="primary"></button>
        </div>
    </ng-template>
</p-sidebar>


<p-dialog header="Alterar Email" [(visible)]="showDialog" [modal]="true" [closable]="true" [showHeader]="true"
    [dismissableMask]="true">
    <p><input type="text" pInputText class="w-full" [(ngModel)]="newEmail" placeholder="Digite o novo email"
            (ngModelChange)="onEmailChange($event)"></p>
    <div *ngIf="errorEmail" class="flex align-items-center justify-content-between mt-2">
        <!-- Mensagem de Erro à esquerda -->
        <span class="p-error">
            Insira um emal válido
        </span>
    </div>
    <div *ngIf="errorMessage" class="flex align-items-center justify-content-between mt-2">
        <!-- Mensagem de Erro dinâmica -->
        <span class="p-error">
            {{ errorMessage }}
        </span>
    </div>
    <p-footer>
        <button pButton label="Enviar email de confirmação" [disabled]="!isEmailValid || isLoading"  [loading]="isLoading" class="w-full" icon="pi pi-check"
            (click)="updateEmail()"></button>
    </p-footer>
</p-dialog>


<p-dialog header="Aguarde" [(visible)]="displayAguarde" [modal]="true" [closable]="true" [showHeader]="true"
    [dismissableMask]="true">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
</p-dialog>