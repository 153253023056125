import { Component, OnInit, ViewContainerRef, ViewChild, Input, OnDestroy, HostListener, ChangeDetectionStrategy, AfterViewInit, DoCheck, OnChanges, SimpleChanges } from '@angular/core';
import { WebsocketService } from '../../services/websocket.service';
import { BehaviorSubject } from 'rxjs';import { SidebarService } from 'src/app/services/sidebar.service';
import { ChangeDetectorRef } from '@angular/core'; import { ShowProductsComponent } from '../show-products/show-products.component';
import { NgZone } from '@angular/core';
import { combineLatest, map, of, firstValueFrom } from 'rxjs';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UnregisterSwService } from 'src/app/services/unregister-sw/unregister-sw.service';
import { ChatWebSocketService } from 'src/app/services/chat-web-socket.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { PickListObserverService } from 'src/app/services/pick-list-observer.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ElementRef, Renderer2 } from '@angular/core';
import { formatDate } from '@angular/common';
import { LazyLoadEvent } from 'primeng/api';
//import { TableLazyLoadEvent } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { MegaMenuItem } from 'primeng/api';
const RecordRTC = require('recordrtc');
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { Metric } from 'src/app/api/metric';
// import { FileAppService } from '../../demo/service/file.app.service';
declare var videojs: any;
import { LiveShopService } from 'src/app/services/liveshop.service';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { UserCardComponent } from './user-card/user-card.component';
//import videojs from 'video.js';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { withLatestFrom } from 'rxjs/operators';
import { ChatSidebarComponent } from './../chat/chat-sidebar/chat-sidebar.component';
import { User } from 'src/app/api/user';
import { ChatService } from '../chat/service/chat.service';
//import { PhotoService } from 'src/app/demo/service/photo.service';
import { MediaStreamService } from 'src/app/services/media-stream.service';
import { WalletService } from 'src/app/services/wallet.service';
import { IWallet } from 'src/app/@types/Wallet';
import { HomeService } from 'src/app/services/home.service';
import { LiveStatusService } from 'src/app/services/live-status.service';
import { interval } from 'rxjs';
import { LiveShop, Item, Coupon, AnimatedText } from 'src/app/models/liveshop';
import { Observable, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CdkDropList } from '@angular/cdk/drag-drop';
import domtoimage from 'dom-to-image';
import { v4 as uuidv4 } from 'uuid';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { from, defer } from 'rxjs';
import { tap, catchError, finalize, switchMap } from 'rxjs/operators';
import { StateManagementService } from 'src/app/services/state-management.service';
import * as QRCode from 'qrcode';
import { SwiperOptions } from 'swiper';
import { Swiper } from 'swiper';
import { ManangeLayoutService } from 'src/app/services/manange-layout.service';
import { throwError } from 'rxjs';
import { FormatDurationPipe } from 'src/app/shared/format-duration.pipe';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn
} from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { GalleriaThumbnails } from 'primeng/galleria';

@Pipe({
  name: 'highlightLimit'
})
export class ClockData {
  id?: string;
  stream_key?: string;
}
interface SlickType {
  label: string;
  value: string;
}
interface Produto {
  id: number;
  sku: number;
  name: string;
  status: number;
  inputQty: number;
  position: number;
  price: string;
  onsale_price: string;
  price_exchange: string;
  thumbnail: string;
  isSelected?: boolean;
  show_item?: boolean;
  highlight?: boolean;
  define_interval?: boolean;
  show_slider?: boolean;
  comprar_font_color?: string;
  comprar_bg_color?: string;
  adicionar_font_color?: string;
  adicionar_bg_color?: string;
  interval_time?: { start: string; end: string };

}
interface ProdutoShopify {
  id: number;
  title: string;
  sku: number;
  description: string;
  vendor: string;
  name: string;
  product_type: string;
  created_at: string;
  updated_at: string;
  categories: { id: string; title: string }[];
  thumbnail: string;
  image: string;
  price: string;
  compare_at_price: string | null;
  inventory_quantity: number;
  product_url: string;

  define_interval?: boolean;
  highlight?: boolean;
  isSelected?: boolean;
  show_item?: boolean;
  comprar_font_color?: string;
  comprar_bg_color?: string;
  adicionar_font_color?: string;
  adicionar_bg_color?: string;
  interval_time?: { start: string; end: string };
}
interface Promotion {
  id: number;
  name: string;
  value: number;
  status: number;
  show_item?: boolean;
  isSelected?: boolean;
  highlight?: boolean;
  define_interval?: boolean;
  show_slider?: boolean;
  interval_time?: { start: string; end: string };
}
interface Custom {
  id: number;
  interval_time?: { start: string; end: string };
}
interface AccordionTab {
  header: string;
  button_text?: string;
  redirect_url?: string;
  font_color_custom?: string;
  bg_color_custom?: string;
  define_interval?: boolean;
  show_item?: boolean;
  interval_time?: { start: string; end: string };
  slider_time_range: [number, number];
}
interface AlertsTab {
  header: string;
  text?: string;
  showAlerts?: boolean;
  font_color?: string;
  background?: string;
  define_interval?: boolean;
  show_item?: boolean;
  interval_time?: { start: string; end: string };
  slider_time_range: [number, number];
}
interface Links {
  id: number;
  name?: string;
  show_link?: boolean;
  bg_color?: string;
  position?: number;
  redirect_url?: string;
  click_url?: string;
  link_type?: string;
  define_interval?: boolean;
  show_item?: boolean;
  time_from?: string;
  time_to?: string;
  interval_time?: { start: string; end: string };
  slider_time_range: [number, number];
}
interface WhatsAppTab {
  header: string;
  ddd?: string;
  fone?: string;
  redirect_url?: string;
  define_interval?: boolean;
  show_item?: boolean;
  interval_time?: { start: string; end: string };
  slider_time_range: [number, number];
}
interface SlickMaps {
  id: number;
  uniq_id: string;
  asset_uniq_id: string;
  url: string;
  status: number;
  created_at: string;
  updated_at: string;
  type: number;
}
interface SocketErrorEvent {
  event: string;
  error: any; // Você pode especificar um tipo mais específico para o erro, se disponível
}
interface Interval {
  start: number;
  end: number;
  source: string;
}
interface IconResponse {
  data: Gif[];
  meta: Meta;
}

interface Meta {
  pagination: Pagination;
}
interface Pagination {
  total: number;
  last_page: number;
  per_page: number;
  current_page: number;
}
interface Icon {
  id: number;
  name: string;
  created: string;
  slug: string;
  style: { id: number, name: string };
  family: { id: number, name: string, total: number };
  free_svg: boolean;
  author: { id: number, name: string, slug: string, avatar: string, assets: number };
  thumbnails: { width: number, height: number, url: string }[];
}
interface Gif {
  type: string;
  id: string;
  url: string;
  images: {
    downsized: {
      url: string;
    };
    // Inclua outras versões conforme necessário
  };
  title: string;
}

interface GifResponse {
  data: Gif[];
  meta: {

  };
  pagination: {
    total_count: number;
    last_page: number;
    per_page: number;
    current_page: number;
  };
}
interface Tab {
  define_interval_custom?: boolean;
  interval_time: { start: string; end: string };
  define_interval?: boolean;
}
export interface AlertChange {
  index: number;
  text?: string;
  fontColor?: string;
  backgroundColor?: string;
}
@Component({
  selector: 'app-slick-detail',
  templateUrl: './slick-detail.component.html',
  styleUrls: ['./slick-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),
      transition(':leave', [
        animate(10, style({ opacity: 0 }))
      ])
    ]),
    trigger('slideInUp', [
      transition(':enter', [
        style({ height: '0', overflow: 'hidden' }), // Começa com altura 0
        animate('300ms ease-in', style({ height: '*' })) // Cresce até a altura definida no CSS
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ height: '0' })) // Diminui até altura 0 ao sair
      ])
    ]),
    trigger('slideInUpChat', [
      transition('void => *', [
        style({ height: '0', overflow: 'hidden' }),
        animate('600ms ease-in', style({ height: '*' }))
      ]),
      transition('* => void', [
        animate('600ms ease-out', style({ height: '0', overflow: 'hidden' })) // Aumenta a duração para 600ms
      ])
    ])
  ]
})
export class SlickDetailComponent implements OnInit, OnDestroy, DoCheck, OnChanges {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true })
  dynamicComponentContainer!: ViewContainerRef;

  @ViewChild('videoUploadContainer') videoUploadContainer?: ElementRef;
  @ViewChild('videoUploadContainerInside') videoUploadContainerInside?: ElementRef;
  @ViewChild('videoInner') videoInnerRef?: ElementRef;
  @ViewChild('videoElement') videoElementRef?: ElementRef;
  loadingContent?: ElementRef;
  @ViewChild('controlsContainer') controlsContainer?: ElementRef;
  @ViewChild('fallbackVideoElement') fallbackVideoElement?: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement?: ElementRef<HTMLCanvasElement>;
  @ViewChild('imagemElement') imagemElement?: ElementRef<HTMLImageElement>;
  uuid?: string | null;
  metrics: Metric[] = [];
  menuitems: MenuItem[] = [];
  items!: MenuItem[];

  boundOnDragging: any;
  boundOnDragEnd: any;
  lastVolume: number = 0.5;
  private colorSubscription: Subscription;

  private subscriptionsGeneric: Subscription = new Subscription();
  noPadding: boolean = false;
  toggleLayoutClass() {
    this.manangeLayoutService.toggleClass();
    this.noPadding = !this.noPadding;
  }
  constructor(
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private confirmationService: ConfirmationService,
    private breakpointObserver: BreakpointObserver,
    private breakpointObserverScreen: BreakpointObserver,
    private manangeLayoutService: ManangeLayoutService,
    private unregisterSwService: UnregisterSwService,
    private cdr: ChangeDetectorRef,
    private chatService: ChatService,
    private liveShopService: LiveShopService,
    private el: ElementRef,
    private router: Router,
    private messageService: MessageService,
    private mediaStreamService: MediaStreamService,
    private websocketService: WebsocketService,
    private walletService: WalletService,
    private auth: AuthService,
    private productsService: PedidosService,
    private liveStatusService: LiveStatusService,
    private homeService: HomeService,
    public fb: FormBuilder,
    private ngZone: NgZone,
    public stateManagementService: StateManagementService,
    public pickListObserverService: PickListObserverService,
    private chatWebSocketService: ChatWebSocketService,
    private renderer: Renderer2

  ) {
    this.boundBeforeUnloadHandler = this.handleBeforeUnload.bind(this);
    if (this.stream) {
      this.stopMediaTracks(this.stream);
    }
    this.liveStatusSubscription = this.liveStatusService.subscribe((newState: 'idle' | 'offline' | 'online') => {

      if (newState === 'offline') {
        this.firstSuccessfulSend = false;
        this.recording = false;
        this.recording = false;
        if (this.updateSubscription) {
          this.updateSubscription.unsubscribe();
        }
        clearTimeout(this.tertInterval);
        clearTimeout(this.analyticsInterval);
        this.tertInterval = null;
        this.analyticsInterval = null;
        //this.stopMediaTracks(this.stream);
        this.pauseSlick = false;



      } else if (newState === 'online') {
        // if (!this.tertInterval) {
        //   this.tertInterval = setInterval(() => {
        //     this.getLiveTert();
        //   }, 30000);
        // }
        // if (!this.analyticsInterval) {
        //   this.analyticsInterval = setInterval(() => {
        //     this.getLiveAndUpdagte();
        //   }, 60000);
        // }
        this.pauseSlick = false;

      } else if (newState === 'idle') {
        this.pauseSlick = true;
      }
      this.liveStatus = newState;
    });

    this.issueForm = this.fb.group({
      // defina os controles do formulário aqui
      // exemplo: 'name': ['']
    });

    this.colorSubscription = this.stateManagementService.getAccordionColors().pipe(takeUntil(this.destroy$)).subscribe(colors => {
      this.playPauseColor = colors.fontColor;
      this.barColor = colors.bgColor;
    });
    this.boundOnDragging = this.onDragging.bind(this);
    this.boundOnDragEnd = this.onDragEnd.bind(this);
    this.lastVolume = parseFloat(localStorage.getItem('lastVolume') || '0.5');


    this.dragEventsSubscription = this.dragEvents.pipe(
      debounceTime(100)  // ajuste conforme necessário
    ).pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.moveMarker(event);
    });



    this.updateItemsDock();


  }
  updateItemsDock() {
    this.itemsDock = [
      {
        label: 'Finder',
        icon: this.showCTAs ? 'assets/aberto.png' : 'assets/fechado.png',
        command: (event: any) => {
          this.toggleShowCTAs();
        }
      },
      {
        label: 'Sidebar Right',
        icon: 'assets/magic.png',
        command: (event: any) => {
          this.openSidebar();
        }
      },
      {
        label: 'Sidebar Bottom',
        icon: 'assets/more.png',
        command: (event: any) => {
          this.openSidebarBottom();
        }
      }
    ];
  }

  handleBeforeUnload(event: BeforeUnloadEvent) {
    // Custom logic here
    event.returnValue = "You'll never see this as the browser overrides it!";
    if (this.messageContainer && this.messageContainer.nativeElement) {
      const messageElement = document.createElement("div");
      messageElement.innerHTML = "<p style='color: red'>Don't close me bro!</p>";
      this.messageContainer.nativeElement.appendChild(messageElement);
    }
    console.log('Usuário está tentando sair da página');
  }
  displayReconnectDialog: boolean = false;
  reconnectAttempts: number = 0;
  reconnectMessage: string = 'Tentando reconectar...';
  monitorSocketErrors(): void {
    this.websocketService.errorObservable.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error: any) => {
        console.log('chegou no monitor de erros: ' + error.event);
        clearInterval(this.timer);
        if (error.event !== 'disconnect') {
          if (this.reconnectAttempts === 0) {
            this.promptReconnect();
          } else if (this.reconnectAttempts === 3) {
            this.disconnectSocket();
          }
        } else {
          if (this.stream) {
            this.stopMediaTracks(this.stream);
          }
        }
      },
      error: (err: any) => console.error('Erro ao monitorar o socket:', err)
    });
  }


  /// área de reconexão
  promptReconnect(): void {
    this.displayReconnectDialog = true;
    this.reconnectMessage = 'Tentando reconectar...';
    this.tryReconnect();
  }
  timeOutReconnect: any;
  tryReconnect(): void {
    if (this.reconnectAttempts < 3) {
      this.reconnectAttempts++;
      this.reconnectMessage = `Tentativa ${this.reconnectAttempts} de 3...`;

      this.websocketService.reconnect().then(() => {
        // Reconexão bem-sucedida, feche o diálogo
        this.displayReconnectDialog = false;
        this.startRecording(0);
      }).catch((error) => {
        // Falha na reconexão, aguarde um momento antes da próxima tentativa
        if (this.reconnectAttempts < 3) {
          this.timeOutReconnect = setTimeout(() => {
            //this.reconnectAttempts = 1;
            this.tryReconnect();
          }, 2000);


        } else {
          // Falhou após 3 tentativas, feche o diálogo e desconecte
          this.onDisconnectClick();
        }
      });
    } else {
      // Falhou após 3 tentativas, feche o diálogo e desconecte
      this.onDisconnectClick();
    }
  }



  onDisconnectClick(): void {
    this.reconnectAttempts = 3;
    this.disconnectSocketRecon();
    this.displayReconnectDialog = false; // Feche o diálogo
    this.websocketService.sendMessage({ action: 'stop' });
    this.websocketService.disconnectSocket();
    this.stopMediaTracks(this.stream);
    this.websocketService.disconnect();
  }

  disconnectSocket(): void {
    this.websocketService.disconnectSocket();
    this.websocketService.disconnect();
    this.websocketService.sendMessage({ action: 'stop' });
  }
  disconnectSocketRecon(): void {
    this.websocketService.disconnect();
  }
  /// área de reconexão
  reconnect(): void {
    // Chama o método de reconexão do seu WebsocketService
    this.websocketService.reconnect();
  }
  tertInterval: any = 0;
  analyticsInterval: any = 0;
  private dragEventsSubscription?: Subscription;
  forceFocus(event: MouseEvent): void {
    (event.target as HTMLInputElement).focus();
  }
  private updateSubscription?: Subscription;
  liveStatus: 'idle' | 'offline' | 'online' = 'offline';
  videoUrl: string = '';
  fullScreen: boolean = false;
  handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      // Atualiza o estado quando sair do modo de tela cheia
      this.fullScreen = false;
      if (this.videoUploadContainerInside) {
        this.videoUploadContainerInside.nativeElement.style.marginLeft = '';
      }
      //this.adjustVideoMarginSubMenu();

    }
    this.showMenuInf = false;
    // const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
    // (videoJsControlBar[0] as HTMLElement).style.display = 'none';
    //if (!this.fullScreen) {
    this.marginTopVideo();
    //}
    if (this.valSelect1Video.value == 1) {
      this.aplicarEstiloFullscreen();
      //this.adjustVideoMargin();
    }

    if (!this.typeLive) {
      if (this.fullScreen) {
        this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize' style='cursor:pointer;'></i>";
      } else {
        this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize' style='cursor:pointer;'></i>";
      }
      this.myButtonDom.onclick = () => {
        if (this.fullScreen) {
          this.goMinscreen();
        } else {
          this.goFullscreen();
        }
      };
    }
    this.playerBorderRadius();
    //this.adjustVideoMargin();

  }
  showMenuInf = false;
  timeoutRef: any = null;
  private destroy$ = new Subject<void>();
  private liveStatusSubscription: Subscription;



  private cameraChangeProcessedSubscription?: Subscription;
  getActionMenu = () => { // Usando arrow function para manter o contexto de `this`
    return [
      { label: 'Ver Slick', icon: 'pi pi-file-edit', command: () => this.redirectToSlick() },
      { label: 'Compartilhar', icon: 'pi pi-trash', command: () => this.compartilhar() },
      // { label: 'RTMP infos', icon: 'pi pi-pencil', command: () => this.rtmpInfos() },
      // { label: 'Recarregar saldo', icon: 'pi pi-pencil', command: () => this.confirmDeletion() },
    ];
  }
  redirectToSlick() {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const domainStore = localStorage.getItem('domain_store');

    // Verificar e obter o subdomínio
    let subdomain = '';
    let domain = '';
    if (domainStore) {
      const parts = domainStore.split('.');
      if (parts.length >= 2) {
        subdomain = parts[0]; // Atribui a primeira parte como subdomínio
        domain = parts.slice(1).join('.'); // Junta o restante das partes para formar o domínio
      } else {
        domain = parts.join('.'); // Caso só exista um domínio sem subdomínio
      }
    }
    domain = 'slicksell.link';
    // Obter o UID da URL atual
    const currentUrl = window.location.href;
    const uidMatch = currentUrl.match(/\/slick\/([^/]+)/);
    const uid = uidMatch ? uidMatch[1] : '';

    if (!uid) {
      console.error('UID não encontrado na URL atual.');
      return;
    }

    // Construir a URL de redirecionamento
    const redirectUrl = `${window.location.protocol}//${domain}/${subdomain}?slick_id=${uid}`;


    console.log(redirectUrl);
    // Redirecionar para a nova URL em uma nova aba
    window.open(redirectUrl, '_blank');
  }

  confirmDeletion() {

  }

  showMenu(): void {
    this.showMenuInf = true;
    // const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
    // (videoJsControlBar[0] as HTMLElement).style.display = 'flex';
    //this.adjustVideoMarginSubMenu();
    this.resetHideTimer();
  }

  hideMenu(): void {
    // Intencionalmente vazio para evitar esconder imediatamente quando o mouse sai
  }

  onMouseMove(): void {
    //if (!this.isLive) {
    this.showMenuInf = true;
    const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
    if (videoJsControlBar.length > 0) {
      (videoJsControlBar[0] as HTMLElement).style.display = 'flex';
    }

    this.adjustVideoMarginSubMenu();
    // Garante que o menu só será escondido após 3 segundos sem movimento
    if (!this.showMenuInf) {
      console.log("mostrandoMenu");
      this.showMenu();
    } else {
      this.resetHideTimer();
    }
    // }
  }
  //
  resetHideTimer(): void {
    if (!this.isLive) {
      this.clearHideTimer();
      this.timeoutRef = setTimeout(() => {
        if (this.showMenuInf) {
          const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
          //(videoJsControlBar[0] as HTMLElement).style.display = 'none';
          this.showMenuInf = false;
        }
      }, 3000); // Esconde após 3 segundos sem movimento
    }
  }

  clearHideTimer(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
  }

  onTouch(event?: Event): void {
    //if (!this.isLive) {
    const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
    if (videoJsControlBar.length > 0) {
      (videoJsControlBar[0] as HTMLElement).style.display = 'flex';
    }
    if (!this.showMenuInf) {
      this.showMenuInf = true;
      this.adjustVideoMarginSubMenu();
      //
      this.resetHideTimer();
      if (event) {

        event.stopPropagation();
      }
    }
    //}
  }



  openDialog() {
    this.displayRTMPInofos = true;
  }
  displaySidebar: boolean = false;
  displaySidebarSaldo: boolean = false;
  displaySidebarBottom: boolean = false;
  sidebarGerenciar: boolean = false;
  sidebarChatApp: boolean = false;
  timeOutCloseSideBar: any;
  onSidebarClose() {
    this.timeOutCloseSideBar = setTimeout(() => {
      document.body.classList.remove('body-fixed');
    }, 300);
  }
  openSidebarBottom() {
    //document.body.classList.add('body-fixed');
    this.displaySidebarBottom = true;
  }
  openSidebarSaldo() {
    this.displaySidebarSaldo = this.displaySidebarSaldo == true ? false : true;;
    this.displaySidebar = false;
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  backStudio() {
    this.mananger = 0;
    this.pageStudio = 'Studio';
    this.pageStudioNow = '';
    this.sidebarChatApp = false;
    this.sidebarGerenciar = true;
  }
  openSidebar() {
    this.displaySidebarSaldo = false;
    if (this.sidebarChatApp) {
      this.displaySidebar = true;
    } else {
      this.displaySidebar = this.displaySidebar == true ? false : true;
    }
    if (this.observer && this.displaySidebar == false) {
      this.observer.disconnect();
    }
    this.sidebarGerenciar = true;
    this.mananger = 0;
    this.pageStudio = 'Studio';
    this.pageStudioNow = '';
    this.sidebarChatApp = false;

  }

  @HostListener('window:resize')
  onResize() {
    this.updateDynamicStyle(); // Atualiza o estilo quando a janela é redimensionada
    this.updateProgressBarPosition();
    this.updateProgressBarWidth();
  }
  micShow: boolean = true;





  async adjustVideoAspectRatio(videoData: Blob, targetAspectRatio: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        // Calcular as novas dimensões
        let newWidth = video.videoHeight * targetAspectRatio;
        let newHeight = video.videoHeight;

        // Caso queira cortar em vez de ajustar somente a altura,
        // poderíamos ajustar a largura mantendo a altura e cortar os lados, por exemplo:
        // let newWidth = video.videoWidth;
        // let newHeight = video.videoWidth / targetAspectRatio;

        // Criar um canvas para o redimensionamento
        var canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Não foi possível obter o contexto 2D do canvas.'));
          return;
        }

        // Desenhar o vídeo no canvas com as novas dimensões
        ctx.drawImage(video, 0, 0, newWidth, newHeight);

        // Converter o canvas para Blob
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Falha ao converter canvas para Blob.'));
          }
        }, 'video/webm');
      };

      // É necessário carregar a fonte de vídeo para que os metadados sejam carregados
      video.onerror = (event) => {
        reject(new Error('Falha ao carregar o vídeo.'));
      };

      video.src = URL.createObjectURL(videoData);
      video.load();
    });
  }


  openCloseMic() {
    this.micShow = !this.micShow; // Alternar o estado
    if (this.player) {
      this.player.muted(!this.player.muted()); // Alterna o estado de mute
    }
    if (this.stream) {
      // Acessa todas as faixas de áudio da stream
      this.stream.getAudioTracks().forEach(track => {
        // Alterna o estado de 'enabled' da faixa
        track.enabled = !track.enabled;
      });
    }
  }



  screenWidth?: number;
  screenHeight?: number;
  adjustVideoMarginSubMenu() {
    const menuInf = document.getElementsByClassName('ss-g-menu-inf')[0];
    if (!menuInf) return;

    // Garanta que menuInf é tratado como HTMLElement
    if (!(menuInf instanceof HTMLElement)) return;

    // Detectar o zoom do navegador
    const zoomLevel = window.innerWidth / window.screen.width;
    this.screenWidth = window.screen.width * zoomLevel;
    this.screenHeight = window.screen.height * zoomLevel;

    if (this.fullScreen && this.screenHeight && this.screenWidth) {
      const larguraDoVideo = this.screenHeight * (9 / 16);
      let marginLeftRight = (this.screenWidth - larguraDoVideo) / 2;
      if (marginLeftRight > 0) {
        menuInf.style.setProperty('width', `${larguraDoVideo}px`, 'important');
        menuInf.style.marginLeft = `${marginLeftRight}px`;
        menuInf.style.marginRight = `${marginLeftRight}px`;
      }

    } else {
      menuInf.style.width = `100%`;
      menuInf.style.marginLeft = `0px`;
      menuInf.style.marginRight = `0px`;
    }
  }


  getVideoHeightInRem() {
    if (this.videoElementRef) {
      const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
      const rect = videoElement.getBoundingClientRect();

      // Obter o tamanho da fonte do root (document.documentElement)
      const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

      // Converter a altura do vídeo de pixels para rem
      const heightInRem = rect.height / fontSize;

      return heightInRem;
    }
    return '';
  }
  adjustVideoMargin() {
    const videoJsDivs = document.getElementsByClassName('video-js');
    if (this.fullScreen) {
      const zoomLevel = window.innerWidth / window.screen.width;
      //   this.screenWidth = window.screen.width * zoomLevel; // Ajustar largura da tela ao nível de zoom
      // this.screenHeight = window.screen.height * zoomLevel; 
      console.log('entrou ajuste');
      const screenWidth = window.screen.width * zoomLevel;
      const screenHeight = window.screen.height * zoomLevel;
      let marginLeft = 0;
      let marginRight = 0;
      this.marginRightAdj = marginRight;
      let barLeft = 0;

      const videoJsControlBar = document.getElementsByClassName('vjs-control-bar');
      const videoJsSpinner = document.getElementsByClassName('vjs-loading-spinner');
      if (videoJsDivs.length > 0) {
        const videoWidth = (videoJsDivs[0] as HTMLElement).offsetWidth;
        if (this.fullScreen && this.valSelect1Video.value === 1) {
          const larguraDoVideo = screenHeight * (9 / 16);
          marginLeft = (screenWidth / 2) - (larguraDoVideo / 2);
          if (marginLeft < 0) {
            barLeft = -marginLeft;
          } else {
            barLeft = -marginLeft;
          }
          marginRight = marginLeft;
          this.marginRightAdj = marginRight;
        } else if (!this.fullScreen) {
          marginLeft = 0;
          barLeft = 0;

        }


        if (this.fullScreen) {
          (videoJsDivs[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;
          //(videoJsSpinner[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;

          if (this.fullScreen && videoJsControlBar[0]) {
            (videoJsControlBar[0] as HTMLElement).style.width = 'auto';
          }


          //if (!this.isMobileDevice()) {
          (videoJsDivs[0] as HTMLElement).style.marginRight = `${marginLeft - 1}px`;
          this.marginRightAdj = marginLeft;
          //}
        } else {
          if (this.valSelect1Video.value === 2) {
            (videoJsDivs[0] as HTMLElement).style.width = `${100}%`;
          }
        }

      }
    } else {

      const screenWidth = window.innerWidth;

      console.log('mmmm');
      console.log(screenWidth);
      if (screenWidth < 1733) {
        (videoJsDivs[0] as HTMLElement).style.marginRight = '0px';
        this.marginRightAdj = 1;
      } else if (screenWidth < 1800) {
        if (this.valSelect1Video.value === 1) {
          (videoJsDivs[0] as HTMLElement).style.marginRight = '0px';
          this.marginRightAdj = 2;
        } else {
          (videoJsDivs[0] as HTMLElement).style.marginRight = '0px';
          this.marginRightAdj = 3;
        }

      } else {
        if (this.valSelect1Video.value === 1) {
          (videoJsDivs[0] as HTMLElement).style.marginRight = '0px';
          this.marginRightAdj = 2;
        } else {
          (videoJsDivs[0] as HTMLElement).style.marginRight = '0px';
          this.marginRightAdj = 3;
        }
      }
      (videoJsDivs[0] as HTMLElement).style.marginLeft = '0px';

    }

    if (!this.typeLive) {
      console.log('marginLeft: ' + this.marginLeftAdj)
      console.log('marginLeft: ' + this.marginRightAdj)

      //ss-l-alert-btn
      const alerts = document.getElementsByClassName('ss-l-alert-btn');
      const btnFullScrenn = document.getElementsByClassName('ss-g-postionFullScreen');
      const swiperProducts = document.getElementsByClassName('ss-l-swiper-products');
      const controlBarVideoJS = document.getElementsByClassName('vjs-control-bar');
      const btnCustom = document.getElementsByClassName('ss-l-custom-btn-screen');
      const btnWhats = document.getElementsByClassName('ss-l-custom-btn-whats');
      const gemPromos = document.getElementsByClassName('ss-l-gem-promos');
      if (this.fullScreen) {
        this.cdr.detectChanges();

        if (this.alertsTabs.length > 0 && this.showCTAs) {
          if (alerts && this.alertsTabs.length > 0) {
            (alerts[0] as HTMLElement).style.marginRight = `${this.marginRightAdj - 1}px`;
            (alerts[0] as HTMLElement).style.marginLeft = `${this.marginRightAdj - 1}px`;
          }
        }

        // if (controlBarVideoJS) {
        //   (controlBarVideoJS[0] as HTMLElement).style.marginRight = `${this.marginRightAdj - 1}px`;
        //   (controlBarVideoJS[0] as HTMLElement).style.marginLeft = `${this.marginRightAdj - 1}px`;
        // }

        if (this.targetProducts.length > 0 && this.showCTAs) {

          if (swiperProducts.length > 0) {
            (swiperProducts[0] as HTMLElement).style.marginRight = `${this.marginRightAdj - 15}px`;
            (swiperProducts[0] as HTMLElement).style.marginLeft = `${this.marginRightAdj - 15}px`;
            (swiperProducts[0] as HTMLElement).style.top = 'calc(20% - 50px);';
          }
        }


        if (gemPromos && this.targetPromotions.length > 0) {
          const element = gemPromos[0] as HTMLElement;
          element.style.cssText += `margin-left: ${this.marginRightAdj - 1}px !important;`;
        }
      } else {
        this.cdr.detectChanges();
        if (this.alertsTabs.length > 0 && this.showCTAs) {
          if (alerts && this.alertsTabs.length > 0) {
            (alerts[0] as HTMLElement).style.marginRight = '';
            (alerts[0] as HTMLElement).style.marginLeft = '';
          }
        }
        console.log('teste de medidas')
        if (this.videoElementRef) {
          const heightInRem = this.getVideoHeightInRem();
          console.log('ff ' + heightInRem);
        }


        // if (controlBarVideoJS) {
        //   (controlBarVideoJS[0] as HTMLElement).style.marginRight = '';
        //   (controlBarVideoJS[0] as HTMLElement).style.marginLeft = '';
        // }
        if (this.targetProducts.length > 0 && this.showCTAs) {
          if (swiperProducts) {
            (swiperProducts[0] as HTMLElement).style.marginRight = '';
            (swiperProducts[0] as HTMLElement).style.marginLeft = '';
            //(swiperProducts[0] as HTMLElement).style.top = 'calc(85% - 50px);';
          }
        }


        // if (gemPromos && this.targetPromotions.length > 0) {
        //   (gemPromos[0] as HTMLElement).style.marginLeft = '';
        // }
      }
    }
    //ss-g-sidebar-promotions
    const zoomLevel = window.innerWidth / window.screen.width;
    const screenWidth = window.screen.width * zoomLevel;
    const screenHeight = window.screen.height * zoomLevel;
    const tagsPillPlayer = document.getElementsByClassName('ss-l-tagsPlayer');
    const divChat = document.getElementsByClassName('ss-l-div-chat');
    const swiperProducts = document.getElementsByClassName('ss-l-swiper-products');
    const divProductsDestaque = document.getElementsByClassName('ss-g-postionProductsDestaque');
    const swiperAlerts = document.getElementsByClassName('ss-l-alert-btn');
    const sidebarPromotion = document.getElementsByClassName('ss-g-sidebar-promotions');
    const menuRecording = document.getElementsByClassName('ss-g-menu-inf2');
    const gemPromosBtn = document.getElementsByClassName('ss-l-custom-btn');
    const menuInf = document.getElementsByClassName('ss-g-menu-inf');
    const darkArea = document.querySelector('.videoUploadContainer'); // Use a classe ou id da área escura
    if (darkArea) {
      const height = darkArea.getBoundingClientRect().height;
      console.log('tamanho área escura: ' + height);

    }
    // vertical fullscreen 
    if (this.fullScreen && this.valSelect1Video.value === 1 && !this.isMobile) {
      let marginLeft = 0;
      let marginRight = 0;
      let barLeft = 0;
      const larguraDoVideo = screenHeight * (9 / 16);
      marginLeft = (screenWidth / 2) - (larguraDoVideo / 2);
      if (marginLeft < 0) {
        barLeft = -marginLeft;
      } else {
        barLeft = -marginLeft;
      }
      marginRight = marginLeft;
      this.marginRightAdj = marginRight;
      (videoJsDivs[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;

      // Obtem o tamanho da fonte do elemento raiz (normalmente <html>) em pixels
      const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const oneRemInPx = rootFontSize * 1; // '1' representa o valor de 1rem

      (tagsPillPlayer[0] as HTMLElement).style.setProperty('margin-left', `${marginLeft}px`, 'important');
      (tagsPillPlayer[0] as HTMLElement).style.width = `${screenWidth - 2 * marginLeft - 2 * oneRemInPx}px`;
      this.cdr.detectChanges();
      if (divChat.length > 0) {
        (divChat[0] as HTMLElement).style.setProperty('width', `${screenWidth - 2 * marginLeft - 8 * oneRemInPx}px`, 'important');
        //(divChat[0] as HTMLElement).style.setProperty('top', `${37}rem`, 'important');
      }
      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (swiperProducts.length > 0) {
          (swiperProducts[0] as HTMLElement).style.setProperty('margin-left', `${marginLeft - oneRemInPx}px`, 'important');
          (swiperProducts[0] as HTMLElement).style.setProperty('width', `${screenWidth - 2 * marginLeft - 2 * oneRemInPx}px`, 'important');
          //(swiperProducts[0] as HTMLElement).style.setProperty('bottom', 'calc(78% - 50px)', 'important');
        }
      }
      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        // if (divProductsDestaque.length > 0) {
        //   (divProductsDestaque[0] as HTMLElement).style.setProperty('margin-left', `${marginLeft}px`, 'important');
        // }
      }


      if (this.alertsTabs.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (swiperAlerts.length > 0) {
          (swiperAlerts[0] as HTMLElement).style.setProperty('margin-left', `${marginLeft}px`, 'important');
          (swiperAlerts[0] as HTMLElement).style.setProperty('width', `${screenWidth - 2 * marginLeft - 2 * oneRemInPx}px`, 'important');
        } else {
          console.log('não tem swiper de alertas');
        }
      }

      console.log("p1")
      if (this.targetPromotions.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (sidebarPromotion.length > 0) {
          console.log("p3");
          (sidebarPromotion[0] as HTMLElement).style.setProperty('margin-left', `${marginLeft}px`, 'important');
          //(sidebarPromotion[0] as HTMLElement).style.setProperty('top', , 'important');
          (sidebarPromotion[0] as HTMLElement).style.setProperty('top', 'calc(50% - 85px)', 'important');

          (sidebarPromotion[0] as HTMLElement).style.setProperty('width', `${screenWidth - 2 * marginLeft}px`, 'important');
        } else {
          console.log('não tem promos1');
        }
        if (gemPromosBtn.length > 0) {
          (gemPromosBtn[0] as HTMLElement).style.setProperty('margin-right', `${marginLeft}px`, 'important');
        }
        // gemPromosBtn
      }
      console.log("p4");
      if (!this.typeLive) {
        const customProgressBar = document.getElementsByClassName('ss-l-progress-container');
        (customProgressBar[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;
        (customProgressBar[0] as HTMLElement).style.width = `${screenWidth - 2 * marginLeft}px`;

        (menuRecording[0] as HTMLElement).style.setProperty('margin-left', `${marginLeft + oneRemInPx}px`, 'important');
        (menuRecording[0] as HTMLElement).style.setProperty('width', `${screenWidth - 2 * marginLeft - 4 * oneRemInPx}px`, 'important');
      }

      if (menuInf.length > 0) {
        (menuInf[0] as HTMLElement).style.setProperty('top', 'calc(22% - 35px)', 'important');
      }
    } else if (!this.fullScreen && this.valSelect1Video.value === 1 && !this.isMobile) {
      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (swiperProducts.length > 0) {
          (swiperProducts[0] as HTMLElement).style.setProperty('bottom', '2%', 'important');
        }
      }
      if (menuInf.length > 0) {
        (menuInf[0] as HTMLElement).style.setProperty('top', '84%', 'important');
      }

    } else if (this.fullScreen && this.valSelect1Video.value === 2 && !this.isMobile) {
      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        // if (swiperProducts.length > 0) {
        //   (swiperProducts[0] as HTMLElement).style.setProperty('top', 'calc(85% - 50px)', 'important');
        // }
      }
      if (menuInf.length > 0) {
        console.log('barra inf1');
        (menuInf[0] as HTMLElement).style.setProperty('top', 'calc(95% - 52px)', 'important');
      }
      console.log('barra inf');
    } else if (!this.fullScreen && this.valSelect1Video.value === 2 && !this.isMobile) {
      this.cdr.detectChanges();
      if (this.targetProducts.length > 0 && this.showCTAs) {

        // if (swiperProducts.length > 0) {
        //   (swiperProducts[0] as HTMLElement).style.setProperty('top', 'calc(85% - 50px)', 'important');
        // }
      }
      if (menuInf.length > 0) {
        (menuInf[0] as HTMLElement).style.setProperty('top', '84%', 'important');
      }

    }
    // vertical preview
    if (!this.fullScreen && this.valSelect1Video.value === 1 && !this.isMobile) {
      let marginLeft = 0;
      let barLeft = 0;
      const screenWidth = window.innerWidth;
      (videoJsDivs[0] as HTMLElement).style.marginRight = '0px';
      this.marginRightAdj = 2;
      (videoJsDivs[0] as HTMLElement).style.marginLeft = '0px';


      if (!this.typeLive) {
        const customProgressBar = document.getElementsByClassName('ss-l-progress-container');
        (customProgressBar[0] as HTMLElement).style.marginLeft = `${marginLeft}px`;
        (customProgressBar[0] as HTMLElement).style.width = `${100}%`;
      }



      (tagsPillPlayer[0] as HTMLElement).style.setProperty('margin-left', `${0}px`, 'important');
      (tagsPillPlayer[0] as HTMLElement).style.width = 'auto';


      if (!this.typeLive) {
        (menuRecording[0] as HTMLElement).style.setProperty('margin-left', `${0}px`, 'important');
        (menuRecording[0] as HTMLElement).style.width = 'auto';
      }

      this.cdr.detectChanges();
      if (divChat.length > 0) {
        (divChat[0] as HTMLElement).style.setProperty('width', `${100}%`, 'important');
        //(divChat[0] as HTMLElement).style.setProperty('top', `${25}rem`, 'important');
      }

      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (swiperProducts.length > 0) {
          (swiperProducts[0] as HTMLElement).style.setProperty('margin-left', '', 'important');
          (swiperProducts[0] as HTMLElement).style.width = '';
        }
      }
      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (divProductsDestaque.length > 0) {
          (divProductsDestaque[0] as HTMLElement).style.setProperty('margin-left', '', 'important');
        }
      }

      if (this.alertsTabs.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (swiperAlerts.length > 0) {
          (swiperAlerts[0] as HTMLElement).style.setProperty('margin-left', '', 'important');
          (swiperAlerts[0] as HTMLElement).style.width = '';
        } else {
          console.log('não tem swiper de alertas');
        }
      }


      if (this.targetPromotions.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (sidebarPromotion.length > 0) {
          (sidebarPromotion[0] as HTMLElement).style.setProperty('margin-left', '0rem', 'important');
          (sidebarPromotion[0] as HTMLElement).style.width = '100%';
          (sidebarPromotion[0] as HTMLElement).style.setProperty('top', 'calc(53% - 6px)', 'important');
        } else {
          console.log('não tem promos2');
        }
        if (gemPromosBtn.length > 0) {
          (gemPromosBtn[0] as HTMLElement).style.setProperty('margin-right', '0rem', 'important');
        }
      }
    }
    if (!this.fullScreen && this.valSelect1Video.value === 2 && !this.isMobile) {
      (videoJsDivs[0] as HTMLElement).style.marginRight = '0.1px';
    }
    if (this.isMobile && this.fullScreen && this.valSelect1Video.value === 1) {
      if (this.targetProducts.length > 0 && this.showCTAs) {
        this.cdr.detectChanges();
        if (swiperProducts.length > 0) {
          (swiperProducts[0] as HTMLElement).style.setProperty('margin-left', '', 'important');
          (swiperProducts[0] as HTMLElement).style.width = '';
          (swiperProducts[0] as HTMLElement).style.setProperty('margin-right', '', 'important');
        }
      }
      console.log('entrou no ajuste de alertas isMobile')


      if (this.alertsTabs.length > 0 && this.showCTAs) {
        console.log('entrou no ajuste de alertas isMobile2')
        let marginLeft = 0;
        let marginRight = 0;
        let barLeft = 0;
        const larguraDoVideo = screenHeight * (9 / 16);
        marginLeft = (screenWidth / 2) - (larguraDoVideo / 2);
        if (marginLeft < 0) {
          barLeft = -marginLeft;
        } else {
          barLeft = -marginLeft;
        }
        console.log('entrou no ajuste de alertas isMobile3');
        marginRight = marginLeft;
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const oneRemInPx = rootFontSize * 1; // '1' representa o valor de 1rem
        this.cdr.detectChanges();
        if (swiperAlerts.length > 0) {
          console.log('entrou no ajuste de alertas isMobile4');
          (swiperAlerts[0] as HTMLElement).style.setProperty('margin-left', `${0}px`, 'important');
          (swiperAlerts[0] as HTMLElement).style.setProperty('margin-right', `${0}px`, 'important');
        } else {
          console.log('entrou no ajuste de alertas isMobile5');
          console.log('não tem swiper de alertas');
        }
        console.log('entrou no ajuste de alertas isMobile6');
      }
    }

  }
  // setMaxHeight() {
  //   // Define a altura do container para ser no máximo igual à do vídeo
  //   videoInner.style.maxHeight = videoElement.clientHeight + 'px';
  // }
  theaterMode: any = {};
  hasTheaterMode: boolean = false;
  originalStyle?: any;
  dynamicStyle: any = {};


  isInTheaterMode: boolean = false;
  goTheaterMode() {
    if (!this.isFullScreen) {
      this.goFullscreen();
    }

    if ((this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 2) || (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 1)) {
      this.hasTheaterMode = false;
      this.theaterMode = {};
    } else {
      if (this.isFullScreen) {
        this.hasTheaterMode = true;
        if (this.valSelectviewMode.value == 1) {
          this.theaterMode = { 'width': '100vw' };
        } else if (this.valSelectviewMode.value == 2) {
          this.theaterMode = { 'height': '100vh', 'width': '100vw' };
        } else {
          this.theaterMode = {};
        }
      } else {
        this.hasTheaterMode = false;
        this.theaterMode = {};
      }
    }
    this.isInTheaterMode = true;
  }

  exitTheaterMode() {
    this.theaterMode = {};
    this.isInTheaterMode = false;
  }

  isFullScreen?: boolean = false;

  modoTeatro() {
    if (this.isFullScreen) {
      if (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 2) {
        this.theaterMode = { 'height': 'auto', 'width': '100%' };
      }
      if (this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 1) {
        this.theaterMode = { 'height': '100%', 'width': 'auto' };
      }
      this.isInTheaterMode = true;
    } else {
      this.isFullScreen = true;

      this.columnSize = 'col-12';
      this.toggleLayoutClass();

      if (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 2) {
        this.theaterMode = { 'height': '100%', 'width': 'auto' };
      }
      if (this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 1) {
        this.theaterMode = { 'height': '100%', 'width': 'auto' };
      }

      this.isInTheaterMode = true;
      this.updateDynamicStyle();
    }

  }

  sairModoTeatro() {

    this.isInTheaterMode = false;

    if (this.isFullScreen) {
      if ((this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 2) || (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 1)) {
        this.hasTheaterMode = false;
      } else {
        this.hasTheaterMode = true;
      }
      this.cdr.detectChanges();

      console.log('saindo modo teatro')
      console.log(this.valSelectviewMode.value)
      console.log('saindo modo teatro')


      if (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 2) {
        this.theaterMode = { 'height': '100%', 'width': '100%' };
      }
      if (this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 1) {
        this.theaterMode = { 'height': '100%', 'width': '100%' };
      }
      //this.theaterMode = {};
    }



  }

  goFullscreen() {
    this.isFullScreen = true;
    this.updateDynamicStyle();
    this.columnSize = 'col-12';
    this.toggleLayoutClass();
    if (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 2) {
      this.theaterMode = { 'height': '100%', 'width': '100%' };
    }
    if (this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 1) {
      this.theaterMode = { 'height': '100%', 'width': '100%' };
    }

    console.log(this.theaterMode) // COR ABAIXO
    let stmBar = setTimeout(() => {
      this.updateProgressBarPosition();
      this.updateProgressBarWidth();
      clearTimeout(stmBar);
    }, 100);
  } //
  goMinscreen = () => {
    this.isFullScreen = false;
    this.isInTheaterMode = false;
    if (this.valSelectviewMode.value == 1) {
      this.columnSize = 'col-12 md:col-8 lg:col-8';
    }
    this.toggleLayoutClass();
    this.updateDynamicStyle();
    if ((this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 2) || (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 1)) {
      this.hasTheaterMode = false;
    } else {
      this.hasTheaterMode = true;
    }
    this.cdr.detectChanges();
    this.theaterMode = {};
    let stmBar = setTimeout(() => {
      this.updateProgressBarPosition();
      this.updateProgressBarWidth();
      clearTimeout(stmBar);
    }, 100);
  }

  aplicarEstiloFullscreen(): void {
    const videoTechElement = this.el.nativeElement.querySelector('.vjs-tech');
    const videoPreview = this.el.nativeElement.querySelector('.video-preview');
    if (this.fullScreen) {
      this.renderer.setStyle(videoTechElement, 'min-height', '100vh');
      this.renderer.setStyle(videoTechElement, 'width', 'auto');
      this.renderer.setStyle(videoTechElement, 'aspect-ratio', '9/16');
      this.renderer.setStyle(videoPreview, 'min-height', '100vh');
      this.renderer.setStyle(videoPreview, 'max-height', '100vh');
    } else {
      this.renderer.removeStyle(videoTechElement, 'min-height');
      this.renderer.removeStyle(videoTechElement, 'width');
      this.renderer.removeStyle(videoPreview, 'min-height');
      this.renderer.removeStyle(videoPreview, 'max-height');
      this.renderer.removeStyle(videoTechElement, 'aspect-ratio');
    }
  }




  private subscription?: Subscription;
  private subscription2?: Subscription;
  private subscription3?: Subscription;
  private subscriptionWhatsapp?: Subscription;
  private subscriptionCustomp?: Subscription;
  private subscriptionAlerts?: Subscription;
  highlightedProductsLimited$?: Observable<Produto[]>;



  closeSidebar(event: Event) {
    event.stopPropagation(); // Previne a propagação do evento
    this.showAllShortucutLinks = false; // Altera a visibilidade
  }
  private cleanupPlayerEventListeners(player: any) {
    //player.off('play');
    player.off('timeupdate');
    player.off('waiting');
    player.off('playing');
    player.off('error');
    player.off('ended');
    player.off('seeking');
    player.off('seeked');
    player.off('tracking:firstplay');
    player.off('tracking:seek');
    player.off('tracking:pause');
    player.off('tracking:buffered');
    player.off('tracking:performance');
  }
  showSwiper: boolean = false;
  numberOfAlerts: number = 0;
  toggleSwiperVisibility(): void {
    this.showSwiper = !this.showSwiper;
    this.subscription = this.filteredAlerts$!.subscribe(alerts => {
      this.numberOfAlerts = alerts.length;
    });
  }

  filteredAlerts$?: Observable<AlertsTab[]>;
  showAlertsSwiper(): void {
    this.showSwiper = true;
  }
  ngOnDestroy() {

    this.cleanupPlayerEventListeners(this.player);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener('resize', this.updateProgressBarWidth);
    this.resizeObserverBreakPoints?.disconnect();
    this.subscriptionsChat.unsubscribe();
    this.liveStatusSubscription?.unsubscribe();
    this.websocketService.disconnect();
    if (this.stream) {
      this.stopMediaTracks(this.stream);
    }
    this.subscriptionsGeneric.unsubscribe();
    this.colorSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    window.removeEventListener('beforeunload', this.boundBeforeUnloadHandler);
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    this.clearHideTimer();
    this.errorSubscription?.unsubscribe();
    if (!this.pauseSlick) {
      this.pause(); // Chama o método pause ao destruir o componente
    }
    this.subscription?.unsubscribe();
    this.pickListObserverService.shouldEmit = false;
    this.subscription2?.unsubscribe();
    this.subscriptionAlerts?.unsubscribe();
    this.pararTimeout();
    if (this.player) {
      this.player.dispose();
    }
    this.observer?.disconnect();
    this.dragEventsSubscription?.unsubscribe();
    this.fakeBarSubscription?.unsubscribe();
    clearInterval(this.tertInterval);
    clearInterval(this.recordingTimerInterval);
    clearInterval(this.analyticsInterval);
    clearInterval(this.timer);
    clearInterval(this.countdownInterval);
    clearTimeout(this.playPauseTimeout);
    if (this.stream) {
      this.stopMediaTracks(this.stream);
    }
    clearTimeout(this.playerTimeout);
    clearTimeout(this.debounceTimer);
    clearTimeout(this.volumeControlTimeout);
    clearTimeout(this.debounceTimerCTALinks);
    clearTimeout(this.timeOutReconnect);
    clearTimeout(this.timeoutRef);
    clearTimeout(this.timeOutCloseSideBar);
    clearTimeout(this.timeoutId);
    clearTimeout(this.timeOutDrag);
    this.cameraChangeProcessedSubscription?.unsubscribe();
    this.pauseSlick = false;
    this.pause();
    this.destroy$.next();
    this.destroy$.complete();

    const progressBar = this.progressContainerWrapper?.nativeElement;

    if (progressBar) {
      progressBar.removeEventListener('touchmove', this.touchMoveListener);
      progressBar.removeEventListener('touchend', this.touchEndListener);
      progressBar.removeEventListener('touchcancel', this.touchEndListener);
      progressBar.removeEventListener('mousemove', this.mouseMoveListener);
      progressBar.removeEventListener('mouseleave', this.mouseLeaveListener);
    }
  }
  logoStore: string = '';
  getStoreData() {

    const domainStore = localStorage.getItem('domain_store');
    this.liveShopService.getStorneInfos(domainStore ? domainStore : "").subscribe({
      next: (res) => {
        this.logoStore = res.store_logo;
      },
      error: (err: any) => {
        console.error('Failed to set meta tags', err);
      }
    });
  }
  @ViewChild('messageContainer') messageContainer?: ElementRef;
  private boundBeforeUnloadHandler = this.handleBeforeUnload.bind(this);
  changeCta() {
    console.log(this.ctaSelected)
  }
  changeCtaLink() {
    console.log('Aba alterada para: ', this.activeItemLinks);
  }

  onDialogCloseMain() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  updateProgressBar(): void {
    if (!this.player || !this.progressContainer) {
      return;
    }

    const rect = this.progressContainer.nativeElement.getBoundingClientRect();
    const currentTime = this.player.currentTime();
    const duration = this.player.duration();

    const normalSeekPercentage = (currentTime / duration) * 100;
    const normalClickX = (rect.width * normalSeekPercentage) / 100;

    let adjustedClickX = normalClickX; // Posição padrão para o marcador
    let progressPercentage;
    if (this.fakeBar) {
      if (normalSeekPercentage < 30) {
        progressPercentage = (normalSeekPercentage / 30) * 70;
        adjustedClickX = (rect.width * progressPercentage) / 100; // Ajusta a posição do marcador
      } else {
        progressPercentage = 70 + ((normalSeekPercentage - 30) / 70) * 30;
        adjustedClickX = (rect.width * progressPercentage) / 100; // Ajusta a posição do marcador
      }
      if (this.parallelProgress) {
        this.parallelProgress.nativeElement.style.width = `${progressPercentage}%`;
      } else {
        console.log("Parallel progress element not available.");
      }
    } else {
      progressPercentage = normalSeekPercentage;  // Usa a porcentagem de progresso direta
      if (this.originalProgress) {
        this.originalProgress.nativeElement.style.width = `${progressPercentage}%`;
      } else {
        console.log("Original progress element not available.");
      }
    }

    // Atualiza a posição do marcador com o valor ajustado
    if (this.marker) {
      this.marker.nativeElement.style.left = `${adjustedClickX}px`;
    } else {
      console.log("Marker element not available.");
    }
  }



  private fakeBarSubscription?: Subscription;
  timeoutId: any;
  iniciarTimeout(): void {
    this.timeoutId = setTimeout(() => {
      if (!this.player && this.videoElementRef) {
        if (typeof videojs !== 'undefined') {
          this.player = videojs(this.videoElementRef.nativeElement, {
            controls: false
          });

          this.player.ready(() => {
            if (this.hasData) {
              // this.disableRecorder = false;
            }

          })
        }
      }
    }, 1);
  }

  pararTimeout(): void {
    clearTimeout(this.timeoutId);
  }
  iconColor: string = '#FFFFFF';


  qrCode?: string;
  qrCodeLoad() {
    console.log("gerando QRCODE");
    this.liveShopService.generatePix().pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.gerarQRCode(response.qrCode);
      },
      error => {

      }
    );
  }
  gerarQRCode(texto: string) {
    this.url_share = texto;
    QRCode.toDataURL(texto)
      .then(url => {
        this.qrCodeUrl = url;
      })
      .catch(err => {
        console.error('Error generating QR Code', err);
      });
  }
  activeItem?: MenuItem;
  activeItemLinks?: MenuItem;
  activeOption: number | null = 2;
  activeLinkOption: any;

  selecionarOpcao(opcao: number): void {
    this.activeOption = opcao;
    this.setupDragAndDropObserver();
    this.cdr.detectChanges();
  }


  images!: any[];
  filter() {
    let filtered: User[] = [];
    for (let i = 0; i < this.users.length; i++) {
      let user = this.users[i];
      if (user.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) == 0) {
        filtered.push(user)
      }
    }

    this.filteredUsers = [...filtered];
  }
  users: User[] = [];
  filteredUsers: User[] = [];
  searchValue: string = '';
  marginRightAdj: any;
  marginLeftAdj: any;







  speedDialTooltipText: string = '';
  speedDialTooltipTarget: any = null;

  onSpeedDialItemHover(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.closest('.p-speeddial-action')) {
      // Usando o operador de coalescência nula para garantir que um valor string seja atribuído
      this.speedDialTooltipText = target.getAttribute('data-label') ?? '';
      this.speedDialTooltipTarget = target;
    }
  }


  onSpeedDialItemOut() {
    this.speedDialTooltipText = '';
    this.speedDialTooltipTarget = null;
  }

  showItemName(name: string) {
    // Lógica para mostrar o nome do item, se necessário
  }

  addItem() {
    // Lógica para adicionar item
  }

  updateItem() {
    // Lógica para atualizar item
  }
  landscapeOptions?: MenuItem[] = [];
  landscapeOptions2: any[] = [];
  landscapeOptions3: any[] = [];
  ctaOptions: any[] = [];
  linksOptions: any[] = [];
  valSelect1Video?: any;
  valSelectviewMode?: any;
  ctaSelected?: any;
  valSelectOrientation?: any;
  selectedSku: number = 0;
  showAllProductsSidebar2:boolean= false;
  openProductSidebar(sku: number): void {
    this.selectedSku = sku;
    this.showAllProductsSidebar2 = true;
    this.showAllProductsSidebar = false;

    this.sidebarService.setSku(sku);

    // 🔥 Carrega dinamicamente o ShowProductsComponent
    this.dynamicComponentContainer.clear();
    const componentRef = this.dynamicComponentContainer.createComponent(ShowProductsComponent);

    // ✅ Passa o SKU para o componente dinâmico
    componentRef.instance.sku = sku;
    this.cdr.detectChanges();
  }
  changeLandscape() {
    if (this.valSelect1Video) {
      console.log("Seleção alterada para valor:", this.valSelect1Video.value);
      this.cdr.detectChanges();
      this.marginTopVideo();
      //this.adjustVideoMargin();
      this.playerBorderRadius();
      this.debounceFunction();
      this.cdr.detectChanges();
    }
    if ((this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 2) || (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 1)) {
      this.hasTheaterMode = false; this.cdr.detectChanges();
    } else {
      this.hasTheaterMode = true; this.cdr.detectChanges();
    }
    this.setupResizeObserver();
    this.cdr.detectChanges();
  }
  changeViewMode() {
    console.log('chamou changeViewMode');
    if (this.valSelectviewMode) {
      console.log("Seleção alterada para valor:", this.valSelectviewMode.value);

      if (this.valSelectviewMode.value == 1 && !this.isFullScreen) {
        this.columnSize = 'col-12 md:col-8 lg:col-8';
      } else {
        this.columnSize = 'col-12';
      }
      //this.columnSize = result.matches ? 'col-12' : 'col-12 md:col-6 lg:col-4';
      this.marginTopVideo();

    }

    if ((this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 2) || (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 1)) {
      this.hasTheaterMode = false;
    } else {
      this.hasTheaterMode = true;
    }


    this.handleTruncatedDesc();
    this.setupResizeObserver();
    let stmBar = setTimeout(() => {
      this.updateProgressBarPosition();
      this.updateProgressBarWidth();
      clearTimeout(stmBar);
    }, 100);
  }
  aspect_ratio: string | null = "1";
  exibePlayer: boolean = false;
  isLive: boolean = false;
  hasData: boolean = false;
  disableRecorder: boolean = true;

  private player: any;
  isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  isMobileDevice() {
    const isMobileUA = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isTouchCapable = navigator.maxTouchPoints > 0;
    return isMobileUA || isTouchCapable;
  }
  myButtonDom: any;
  typeSlick?: number;
  sliderMaxValue: number = 0;

  @ViewChild('thumbnailPreview') thumbnailPreview!: ElementRef;
  togglePlayPause() {
    // console.log('togglePlayPause');
    // console.log('Estado isPaused antes de alternar:', this.isPaused);

    // Exibe o estado do player usando video.js
    //console.log('Player video.js está pausado:', this.player.paused());

    // Verifica e exibe o estado do elemento de vídeo diretamente
    if (this.videoElementRef && this.videoElementRef.nativeElement) {
      //console.log('Estado do elemento de vídeo diretamente:', this.videoElementRef.nativeElement.paused);


      this.showPlayPauseButton = true; // Sempre mostra o botão enquanto interage.

      // Alterna o estado de play e pause diretamente usando o elemento de vídeo
      if (this.videoElementRef.nativeElement.paused) {
        this.player.play();
        console.log('O elemento de vídeo está pausado, tentando tocar...');
        this.videoElementRef.nativeElement.play().then(() => {
          this.isPaused = false; // Confirma que não está pausado.
        }).catch((error: any) => {

        });
      } else {
        this.player.pause();
        console.log('O elemento de vídeo está tocando, tentando pausar...');
        this.videoElementRef.nativeElement.pause();
        this.isPaused = true; // Confirma que está pausado.
        // console.log('Elemento de vídeo foi pausado.');
      }
    }
  }

  showPlayPauseButton: boolean = false;
  playPauseTimeout: any;
  // Quando o mouse entra na área do vídeo
  onVideoMouseEnter(): void {
    this.showPlayPauseButton = true;
    // Limpar o timeout anterior se o vídeo estiver tocando

    if (this.playPauseTimeout && !this.isPaused) {
      clearTimeout(this.playPauseTimeout);
    }
  }
  // Quando o mouse sai da área do vídeo
  onVideoMouseLeave(): void {
    if (!this.isPaused) {
      // Iniciar um novo timeout para esconder o botão
      this.playPauseTimeout = setTimeout(() => this.showPlayPauseButton = false, 3000);
    }
  }
  onVideoMouseMove(): void {
    // Mostra o botão quando o mouse se move dentro do vídeo
    this.showPlayPauseButton = true;

    // Reinicia o temporizador para esconder o botão após 3 segundos
    clearTimeout(this.playPauseTimeout);
    if (!this.isPaused) {
      this.playPauseTimeout = setTimeout(() => {
        this.showPlayPauseButton = false;
      }, 3000);
    }

  }
  posterURL?: string;
  combinedTime$!: Observable<{ current: string | null, total: string | null }>;
  removeNgswBypassParam(url: string): string {
    const urlObj = new URL(url);
    urlObj.searchParams.delete('ngsw-bypass');
    return urlObj.toString();
  }
  removeUrlValidator(url: string): string {
    return url.includes('?') ? url.split('?')[0] : url;
  }
  initVideoPlayer(): void {
    if (this.videoElementRef && this.videoElementRef.nativeElement) {
      console.log('achou elemento de vídeo');
      if (typeof videojs !== 'undefined') {
        this.player = videojs(this.videoElementRef.nativeElement, {
          controls: false,
          poster: this.posterURL,
          sources: [{
            src: this.removeNgswBypassParam(this.removeUrlValidator(this.videoUrl)),
            type: 'video/mp4'
          }]
        });
        this.player.ready(() => {
          this.player.playsinline(true);
          this.player.one('canplay', () => {
            this.player.play().then(() => {
              this.isPaused = false;
              console.log("Vídeo começou a tocar.");
            }).catch((error: any) => {
              console.error("Erro ao tentar reproduzir o vídeo: ", error);
            });
          });


          this.subscriptionsGeneric.add(() => this.player.off('timeupdate'));

          this.player.on("play", () => {
            this.ngZone.run(() => {
              this.isPaused = false;
              clearTimeout(this.playPauseTimeout);
              this.playPauseTimeout = setTimeout(() => {
                this.showPlayPauseButton = false;
              }, 3000);
            });
          });

          this.player.on("pause", () => {
            this.ngZone.run(() => {
              this.isPaused = true;
              this.showPlayPauseButton = true;
              clearTimeout(this.playPauseTimeout);
            });
          });

          this.player.on('waiting', () => {
            console.log('Evento: waiting - Buffering or seeking');
          });

          this.player.on('stalled', () => {
            console.log('Evento: stalled - Buffering');
          });

          this.player.on("loadedmetadata", () => {
            this.sliderMaxValue = this.player.duration();

            if (!this.timePitch) {
              this.updateTimeValue();
            }

            this.setCustom(this.customComponentsTmp);
            this.setWhatsAppTabs(this.whatsAppComponentsTmp);
            this.setAlerts(this.alertsAppComponentsTmp);
          });

          // No seu componente Angular, onde o player é inicializado
          this.player.on('timeupdate', () => {
            const currentTime = this.player.currentTime();
            const duration = this.player.duration();
            this.durationTotal = this.formatTime(duration);
            if (this.progressContainer) {
              const containerWidth = this.progressContainer.nativeElement.getBoundingClientRect().width;
              let progressPercentage;

              // Verifica qual barra de progresso está ativa e calcula a porcentagem baseado nela
              if (this.fakeBar) {
                // Cálculo e atualização do progresso paralelo (fake)
                if (currentTime < duration * 0.3) {
                  progressPercentage = (currentTime / (duration * 0.3)) * 70;
                } else {
                  progressPercentage = 70 + ((currentTime - duration * 0.3) / (duration * 0.7)) * 30;
                }
                if (this.parallelProgress && !this.isDragging) { // isDragging
                  this.parallelProgress.nativeElement.style.width = `${progressPercentage}%`;
                }
              } else {
                // Atualização do progresso real (original)
                progressPercentage = (currentTime / duration) * 100;
                if (this.originalProgress && !this.isDragging) {
                  this.originalProgress.nativeElement.style.width = `${progressPercentage}%`;
                }
              }


              if (this.marker && !this.isDragging) {
                const containerWidth = this.progressContainer.nativeElement.getBoundingClientRect().width;
                const markerPosition = (progressPercentage / 100) * containerWidth;
                this.marker.nativeElement.style.left = `${markerPosition}px`;
              }
            }

          });
          this.subscriptionsGeneric.add(() => this.player.off('timeupdate'));

          // Encontra o índice do botão de fullscreen antes de removê-lo
          const fullscreenButton = this.player.controlBar.getChild('FullscreenToggle');
          const index = this.player.controlBar.children().indexOf(fullscreenButton);

          // Remove o botão de toggle de fullscreen da barra de controle
          this.player.controlBar.removeChild(fullscreenButton);

          // Adiciona um botão personalizado na posição do botão de fullscreen
          const myButton = this.player.controlBar.addChild('button', {}, index);
          this.myButtonDom = myButton.el();

          // Define o HTML interno do botão e adiciona um evento de clique
          if (this.fullScreen) {
            this.myButtonDom.innerHTML = "<i class='pi pi-window-minimize active' style='cursor:pointer;'></i>";
          } else {
            this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize active' style='cursor:pointer;'></i>";
          }
          this.myButtonDom.onclick = () => {
            if (this.fullScreen) {
              this.goMinscreen();
            } else {
              this.goFullscreen();
            }
          };

          // Remove o botão PiP (se estiver disponível)
          if (this.player.controlBar.pictureInPictureToggle) {
            this.player.controlBar.removeChild('pictureInPictureToggle');
          }
          // Remove o controle de volume padrão updateProgress
          this.player.controlBar.removeChild('volumePanel');
          //this.marginTopVideo();
          //this.adjustVideoMargin();
          this.playerBorderRadius();
          this.moveControlBar();
          this.updateProgressBarWidth();
          window.addEventListener('resize', () => this.updateProgressBarWidth());
          this.updateDynamicStyle();
          //this.setThumbNail(this.player);
        });
      } else {
        console.log('VideoJS não definido')
      }



    } else {
      console.log('não achou elemento de vídeo');
    }
  }
  setThumbNail(player: any) {
    const progressBar = this.progressContainerWrapper?.nativeElement;
    const thumbnailPreview = this.thumbnailPreview.nativeElement;

    if (progressBar) {
      this.touchMoveListener = (event: TouchEvent) => {
        const touch = event.touches[0];
        const rect = progressBar.getBoundingClientRect();
        const seekPercentage = (touch.clientX - rect.left) / rect.width;
        const duration = player.duration();
        const seekTime = seekPercentage * duration;
        const thumbnailIndex = Math.floor(seekTime);
        const backgroundPosX = thumbnailIndex * -160;

        thumbnailPreview.style.display = 'block';
        thumbnailPreview.style.left = `${touch.clientX - 80}px`;
        thumbnailPreview.style.top = `${-100}px`;
        thumbnailPreview.style.backgroundImage = `url('https://raw.githubusercontent.com/GiriAakula/samuel-miller-task/master/openvideo.png')`;
        thumbnailPreview.style.backgroundPosition = `${backgroundPosX}px 0px`;
        thumbnailPreview.style.backgroundSize = '1600px 450px';
      };

      this.touchEndListener = () => {
        thumbnailPreview.style.display = 'none';
      };

      this.mouseMoveListener = (event: MouseEvent) => {
        const rect = progressBar.getBoundingClientRect();
        const seekPercentage = (event.clientX - rect.left) / rect.width;
        const duration = player.duration();
        const seekTime = seekPercentage * duration;
        const thumbnailIndex = Math.floor(seekTime);
        const backgroundPosX = thumbnailIndex * -160;

        thumbnailPreview.style.display = 'block';
        let thumbnailLeft = event.clientX - rect.left - 80;
        if (thumbnailLeft < 0) {
          thumbnailLeft = 0;
        } else if (thumbnailLeft + 160 > rect.width) {
          thumbnailLeft = rect.width - 160;
        }

        thumbnailPreview.style.left = `${thumbnailLeft}px`;
        thumbnailPreview.style.top = `${-100}px`;
        thumbnailPreview.style.backgroundImage = `url('https://raw.githubusercontent.com/GiriAakula/samuel-miller-task/master/openvideo.png')`;
        thumbnailPreview.style.backgroundPosition = `${backgroundPosX}px 0px`;
        thumbnailPreview.style.backgroundSize = '1600px 450px';
      };

      this.mouseLeaveListener = () => {
        thumbnailPreview.style.display = 'none';
      };

      progressBar.addEventListener('touchmove', this.touchMoveListener);
      progressBar.addEventListener('touchend', this.touchEndListener);
      progressBar.addEventListener('touchcancel', this.touchEndListener);
      progressBar.addEventListener('mousemove', this.mouseMoveListener);
      progressBar.addEventListener('mouseleave', this.mouseLeaveListener);
    }
  }

  private touchMoveListener: any;
  private touchEndListener: any;
  private mouseMoveListener: any;
  private mouseLeaveListener: any;
  private transitionEndListener: any;
  createCustomMarkers(): void {
    const markers = [
      { time: 10, text: 'Time to pitch' } // Você pode ter uma array de objetos de marcadores
    ];

    const duration = this.player.duration();
    if (this.progressContainer) {
      const containerWidth = this.progressContainer.nativeElement.getBoundingClientRect().width;

      // Remova todos os marcadores existentes antes de adicionar novos
      const progressContainerElement = this.progressContainer.nativeElement;
      const markersContainer = document.querySelector('#my-markers-container');
      if (markersContainer) {
        markersContainer.innerHTML = '';

        // Crie e adicione os marcadores
        markers.forEach(marker => {
          const markerElement = document.createElement('div');
          markerElement.classList.add('custom-marker');
          markerElement.title = marker.text;

          // Calcule a posição do marcador
          const position = (marker.time / duration) * progressContainerElement.offsetWidth;
          markerElement.style.left = `${position}px`;

          markersContainer.appendChild(markerElement);
        });
      }

    }

  }
  timeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  moveControlBar() {
    const controlBar = this.player?.controlBar?.el();
    if (controlBar && this.controlsContainer?.nativeElement) {
      this.controlsContainer.nativeElement.appendChild(controlBar);
    }
  }
  playerBorderRadius() {
    const videoTechElement = this.el.nativeElement.querySelector('.vjs-tech');
    if (this.valSelect1Video.value === 1 && videoTechElement) {
      if (this.fullScreen) {
        console.log('border 1')
        this.renderer.setStyle(videoTechElement, 'border-radius', '0px');
      } else {
        console.log('border 2');
        if (this.isMobileDevice()) {
          console.log('border 3');
          this.renderer.setStyle(videoTechElement, 'border-radius', '10px 10px 10px 10px');
        }

      }

    } else {
      console.log('border 5');
      if (videoTechElement) {
        console.log('border 6');
        this.renderer.setStyle(videoTechElement, 'border-radius', '0px');
      }
      if (this.isMobileDevice()) {
        console.log('border 3');
        this.renderer.setStyle(videoTechElement, 'border-radius', '10px 10px 10px 10px');
      }
    }
  }
  detectDevice(): string {
    const ua = navigator.userAgent;

    // Regras simples baseadas no User-Agent para detectar dispositivos móveis
    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua.toLowerCase())) {
      // Detecção adicional para diferenciar entre celular e tablet pode ser necessária
      if (window.innerWidth < 800) { // Supondo uma largura de tela como heurística
        return 'mobile';
      } else {
        return 'tablet';
      }
    } else {
      return 'desktop';
    }
  }
  isMobileIOS(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /iPad|iPhone|iPod/.test(userAgent);
  }

  isIpad(userAgent: string): boolean {
    const ipadRegex = /iPad/i;
    return ipadRegex.test(userAgent);
  }
  @ViewChild('videoContainer') videoContainerRef?: ElementRef;
  marginTopVideo() {
    // const videoJsDivs = document.getElementsByClassName('video-js');
    // const videoContainer = document.getElementsByClassName('videoUploadContainer');
    // //const btnsPlaySeek = document.getElementsByClassName('ss-l-rpf-bar');
    // const videoTechElement = this.el.nativeElement.querySelector('.vjs-tech');
    // if (this.videoInnerRef) {   // 10px 10px 0px 0px
    //   if (this.videoInnerRef.nativeElement.classList.contains('ss-l-video-container-inner-landscape')) {
    //     let elementHeight = this.videoInnerRef.nativeElement.getBoundingClientRect().height;
    //     console.log("Altura do player no modo paisagem:", elementHeight);



    //     //this.renderer.setStyle(videoTechElement, 'border-radius', '0');
    //   } else {
    //     this.videoInnerRef.nativeElement.style.top = '';
    //     //this.renderer.setStyle(videoTechElement, 'border-radius', '10px 10px 10px 10px');
    //   }
    // }


    // let marginTop = 0;

    // if (videoJsDivs.length > 0 && this.videoContainerRef) {
    //   if (this.fullScreen && this.valSelect1Video.value == 2) {
    //     //const windowHeight = window.innerHeight;
    //     let videoHeight = (videoJsDivs[0] as HTMLElement).offsetHeight;
    //     let videoWidth = (videoJsDivs[0] as HTMLElement).offsetWidth;
    //     let videoContainerHeight = this.videoContainerRef.nativeElement.offsetHeight;
    //     const userAgent = navigator.userAgent;

    //     //////////////////////
    //     const windowHeight = window.innerHeight;
    //     const windowWidth = window.innerWidth;
    //     let videoAspectRatio = videoWidth / videoHeight;

    //     const windowAspectRatio = windowWidth / windowHeight;

    //     //let videoHeight, videoWidth, marginTop;

    //     // Se a proporção da janela for maior que a proporção do vídeo, ajustar pela altura
    //     if (windowAspectRatio > videoAspectRatio) {
    //       videoHeight = windowHeight; // Faz o vídeo preencher a altura
    //       videoWidth = videoHeight * videoAspectRatio; // Ajusta a largura para manter a proporção do vídeo
    //       marginTop = 0; // Sem necessidade de ajuste vertical, pois o vídeo preenche a altura
    //     } else {
    //       // Ajusta pela largura
    //       videoWidth = windowWidth; // Faz o vídeo preencher a largura
    //       videoHeight = videoWidth / videoAspectRatio; // Ajusta a altura para manter a proporção
    //       marginTop = (windowHeight - videoHeight) / 2; // Calcula o marginTop para centralizar verticalmente
    //     }
    //     /////////////////////
    //     (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
    //     if (this.isMobileIOS()) {
    //       console.log('ios')

    //       if (this.fullScreen) {
    //         marginTop = (windowHeight - videoHeight) / 2;
    //       } else {
    //         console.log(this.isIpad(userAgent));
    //         if (!this.isIpad) {
    //           marginTop = (windowHeight - videoHeight) / 2;
    //         }


    //       }
    //       console.log(marginTop);
    //       if (marginTop >= 0) {
    //         if (this.isIpad(userAgent)) {
    //           (videoJsDivs[0] as HTMLElement).style.top = '60%';
    //         } else {
    //           (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
    //         }

    //       } else {
    //         //(videoJsDivs[0] as HTMLElement).style.top = '100%';
    //       }



    //     } else if (this.detectDevice() == 'mobile') {
    //       console.log('mobile')
    //       if (this.fullScreen) {
    //         marginTop = (windowHeight - videoHeight) / 2;
    //       } else {
    //         marginTop = (videoContainerHeight - videoHeight) / 2;
    //       }

    //       if (marginTop >= 0) {
    //         (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
    //       } else {
    //         //(videoJsDivs[0] as HTMLElement).style.top = '100%';
    //       }
    //     } else if (this.detectDevice() == 'tablet') {
    //       console.log('tablet')
    //       if (this.fullScreen) {
    //         marginTop = (windowHeight - videoHeight) / 2;
    //       } else {
    //         marginTop = (videoContainerHeight - videoHeight) / 2;
    //       }
    //       if (marginTop >= 0) {
    //         (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
    //       } else {
    //         //(videoJsDivs[0] as HTMLElement).style.top = '100%';
    //       }
    //     } else if (this.detectDevice() == 'desktop') {
    //       console.log('desktop')
    //       marginTop = 0;
    //       //console.log(window.innerWidth);
    //       if (this.fullScreen) {
    //         if (window.innerWidth >= 821) {
    //           console.log('desktop');
    //           (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
    //         } else {
    //           console.log('tablet');
    //           marginTop = (windowHeight - videoHeight) / 2;
    //           (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}px`;
    //         }
    //       } else {
    //         //(videoJsDivs[0] as HTMLElement).style.top = '100%';
    //       }

    //     }
    //   } else if (this.valSelect1Video.value == 2) {
    //     marginTop = 100;
    //     //(videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}%`;

    //     (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}%`;
    //     // if (!this.typeLive) {
    //     //   (btnsPlaySeek[0] as HTMLElement).style.top = `${marginTop + 50}%`;
    //     // }


    //   } else if (this.valSelect1Video.value == 1) {
    //     marginTop = 0;
    //     //(videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}%`;
    //     if (marginTop < 0) {
    //       marginTop = 0;
    //     }
    //     (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}%`;
    //     // if (!this.typeLive) {
    //     //   (btnsPlaySeek[0] as HTMLElement).style.top = `${marginTop + 50}%`;
    //     // }
    //   } else if (this.valSelect1Video.value == 2 && this.fullScreen && this.isMobile) {
    //     marginTop = 100;
    //     (videoJsDivs[0] as HTMLElement).style.top = `${marginTop}%`;

    //     if (this.videoUploadContainerInside) {
    //       let elementHeight = this.videoUploadContainerInside.nativeElement.getBoundingClientRect().height;
    //       // let offsetTop = `calc(50% - ${elementHeight / 2}px)`;
    //       // this.videoUploadContainerInside.nativeElement.style.top = offsetTop;

    //     }
    //   }


    // }
  }


  marginTopVideo2() {
    // const videoJsDivs = document.getElementsByClassName('video-js');
    // if (videoJsDivs.length > 0) {
    //   console.log('ajustando video');
    //   if (this.fullScreen && this.valSelect1Video.value == 2) {
    //     // Calcula a altura da janela e do vídeo
    //     const windowHeight = window.innerHeight;
    //     const videoHeight = (videoJsDivs[0] as HTMLElement).offsetHeight;

    //     // Verifica se o vídeo está em formato retrato (9/16)
    //     const videoWidth = (videoJsDivs[0] as HTMLElement).offsetWidth;
    //     const isPortrait = (videoHeight / videoWidth) >= (1.76);
    //     console.log(videoHeight);
    //     console.log(videoWidth);
    //     const aspectRatio = 1.76;
    //     const windowWidth = window.innerWidth;
    //     const expectedVideoHeight = windowWidth * aspectRatio;
    //     console.log('ajustando top')
    //     let marginTop = 0;

    //     if (this.isMobileDevice()) {
    //       marginTop = (windowHeight - videoHeight) / 2;
    //     }

    //     //if(this.isLandscape()){
    //     console.log(videoHeight);
    //     console.log(videoWidth);




    //     if (videoHeight / videoWidth == 108 / 192) {
    //       if (videoHeight == 171) {
    //         (videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}px`;
    //       } else {
    //         (videoJsDivs[0] as HTMLElement).style.marginTop = '10%';
    //       }
    //       console.log('108/192');

    //     } else if (videoHeight / videoWidth == 194 / 346) {
    //       console.log('194/346');
    //       (videoJsDivs[0] as HTMLElement).style.justifyContent = 'center';
    //     } else if (this.isLandscape()) {
    //       console.log('land');
    //       (videoJsDivs[0] as HTMLElement).style.marginTop = '0%';
    //     } else if (this.isMobile) {
    //       console.log('mob');
    //       if (navigator.maxTouchPoints > 0) {
    //         (videoJsDivs[0] as HTMLElement).style.marginTop = '40%';
    //       } else {
    //         (videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}px`;
    //       }

    //     } else if (navigator.maxTouchPoints > 0) {
    //       console.log('touch');
    //       (videoJsDivs[0] as HTMLElement).style.marginTop = '40%';
    //     } else {
    //       console.log('desk');
    //       (videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}px`;
    //     }
    //     if (this.isLandscape()) {
    //       console.log('land');
    //       (videoJsDivs[0] as HTMLElement).style.marginTop = '0%';
    //     } else if (this.detectDevice() == 'mobile') {
    //       (videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}px`;
    //     } else if (this.detectDevice() == 'tablet') {
    //       (videoJsDivs[0] as HTMLElement).style.marginTop = '40%';
    //     } else {

    //     }
    //     // if (isPortrait) { ismo
    //     //   console.log("portrait")
    //     //   // Para vídeos retrato, ajusta a margem apenas se a altura do vídeo for menor que a da janela
    //     //   if (expectedVideoHeight < windowHeight) {
    //     //     console.log("portrait2")
    //     //     marginTop = (windowHeight - expectedVideoHeight) / 2;
    //     //   }
    //     // } else {
    //     //   console.log("portrait3")
    //     //   // Para vídeos não retrato, usa o cálculo normal 412
    //     //   // marginTop = (windowHeight - videoHeight) / 2;
    //     //   // marginTop = marginTop-50;
    //     // }
    //     // console.log("portrait4");        // Aplica a margem superior calculada
    //     // //(videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}px`;
    //     // (videoJsDivs[0] as HTMLElement).style.marginTop = '';
    //   } else if (this.valSelect1Video.value == 2) {
    //     const marginTop = '60';
    //     (videoJsDivs[0] as HTMLElement).style.marginTop = `${marginTop}%`;

    //   } else {
    //     (videoJsDivs[0] as HTMLElement).style.marginTop = '';
    //   }

    // } else {
    //   console.log('não ajustando video');
    //   (videoJsDivs[0] as HTMLElement).style.marginTop = '';
    // }
  }
  isLandscape() {
    // Verifica a orientação usando a propriedade screen.orientation.type, se disponível
    if (screen.orientation && screen.orientation.type) {
      return screen.orientation.type.startsWith('landscape');
    } else {
      // Fallback usando a relação entre a largura e altura da janela
      return window.innerWidth > window.innerHeight;
    }
  }
  private applySwiperStyles2(containerWidth: number): void {
    const swiperSlides = this.gridContainer?.nativeElement.querySelectorAll('.mySwiperThird .swiper-slide') as NodeListOf<HTMLElement>;
    if (!swiperSlides) return;

    swiperSlides.forEach(swiperSlide => {
      if (containerWidth < 600) {
        swiperSlide.style.width = '75%';
      } else if (containerWidth < 960) {
        swiperSlide.style.width = '50%';
      } else if (containerWidth < 1280) {
        swiperSlide.style.width = '30%';
      } else {
        swiperSlide.style.width = '25%';
      }
    });
  }
  showAllProductsSidebar: boolean = false;
  selectedSlideIndex: number = 0;
  // Abre a Sidebar com os produtos do slide específico

  private applySwiperStyles3(containerWidth: number): void {
    const swiperSlides = this.gridContainer?.nativeElement.querySelectorAll('.mySwiperSecond .swiper-slide') as NodeListOf<HTMLElement>;
    if (!swiperSlides) return;

    swiperSlides.forEach(swiperSlide => {
      if (containerWidth < 600) {
        swiperSlide.style.width = '75%';
      } else if (containerWidth < 960) {
        swiperSlide.style.width = '50%';
      } else if (containerWidth < 1280) {
        swiperSlide.style.width = '30%';
      } else {
        swiperSlide.style.width = '25%';
      }
    });
  }
  private applySwiperStyles(containerWidth: number): void {
    const swiperSlides = this.gridContainer?.nativeElement.querySelectorAll('.mySwiper .swiper-slide') as NodeListOf<HTMLElement>;
    if (!swiperSlides) return;

    swiperSlides.forEach(swiperSlide => {
      if (containerWidth < 600) {
        swiperSlide.style.width = '75%';
      } else if (containerWidth < 960) {
        swiperSlide.style.width = '50%';
      } else if (containerWidth < 1280) {
        swiperSlide.style.width = '30%';
      } else {
        swiperSlide.style.width = '25%';
      }
    });
  }
  private cleanupResizeObserver() {
    if (this.resizeObserver) {
      console.log("disconnect");
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
  }
  setupResizeObserver() {

    this.cleanupResizeObserver();

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.adjustGridColumn(entry.contentRect.width);
      }
    });

    if (this.gridContainer) {
      this.resizeObserver.observe(this.gridContainer.nativeElement);
    }
  }
  top1: number = 9;
  top2: number = 8; // alertas
  center: number = 9.5;
  bottom0: number = 5; // description
  bottom1: number = 6; // custom
  bottom2: number = 15; // produtos
  bottom3: number = 35; //swiper links
  ml: number = 20;
  mc: number = 65;
  mr: number = 15;
  adjustBottom3: number = 0;
  adjustGridColumn(width: number) {
    let columns: string;
    this.ml = 20;
    this.mc = 65;
    this.mr = 15;
    if (width < 350) {
      this.center = 9.5; // original
      this.bottom3 = 47.5; // original
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`; // Example for XSmall screens
    } else if (width >= 350 && width < 400) {
      /////////////////////
      this.center = 12.5;
      this.bottom3 = 44.5;
      this.ml = this.ml - 5;
      this.mc = this.mc + 5;
      //this.mr = this.mr - 5;
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`;
    } else if (width >= 400 && width < 600) {
      this.center = 12.5;
      this.bottom3 = 44.5;
      /////////////////////
      this.mc = this.mc - 5;
      this.mr = this.mr + 5;
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`; // Example for Small screens
    } else if (width >= 600 && width < 960) {
      this.center = 12.5; // original
      this.bottom3 = 44.5;
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`;
    } else if (width >= 960 && width < 1280) {
      this.center = 13.5; // original
      this.bottom3 = 43.5; // original
      this.ml = this.ml - 13;
      this.mc = this.mc + 21;
      this.mr = this.mr - 8;
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`;
    } else if (width >= 1280 && width < 1920) {
      this.center = 13.5; // original
      this.bottom3 = 43.5;
      this.ml = this.ml - 15;
      this.mc = this.mc + 25;
      this.mr = this.mr - 10;
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`;
    } else {
      this.center = 13.5; // original
      this.bottom3 = 43.5;
      this.ml = this.ml - 15;
      this.mc = this.mc + 25;
      this.mr = this.mr - 10;
      columns = `${this.ml}% ${this.mc}% ${this.mr}%`;
    }
    this.adjustBottom3 = (this.ml + this.mc);
    if (this.gridContainer) {
      this.gridContainer.nativeElement.style.gridTemplateColumns = columns;
    }
    this.applySwiperStyles2(width);
    this.applySwiperStyles(width);
    this.applySwiperStyles3(width);

    this.sum100();
  }
  handleDivClick(event: Event): void {
    event.stopPropagation(); // Isso sempre será chamado.
    if (this.fullViewActive) {
      this.goVerMaisFechar();
    }
    // Aqui, você não faz nada se fullViewActive for false.
  }

  ngAfterViewInit() {

    this.resizeObserverBreakPoints = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        this.applySwiperStyles(width);
        this.applySwiperStyles2(width);
        this.applySwiperStyles3(width);
      }
    });

    if (this.gridContainer) {
      this.resizeObserverBreakPoints.observe(this.gridContainer.nativeElement);
    }
    setTimeout(() => {
      const closeButton = document.querySelector('.p-sidebar-close');
      if (closeButton) {
        closeButton.addEventListener('click', (event: Event) => { // Mudando MouseEvent para Event
          console.log('Preventing sidebar close button from propagating');
          event.stopPropagation();
        }, true); // Use `true` to run this in the capture phase
      }
    }, 500);
    //this.cdr.detectChanges();
    this.setupResizeObserver();
    this.updateProgressBarPosition();
  }
  progressBarTop: string = '0px';
  updateProgressBarPosition(): void {
    if (this.gridContainer) {
      const gridHeight = this.gridContainer.nativeElement.offsetHeight;
      this.progressBarTop = `${gridHeight - 6}px`; // Posição dinâmica para a barra

      document.documentElement.style.setProperty('--progress-bar-top-time', `${gridHeight - 150}px`);
      //this.cdr.detectChanges();
    }
  }
  updateDynamicStyle() {
    const heightInPixels = window.innerHeight;
    this.dynamicStyle = this.isFullScreen ? { 'min-height': `${heightInPixels}px`, 'width': '100vw', 'border-radius': '0px' } : {};
    this.updateProgressBarPosition();
  }
  private observer?: MutationObserver;
  timeOutDrag: any;

  setupDragAndDropObserver() {
    this.timeOutDrag = setTimeout(() => {
      this.initializeObserver();
    }, 500);
  }

  initializeObserver() {
    // Desconectar o observador anterior, se existir
    if (this.observer) {
      this.observer.disconnect();
    }

    console.log('chamou multi');
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains('p-picklist-item') && target.style.zIndex !== '1500' ||
            target.classList.contains('p-orderlist-item') && target.style.zIndex !== '1500') {
            target.style.zIndex = '1500'; // Definindo um novo z-index
          }
        }
      });
    });

    this.observer.observe(document.body, { attributes: true, subtree: true, attributeFilter: ['style'] });
  }


  isDragDrop: boolean = false;
  dragDrop(x: number, event?: Event) {
    if (event) {
      event.stopPropagation(); // Previne que o clique se propague e desative o drag and drop
    }

    if (x === 0) {
      this.isDragDrop = true;
    } else {
      this.isDragDrop = false;
    }
    console.log('DragDrop status:', this.isDragDrop);
  }
  onReorder(event: any) {
    this.isDragDrop = false;
    console.log('Reordered, DragDrop status:', this.isDragDrop);
    this.debounceFunction();
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
  }
  onTouchStart(event: Event) {
    this.isDragDrop = true;
    console.log('Touch start, DragDrop status:', this.isDragDrop);
  }

  onTouchMove(event: Event) {
    this.isDragDrop = true;
    if (this.isDragDrop) {
      event.preventDefault(); // Previne o comportamento padrão de scroll
    }
  }

  onTouchEnd(event: Event) {
    this.isDragDrop = false;
    console.log('Touch ended, DragDrop status:', this.isDragDrop);
  }

  ////////////////////////////////////////////////

  sidebarChat?: boolean = true; // Inicialmente, mostrar o sidebar hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
  // this.openSreenChat();
  screenChat: boolean = false;
  @ViewChild('chatBoxComponent') chatBoxComponent?: ElementRef;
  openSreenChat() {
    this.screenChat = this.screenChat == true ? false : true;
    if (this.chatBoxComponent) {
      this.chatBoxComponent.nativeElement.loadMessages();
    }
    //this.adjustVideoMargin();
  }
  toggleSidebar() {
    this.displaySidebarBottom = !this.displaySidebarBottom;
    // if (this.displaySidebarBottom) {
    //   document.body.classList.add('no-scroll');
    // } else {
    //   document.body.classList.remove('no-scroll');
    // }
    this.setupDragAndDropObserver();
  }
  carouselResponsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  products!: Produto[];


  screenPromos?: boolean = false;
  openScreenPromos() {
    this.screenPromos = this.screenPromos == true ? false : true;
  }
  screenSaibaMais?: boolean = true;
  openSreenSaibaMais() {
    this.screenSaibaMais = this.screenSaibaMais == true ? false : true;
  }
  screenWhatsBtn?: boolean = false;
  screenWhats() {
    this.screenWhatsBtn = this.screenWhatsBtn == true ? false : true;
  }
  screenAlertsBtn?: boolean = true;
  screenAlerts() {
    this.screenAlertsBtn = this.screenAlertsBtn == true ? false : true;
  }
  isTransparent: boolean = false;
  handleTransparency() { // updateLinksColors
    if (this.isTransparent) {
      this.selectedLinkColor = 'transparent'; // Define a cor como transparente
      this.updateLinksColors('transparent');
    } else {
      this.selectedLinkColor = '#FFFFFF'; // Define uma cor padrão ao desativar transparência
      this.updateLinksColors(this.selectedLinkColor);
    }
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
    //updateLinksPositions
  }
  pageStudioNow: string = '';
  openCTA() { // openSidebar
    this.mananger = 1;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Elementos Interativos';
    //this.loadPromotions()
  }
  openConfigs() {
    this.mananger = 2;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Configurações e Estilos';
  }
  openAB() {
    this.mananger = 3;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Teste A/B';
  }
  openAnalytics() {
    this.mananger = 4;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Analytics';
  }
  openPixels() {
    this.mananger = 5;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Pixels';
  }
  openDetails() {
    this.mananger = 6;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Detalhes';

    this.liveShopService.detailSettings().pipe(takeUntil(this.destroy$)).subscribe({
      next: (settings) => {


        const mediaFolderSetting = settings.find(s => s.key === 'media_folder');
        if (mediaFolderSetting) {
          this.media_folder = mediaFolderSetting.value;
        } else {
          console.log('media_folder not found');
        }

      },
      error: (err) => {

      }
    });
  }
  openIntegrations() {
    this.mananger = 7;
    this.pageStudio = 'Studio ';
    this.pageStudioNow = '> Integrações';
  }





  menuInferior: MenuItem[] = [];



  isSharing: boolean = false;
  compartilhar() {
    const domainStore = localStorage.getItem('domain_store');

    // Verificar e obter o subdomínio
    let subdomain = '';
    if (domainStore) {
      const parts = domainStore.split('.');
      if (parts.length >= 2) {
        subdomain = parts[0];
      }
    }

    // Obter o UID da URL atual
    const currentUrl = window.location.href;
    const uidMatch = currentUrl.match(/\/slick\/([^/]+)/);
    const uid = uidMatch ? uidMatch[1] : '';

    if (!uid) {
      console.error('UID não encontrado na URL atual.');
      return;
    }

    let domain = 'slicksell.link';

    // Construir a URL de redirecionamento
    const redirectUrl = `${window.location.protocol}//${subdomain ? subdomain + '.' : ''}${window.location.host}?slick_id=${uid}`;

    ///////////////////////
    if (navigator.share) {
      if (this.isSharing) {
        console.warn('Já existe uma operação de compartilhamento em andamento.');
        return;
      }

      this.isSharing = true;


      let urlShare = domainStore ? `https://slicksell.link/${subdomain}` + '?slick_id=' + uid : ""
      console.log('urlShare');
      console.log(urlShare);
      console.log('urlShare');

      navigator.share({
        title: 'Compartilhar Link',
        url: urlShare
      }).then(() => {
        console.log('Link compartilhado com sucesso!');
      }).catch(err => {
        console.error('Erro ao compartilhar:', err);
      }).finally(() => {
        this.isSharing = false;
      });
    } else {
      console.error('A Web Share API não é suportada neste navegador.');
    }
  }

  displayRTMPInofos: boolean = false;
  rtmpInfos = () => {
    console.log('rtmpInfos');
    this.displayRTMPInofos = true;
  }

  recarregarSaldo() {
    this.openSidebarSaldo();
    // Ação para 'Recarregar Saldo'
  }
  mananger: number = 0;
  pageStudio: string = 'Studio';



  //////////////////////// player recorders //////////////////////////
  stream?: MediaStream | null = null;
  currentCameraId: string | null = null;
  autoplay: boolean = false;
  dialogVisible: boolean = false;
  errorMessage: string = '';
  showError(message: string): void {
    this.errorMessage = message;
    this.dialogVisible = true;
  }
  dialogVisibleError: boolean = false;
  showErrorDisconnect(message: string): void {
    this.errorMessage = message;
    this.dialogVisibleError = true;
  }

  closeDialog() {
    this.dialogVisible = false;
  }
  closeDialogError() {
    this.dialogVisibleError = false;
  }
  showCountdown: boolean = true;
  countdownMessage: string = '';
  countdownStyle = {
    'font-size': '0vw',
    opacity: '0%'
  };
  timer: any;  // Adicione isso na classe para manter referência ao timer
  counter: number = 3;
  startCountdown = (x: number) => {
    let counter = 3;
    if (x >= 1) {
      counter = 0;
    }
    if (x < 0) {
      x = 3;
    }


    console.log('counter: ' + counter);
    return new Promise((resolve, reject) => {  // Inclua reject para poder rejeitar a promessa em caso de erro
      this.showCountdown = true;
      this.timer = setInterval(() => {
        this.countdownMessage = counter === 0 ? '' : counter.toString();
        this.countdownStyle = {
          'font-size': '40vw',
          opacity: '100%',
        };

        setTimeout(() => {
          this.countdownStyle.opacity = '0%';
        }, 900);

        counter--;

        if (counter < 0) {
          this.showCountdown = false;
          this.firstSuccessfulSend = true;
          clearInterval(this.timer);
          resolve(true);
          //if (x == 0) {
          this.startRecordingTimeCounter();
          //}
        }
      }, 1000);
    });
  };
  cancelCountdown = () => {
    clearInterval(this.timer); // Limpa o intervalo do timer
    this.showCountdown = false;
    this.countdownMessage = '';
  };
  lastData: Blob | null = null;
  heartbeatInterval: any = null;


  fallbackVideo: boolean = false;
  pauseSlick: boolean = false;
  videoChunks: Blob[] = [];
  isPaused: boolean = true;
  firstSuccessfulSend = false;
  private recorder?: typeof RecordRTC;
  private fallbackRecorder?: typeof RecordRTC;
  sendMonitorTimeout: any = null;



  // Função para iniciar ou resetar o temporizador de monitoramento
  async stopAndResetRecording() {
    this.resetRecordingTimer(); // Reseta o timer e zera a contagem
  }

  resetRecordingTimer() {
    clearInterval(this.recordingTimerInterval);
    this.recordingTimerInterval = undefined;
    this.recordingTimeSeconds = 0; // Zera o contador de tempo de gravação
    this.elapsedTimeBeforePause = 0; // Zera o tempo guardado antes de uma pausa
  }
  recordingTimeSeconds: number = 0;
  recordingInterval: any;
  recordingTimerInterval?: any;

  countdownSeconds: number = 300;
  countdownInterval?: any;
  startCountdownPause() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.countdownInterval = setInterval(() => {
      if (this.countdownSeconds > 0) {
        this.countdownSeconds--;
      } else {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }
  resetCountdownPause() {
    clearInterval(this.countdownInterval);
    this.countdownSeconds = 300;
  }


  elapsedTimeBeforePause = 0;
  startRecordingTimeCounter() {
    if (this.recordingTimerInterval !== undefined) return;

    // Recuperar o tempo decorrido do localStorage
    const savedTime = localStorage.getItem('elapsedTimeBeforePause');
    this.elapsedTimeBeforePause = savedTime ? parseInt(savedTime, 10) : 0;

    console.log('Reiniciando o contador de tempo com elapsedTimeBeforePause:', this.elapsedTimeBeforePause);
    const startTime = Date.now() - (this.elapsedTimeBeforePause * 1000);
    this.recordingTimerInterval = setInterval(() => {
      if (this.cliclkStop) {
        this.stopRecordingTimer();
      } else {
        const currentTime = Date.now();
        this.recordingTimeSeconds = Math.floor((currentTime - startTime) / 1000);

        console.log('Tempo atualizado:', this.recordingTimeSeconds);
      }

    }, 1000);
  }
  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  }
  stopRecordingTimer() {
    if (this.recordingTimerInterval !== undefined) {
      clearInterval(this.recordingTimerInterval);
      this.recordingTimerInterval = undefined;
    }
    this.recordingTimeSeconds = 0; // Garante que o contador é zerado para nova gravação
    this.elapsedTimeBeforePause = 0; // Reseta o tempo decorrido antes da pausa

    // Limpar o valor salvo no localStorage
    localStorage.removeItem('elapsedTimeBeforePause');

  }
  stopRecordingTimerPause() {
    if (this.recordingTimerInterval !== undefined) {
      this.elapsedTimeBeforePause = this.recordingTimeSeconds;
      clearInterval(this.recordingTimerInterval);
      this.recordingTimerInterval = undefined;
      console.log('Timer pausado em:', this.elapsedTimeBeforePause);
      localStorage.setItem('elapsedTimeBeforePause', this.elapsedTimeBeforePause.toString());
    }
  }

  closeDialogConfirmation() {
    this.showConfirmDialog = false;
  }

  stopTransmission() {
    this.showConfirmDialog = true;
  }

  hasErrorLive: boolean = false;

  async pause() {
    if (this.pauseSlick) {
      // Retomar a transmissão ao vivo
      this.liveStatusService.setState('online');
      this.websocketService.sendMessage({ action: 'resume' });
      console.log('chamando start');
      await this.startRecording(2); // Reinicia a gravação sem perder o estado anterior
      this.startRecordingTimeCounter(); // Reinicia a contagem do timer de onde parou
      this.pauseSlick = false;
      this.resetCountdownPause();
    } else {
      // Pausar a transmissão ao vivo
      this.liveStatusService.setState('idle');
      if (this.recorder) {
        await this.recorder.stopRecording();
        this.websocketService.sendMessage({ action: 'pause' });
        // Para o timer de gravação e salva o tempo decorrido
        this.stopRecordingTimerPause();
        this.pauseSlick = true;
        this.startCountdownPause();
      }


    }
  }
  cliclkStop: boolean = false;
  async startRecording(x: number): Promise<boolean> {
    if (x == 0) {
      this.reconnectAttempts = 0;
    }

    if (!this.encontrouCam) {
      this.handleSocketError('list cam', '');
      return false;
    }

    this.showCountdown = true;
    this.firstSuccessfulSend = false;
    this.cliclkStop = false;
    this.hasErrorLive = false;
    this.initNewStream = false;
    //getUserMedia recording
    try {
      await this.connection();
    } catch (error) {
      let msg = 'Não foi possível estabelecer a conexão.';
      this.showError(msg);
      return false;
    }

    try {
      let constNumber = 9 / 16;
      if (this.valSelect1Video.value == 2) {
        constNumber = 16 / 9;
      }
      if (!this.isMobile) {
        console.log('desktop');
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            deviceId: { exact: this.currentCameraId ? this.currentCameraId : undefined },
            aspectRatio: { ideal: constNumber }
          },
        });
      } else {
        console.log('mobile');
        let constNumberWidth = 1920;
        let constNumberHeight = 1080;
        if (this.valSelect1Video.value == 2) {
          constNumberWidth = 1080;
          constNumberHeight = 1920;
        }
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            deviceId: { ideal: this.currentCameraId ? this.currentCameraId : undefined },
            width: { ideal: constNumberWidth },
            height: { ideal: constNumberHeight }
          },
        });
      }
      console.log('gravando')
      this.startCountdown(x);
      if (this.videoElementRef) {
        this.videoElementRef.nativeElement.srcObject = this.stream;
        if (!this.player) {
          if (typeof videojs !== 'undefined') {
            this.player = videojs(this.videoElementRef.nativeElement, {
              controls: false
            });
          }
        }

        this.player.ready(() => {
          const fullscreenButton = this.player.controlBar.getChild('FullscreenToggle');
          const index = this.player.controlBar.children().indexOf(fullscreenButton);
          this.player.controlBar.removeChild(fullscreenButton);

          const myButton = this.player.controlBar.addChild('button', {}, index);
          this.myButtonDom = myButton.el();
          this.myButtonDom.innerHTML = "<i class='pi pi-window-maximize active' style='cursor:pointer;'></i>";
          this.myButtonDom.onclick = () => {
            if (this.fullScreen) {
              this.goMinscreen();
            } else {
              this.goFullscreen();
            }
          };

          // Remove o botão PiP (se estiver disponível)
          if (this.player.controlBar.pictureInPictureToggle) {
            this.player.controlBar.removeChild('pictureInPictureToggle');
          }
          // Remove o controle de volume padrão
          this.player.controlBar.removeChild('volumePanel');



        });
      } else {
        let msg = 'Não foi possível encontrar player de gravação.';
        this.showError(msg);
        return false;
      }

      if (!this.micShow) {
        if (this.stream) {
          // Acessa todas as faixas de áudio da stream
          this.stream.getAudioTracks().forEach(track => {
            // Alterna o estado de 'enabled' da faixa
            track.enabled = !track.enabled;
          });
        }
      }
      this.websocketService.startStream();
      this.recorder = new RecordRTC(this.stream, {
        type: 'video',
        mimeType: 'video/webm',
        bitsPerSecond: 3000000,
        timeSlice: 1000, // Invoca o callback a cada 1000ms (1 segundo) Tempo de gravação
        ondataavailable: (blob: Blob) => {

          console.log('pauseSlick: ' + this.pauseSlick)
          console.log('cliclkStop: ' + this.cliclkStop)
          console.log('recording: ' + this.recording)
          console.log('hasErrorLive: ' + this.hasErrorLive)
          if (this.hasErrorLive) {
            this.recorder.stopRecording();
          }
          if (this.cliclkStop) {
            this.stopRecordingTimer();
          }
          if (!this.pauseSlick && !this.cliclkStop && !this.hasErrorLive && !this.initNewStream) {
            this.websocketService.sendStreamData(blob).then((enviadoComSucesso: boolean) => {
              if (enviadoComSucesso) {

                this.recording = true;
                if (!this.firstSuccessfulSend) {
                  this.firstSuccessfulSend = true; // Marca o primeiro envio bem-sucedido

                }
                if (!this.cliclkStop) {
                  this.liveStatusService.setState('online');
                }
                this.displayChangeCameraDialog = false;
                // if(this.initNewStream){

                // }

              }
            }).catch((error: Error) => {
              //console.error('Erro ao enviar dados:', error);
              this.liveStatusService.setState('offline');
            });
          }
        }
      });

      this.recorder.startRecording();


      this.autoplay = true;

      // Inicialize a gravação
      return true;

    } catch (error) {
      console.error("Erro ao acessar a mídia:", error);
      let msg = 'Não foi possível iniciar a gravação devido a um erro na conexão com a mídia.';
      this.errorCam = true;

      return false;
    }
  }

  async switchCamera(camera: MediaDeviceInfo) {
    if (this.recording) {
      //if (this.recorder) {
      this.recorder.stopRecording();
      this.recorder.destroy();
      this.recorder = null;
      //}
      this.stopRecordingTimerPause();
      if (this.stream) {
        await this.stream.getTracks().forEach(track => track.stop());
      }
      // Se já estiver gravando, prepare-se para trocar de câmera e iniciar nova transmissão
      this.initNewStream = true;
      this.changeCameraMessage = 'Trocando a câmera...';
      this.displayChangeCameraDialog = true;
      await this.stopMediaTracks(this.stream); // Encerra o stream atual
      this.currentCameraId = camera.deviceId;
      await this.updateCameraPreview(camera);
      //this.displayCameraSidebar = false;

      // Aguarde um momento antes de iniciar a nova gravação
      setTimeout(async () => {
        await this.startRecording(1);
        this.displayCameraSidebar = false;
      }, 2000);
    } else {
      // Se não estiver gravando, simplesmente atualize o preview da câmera
      await this.updateCameraPreview(camera);
      this.displayCameraSidebar = false;
    }
  }

  async updateCameraPreview(camera: MediaDeviceInfo) {
    this.initNewStream = true;
    if (this.stream) {
      await this.stream.getTracks().forEach(track => track.stop());
    }
    try {
      this.currentCameraId = camera.deviceId;
      let newStream;
      ///////////////////
      let constNumber = 9 / 16;
      if (this.valSelect1Video.value == 2) {
        constNumber = 16 / 9;
      }
      if (!this.isMobile) {
        console.log('desktop');
        newStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            deviceId: { exact: this.currentCameraId },
            aspectRatio: { ideal: constNumber }
          },
        });
      } else {
        console.log('mobile');
        let constNumberWidth = 1920;
        let constNumberHeight = 1080;
        if (this.valSelect1Video.value == 2) {
          constNumberWidth = 1080;
          constNumberHeight = 1920;
        }
        newStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            deviceId: { exact: this.currentCameraId },
            width: { ideal: constNumberWidth },
            height: { ideal: constNumberHeight }
          },
        });
      }
      ///////////////////
      this.stream = newStream;
      if (this.videoElementRef) {
        this.videoElementRef.nativeElement.srcObject = newStream;
      }
      this.currentCameraId = camera.deviceId;
      // Salva o novo stream
    } catch (error) {
      console.error("Erro ao atualizar preview da câmera:", error);
    }
  }


  displayChangeCameraDialog: boolean = false;
  changeCameraMessage: string = '';
  initNewStream: boolean = false;
  async listCameras() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.cameras = devices.filter(device => device.kind === 'videoinput');
    } catch (error) {
      console.error("Erro ao listar câmeras:", error);
    }
  }
  async openCameraSelector() {
    try {
      if (this.recording) {
        this.listCameras();
      } else {

        if (this.cameras.length > 0) {
          this.listCameras();
        } else {
          // Solicitar acesso à câmera para garantir que as permissões sejam concedidas
          const stream = navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          //stream.getTracks().forEach(track => track.stop()); // Encerra o stream, pois o objetivo é apenas solicitar permissão

          // Após a permissão ser concedida, listar as câmeras
          await this.listCameras();
        }

      }

      this.displayCameraSidebar = true; // Exibe a barra lateral para seleção de câmera
    } catch (error) {
      console.error("Erro ao acessar a câmera:", error);
      // Tratar o erro, como informar ao usuário que a permissão é necessária
    }
  }

  async listCamerasAndSetFirstAsCurrent(): Promise<void> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputs = devices.filter(device => device.kind === 'videoinput');

      if (videoInputs.length > 0) {
        this.currentCameraId = videoInputs[0].deviceId; // Define a primeira câmera como a atual.
      } else {
        console.log('Nenhuma câmera encontrada.');
        this.currentCameraId = null;
      }

      // Se você quiser manter uma lista de todas as câmeras, você pode atribuí-las a uma variável de classe
      this.cameras = videoInputs;
    } catch (error) {
      console.error('Erro ao listar câmeras:', error);
    }
  }





  newCameraId: string | null = null;

  tert: number = 0;

  getLiveTert() {
    if (!this.uuid) {
      console.error('UUID é null ou undefined.');
      // Você pode optar por lançar um erro ou simplesmente retornar para evitar chamar o serviço.
      return;
    }
    this.liveShopService.getLiveTert(this.uuid).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        this.tert = response.tert;
      },
      error => {

      }
    );
  }

  isChangeCamButtonDisabled: boolean = false;
  encontrouCam: boolean = true;
  displayCameraSidebar: boolean = false;
  findCam(n: boolean): boolean {
    if (n) {
      return true;
    }
    this.encontrouCam = false;
    return false;
  }



  cameras: MediaDeviceInfo[] = [];
  showCounter: boolean = true;

  async getStream() {
    console.log("sw1 getStream");
    try {
      let constNumber = 9 / 16;
      if (this.valSelect1Video.value == 2) {
        constNumber = 16 / 9;
      }
      let videoConstraints = {};

      if (window.innerWidth > 768) {
        videoConstraints = {
          deviceId: { exact: this.currentCameraId }, // Use exact aqui
          aspectRatio: { ideal: constNumber }
        };
      } else {
        let constNumberWidth = 1920;
        let constNumberHeight = 1080;
        if (this.valSelect1Video.value == 2) {
          constNumberWidth = 1080;
          constNumberHeight = 1920;
        }
        videoConstraints = {
          ...(this.currentCameraId ? { deviceId: { ideal: this.currentCameraId } } : {}),
          width: { ideal: constNumberWidth },
          height: { ideal: constNumberHeight }
        };
      }

      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: videoConstraints,
      });

      if (this.videoElementRef) {
        this.videoElementRef.nativeElement.srcObject = this.stream;
      }
    } catch (error) {
      console.error("Erro ao acessar a câmera:", error);
    }
  }

  recording: boolean = false;
  stopClicked: boolean = false;
  async stopMediaTracks(stream?: MediaStream | null) {
    this.disableRecorder = false;
    this.pauseSlick = false;
    this.recording = false;
    this.stopClicked = true;
    this.cliclkStop = true;



    if (!this.initNewStream) {
      this.stopRecordingTimer();
      this.stopAndResetRecording();
      // this.startRecordingTimeCounter();
    }

    if (stream) { // Checa se stream não é null ou undefined
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
      // enviar hook
      if (!this.pauseSlick) {
        this.liveShopService.sendHook(this.id).pipe(takeUntil(this.destroy$)).subscribe({
          next: (resp) => {
            console.log(resp)
            //
          },
          error: (err) => {

          }
        });
      }

    }
    if (this.stream) {
      this.stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
    this.initNewStream = false;
    this.websocketService.sendMessage({ action: 'stop' });
    this.liveStatusService.setState('offline');
    this.resetRecordingTimer()
    this.stopRecordingTimer();
  }
  showConfirmDialog: boolean = false;

  async stopMediaTracksMessage(stream?: MediaStream | null) {
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
  }
  baseWalletData: any;
  saldoDisponivel: string = '0.00';
  stream_status?: string;
  uniq_id?: string;

  dados: any;
  id: any;
  swalShown: boolean = false;
  // Antes de tudo, certifique-se de declarar errorSubscription no seu componente

  async connection() {
    console.log('chamou con')
    const myStreamKey = this.dados.stream_key;
    this.websocketService.connect(myStreamKey, this.id);

    console.log('myStreamKey');
    console.log(myStreamKey);
    //return;

    this.websocketService.setRTMPDestination(myStreamKey);
    this.websocketService.setVideoCodec('libx264'); // Exemplo de codec
    this.websocketService.setTypeSlick(this.valSelect1Video.value); // Exemplo de codec

    const errorObservable = new Observable<SocketErrorEvent>((subscriber) => {
      const errorEvents = ['fatal', 'connect_error', 'disconnect', 'ffmpeg_stderr', 'exit', 'message'];
      errorEvents.forEach((event) => {
        this.websocketService.socket.on(event, (error: any) => {
          subscriber.next({ event, error });
          this.liveStatusService.handleSocketError(event, error);
        });
      });

      return () => {
        errorEvents.forEach((event) => {
          this.websocketService.socket.off(event);
        });
      };
    });

    // Subscrição correta ao Observable
    this.errorSubscription = errorObservable.pipe(takeUntil(this.destroy$)).subscribe(
      data => this.handleSocketError(data.event, data.error),
      err => console.error(err),
      () => console.log('Observable completado')
    );
    // this.websocketService.socket.on('connect', () => {
    //   this.liveStatusService.handleSocketConnect();
    // });


  }

  disconectSocket() {
    this.websocketService.disconnect();
    this.recording = false;
    clearInterval(this.timer);
    this.dialogVisibleError = false;
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  errorCam: boolean = false;
  private errorSubscription?: Subscription;
  handleSocketError(event: string, error: any) {
    this.hasErrorLive = true;
    this.liveStatusService.setState('offline');

    console.log('erro do tipo: ' + event);

    this.recording = false;
    if (event === 'message') {
      if (error === 'Transmissão interrompida com sucesso.') {
        console.log(error); // Loga a mensagem
        this.liveStatusService.setState('offline'); // Atualiza o estado para 'offline'
      }
      return;
    }

    if (event === 'fatal' || event === 'exit' || event === 'ffmpeg_stderr' || event === 'disconnect' || event === 'error' || event === 'connect_error') {
      console.log('entrou no monitor de erros')

      //this.websocketService.handleError({ event: event, error });
      //this.monitorSocketErrors();
      clearInterval(this.timer);
      if (error.event !== 'disconnect') {
        if (this.reconnectAttempts === 0) {
          this.promptReconnect();
        } else if (this.reconnectAttempts === 3) {
          this.disconnectSocket();
        }
      } else {
        if (this.stream) {
          this.stopMediaTracks(this.stream);
        }
      }
      this.stopRecordingTimer();
      // //this.stopTransmission();
      // this.stopMediaTracks(this.stream);
      // if (this.stream) {
      //   this.stream.getTracks().forEach(track => track.stop());
      //   this.stream = null;
      // }
      // this.swalShown = true;
      // let msg = '';
      // this.recording = false;
      // if (event === 'fatal' && error === 'ffmpeg exit!') {
      //   msg = 'Erro no Socket. Tente novamente';
      // } else if (event === 'exit') {
      //   msg = 'Erro de pipe.';
      // } else if (event === 'connect_error') {
      //   msg = 'Um erro desconhecido ocorreu. Entre em contato ou tente novamente mais tarde.';
      //   this.showErrorDisconnect(msg);
      //   return;
      // } else if (event === 'ffmpeg_stderr') {
      //   return;
      // } else {
      //   msg = 'Um erro desconhecido ocorreu. Entre em contato ou tente novamente mais tarde.';
      // }
      // this.showError(msg);
    } else {

      console.log('evento: ' + event);

      let msg = 'Câmera não encontrada..';
      this.showError(msg);
    }
  }

  allProducts?: Item[];
  paid?: string;
  liveStreamId?: string = '0';
  like_reaction?: number = 0;
  tituloPaginaInicio?: string;
  currentTime: string = '0';

  timeM: number = 0;
  streamKey?: string;
  domainStoreValue?: string | null;
  link?: string;
  currentList?: Item[] = undefined;
  currentListCupons: Coupon[] = [];
  currentListAlerts: AnimatedText[] = [];
  tertResponse?: number;
  numViewers: number = 0;
  sessions: number = 0;
  shopping_carts?: number = 0;
  tmp?: string = '0';
  async getLiveAndUpdagte(): Promise<void> {
    try {
      //this.numViewers = await this.liveShopService.getLiveViewers(this.id).toPromise();
      const response: LiveShop = await this.liveShopService.getLive(this.id).toPromise();
      const responseData: LiveShop = await this.liveShopService.getLiveData(this.id).toPromise();
      if (this.typeLive) {
        const response2: LiveShop = await this.liveShopService.getLiveDetails(this.id).toPromise();
        if (this.liveStreamId !== undefined) {
          this.currentTime = (response2 as any)[this.liveStreamId];
        }
      }

      this.allProducts = response.items;
      this.paid = response.pay_off_amount;
      this.liveStreamId = response.liveStreamId;
      this.like_reaction = response.like_reaction;
      this.stream_status = response.stream_status;
      this.tituloPaginaInicio = response.name;


      this.tmp = response.watchedDuration;


      // analytics
      if (response.shopping_carts) {
        this.shopping_carts = response.total_users;
      }
      if (response.sessions) {
        this.sessions = response.sessions;
      }
      // analytics

      if (this.tmp !== undefined) {
        this.timeM = Math.floor((+this.tmp * 100) / (+this.currentTime / 60));
      } else {
        this.timeM = 0; // Ou algum outro valor padrão apropriado
      }

      this.timeM = isNaN(this.timeM) ? 0 : this.timeM;


      if (response.stream_key == null) {
        this.streamKey = 'https://app.krabo.io/live-redirect?streamkey=123';
      } else {
        //this.streamKey = 'https://stage.krabo.io/live-redirect?streamkey=' + response.stream_key;
        this.streamKey = 'https://app.krabo.io/live-redirect?streamkey=' + response.stream_key;
      }

      this.dados = new ClockData();
      this.dados.id = this.id;
      this.dados.stream_key = responseData.stream_key;
      this.disableRecorder = false;

      this.listCamerasAndSetFirstAsCurrent();
      ////////////
    } catch (error) {
      console.log('Erro ao buscar dados:', error);
    }
  }
  isNumeric(value: any): boolean {
    return !isNaN(value);
  }
  formatTime2(minutes: number): string {
    if (minutes == null || isNaN(minutes)) {
      return '00:00:00';
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    const seconds = Math.floor((minutes * 60) % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }









  ///////////////////////////////
  recordedBlobs: BlobPart[] = [];

  private intervalId: number | null = null;
  pararEnvioImagem() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  obterVideoComoBlob(): Blob {
    return new Blob(this.recordedBlobs, { type: 'video/webm' });
  }

  // Função para salvar ou manipular o Blob após a gravação
  manipularBlob() {
    const blob = this.obterVideoComoBlob();
    const url = URL.createObjectURL(blob);
    // Aqui você pode, por exemplo, salvar o Blob em um servidor ou reproduzi-lo em um elemento <video>
  }
  sourcePromotions: Promotion[] = [];
  targetPromotions: Promotion[] = [];
  targetAlerts: AlertsTab[] = [];
  sourceProducts: Produto[] = [];
  targetProducts: Produto[] = [];
  //normalizeProductData
  loadProducts(): void {
    this.productsService.listProducts().pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => {
        this.sourceProducts = resp.products.map((product: ProdutoShopify) => this.transformProdutoShopify(product));

        if (this.uuid) {
          this.loadSlickDetails(this.uuid);
        }
      },
      error: (err) => console.error('Erro ao carregar produtos:', err),
    });
  }
  private transformProdutoShopify(product: ProdutoShopify): Produto {
    return {
      id: product.id, // Extrai o ID e converte para número
      sku: product.id,
      name: product.title,
      status: 1, // Default status
      inputQty: product.inventory_quantity, // Quantidade inicial
      position: 0, // Posição inicial
      price: product.price,
      onsale_price: product.compare_at_price || '',
      price_exchange: '', // Não especificado
      thumbnail: product.thumbnail,
      isSelected: false,
      show_item: true, // Default para exibir
      highlight: false, // Default para não destacar
      define_interval: false,
      show_slider: false,
      interval_time: { start: '', end: '' },
    };
  }
  loadSlickDetails(uuid: string): void { // stream_key this.player typeLive description
    this.showMenuInf = false;
    this.liveShopService.detailAsset(uuid).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => {
        this.videoReadyToLoad = true;
        const mediaType = (resp.items[0].media.type == null && resp.items[0].media.src == null) ? "live" : ((resp.items[0].media.src != null) ? "shorts" : resp.items[0].media.type);
        if (mediaType === "live") {
          this.typeLive = true;
        } else {
          this.typeLive = false;
        }
        if (this.typeLive) {
          this.getLiveAndUpdagte();
        } else {
          this.getLiveAndUpdagte();
        }
        //this.videoUrl = resp.items[0].media.src.split('?')[0];
        this.videoUrl = resp.items[0].media.src;
        //this.posterURL = resp.items[0].media.thumbnail ?? '';

        let tagsFromDb: string;
        if (resp.items[0].extra.tags) {
          tagsFromDb = resp.items[0].extra.tags;
          this.selectedTags = tagsFromDb.split(','); // Converte a string em um array
          this.tagsString = tagsFromDb;
        } else {
          this.selectedTags = []; // Assegura que selectedTags está limpo se não houver tags
          this.tagsString = '';
        }

        console.log('selectedTags', this.selectedTags);

        /////////////////////////////
        const selectedProductIds = new Set(resp.items[0].content.cta.components.products.items.map(p => p.product_id));

        this.targetProducts = resp.items[0].content.cta.components.products.items
          .map((productFromService: any) => this.normalizeProductData(productFromService as ProdutoShopify));

        this.sourceProducts = this.sourceProducts.filter(sourceProduct =>
          !this.targetProducts.some(targetProduct => String(targetProduct.sku) === String(sourceProduct.sku))
        );



        this.prevTargetProducts = [...this.targetProducts];

        const productColorsUpdate: Record<number, { comprarFontColor: string; comprarBgColor: string; adicionarFontColor: string; adicionarBgColor: string; }> = {};
        this.targetProducts.forEach(product => {
          productColorsUpdate[product.id] = {
            comprarFontColor: product.comprar_font_color ?? '#FFFFFF',
            comprarBgColor: product.comprar_bg_color ?? '#000000',
            adicionarFontColor: product.adicionar_font_color ?? '#000000',
            adicionarBgColor: product.adicionar_bg_color ?? '#FFFFFF'
          };
        });
        this.stateManagementService.productColors.next(productColorsUpdate);
        this.products = this.targetProducts;

        this.setupFilteredProducts();
        if (this.filteredProducts$) {
          this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
            map(products =>
              products.filter(product => product.highlight).slice(0, 3)
            )
          );
        }
        this.targetProducts = [...this.targetProducts];
        this.setupFilteredProducts();

        this.linksSelecteds = resp.items[0].content.cta.components.links;

        // Atualizar sourceProducts para remover os produtos que já foram selecionados
        this.sourceProducts = this.sourceProducts.filter(product => !selectedProductIds.has(product.id));

        /////////////////////////////

        // promos
        // Filtrar targetProducts baseado nos IDs dos produtos selecionados
        this.targetPromotions = resp.items[0].content.cta.components.promotions.items
          .map(productFromService => this.normalizePromosData(productFromService));
        console.log(this.targetPromotions);
        // Primeiro, cria um conjunto dos IDs dos produtos que estão em targetPromotions para facilitar a comparação
        const idsInTargetPromotions = new Set(this.targetPromotions.map(p => p.id));

        // Depois, filtra sourcePromotions para remover os itens que estão em targetPromotions
        this.sourcePromotions = this.sourcePromotions.filter(product => !idsInTargetPromotions.has(product.id));
        this.setupFilteredPromotions();
        /////////////////////////////


        // configurações e estilos
        //this.fakeBar = resp.items[0].extra.player.fake_bar;
        this._fakeBar.next(resp.items[0].extra.player.fake_bar);
        this.smartAutoPlay = resp.items[0].extra.player.auto_play;



        this.timeToPitch = resp.items[0].extra.player.time_to_pitch;
        this.timePitch = resp.items[0].extra.player.time_to_pitch_value;
        this.sliderValuePitch = this.convertTimeToSeconds(this.timePitch);
        this.updateTimeValue();



        this.aspect_ratio = resp.items[0].media.aspect_ratio;

        if (resp.items[0].media.thumbnail) {
          this.imageSelected = true;
          this.imagePreviewUrl = resp.items[0].media.thumbnail.split('?')[0];
        } else {
          this.imageSelected = false;
        }
        // media.type
        this.asset_id = resp.items[0].content.id;
        if (this.aspect_ratio == "1") {
          this.valSelect1Video = this.landscapeOptions2[0];
        } else {
          this.valSelect1Video = this.landscapeOptions2[1];
        }

        console.log('================================');
        console.log(this.valSelectviewMode.value);
        console.log(this.valSelect1Video.value);
        if ((this.valSelectviewMode.value == 1 && this.valSelect1Video.value == 2) || (this.valSelectviewMode.value == 2 && this.valSelect1Video.value == 1)) {
          this.hasTheaterMode = false;
        } else {
          this.hasTheaterMode = true;
        }
        //this.valSelect1Video.value = resp.items[0].media.aspect_ratio;
        //videoUrl

        this.videoPreviewUrl = this.videoUrl;
        if (resp.items[0].media.src) {
          this.isLive = false;
        } else {
          this.isLive = true;
        }
        this.shouMuteImg = true;
        this.mediaType = resp.items[0].media.type;
        this.mediaSrc = resp.items[0].media.src;
        this.mediaDuration = resp.items[0].media.duration;
        this.mediaThumbnail = resp.items[0].media.thumbnail;
        this.publicarAgora = resp.items[0].media.status == 1 ? true : false;
        if (!this.publicarAgora) {
          this.dataSelecionada = new Date(resp.items[0].schedule.date_from);
        }

        this.mediaAspectRatio = resp.items[0].media.aspect_ratio;
        this.mediaStatus = resp.items[0].media.status;
        this.slickTitle = resp.items[0].content.title;
        this.slickDesc = resp.items[0].content.description;

        this.handleTruncatedDesc();
        let elementHeight = this.bottomDiv.nativeElement.scrollHeight;
        this.dynamicHeight = `${elementHeight}px`;


        this.stateManagementService.updateTypeLive(this.typeLive);
        // Tenta encontrar o objeto em slickTypes que tem o valor correspondente
        const foundType = this.slickTypes.find(type => type.value === mediaType);

        // Se nenhum tipo correspondente for encontrado, atribui um valor padrão que corresponda ao tipo SlickType
        this.selectedSlickType = foundType ?? { label: 'Default Label', value: '' };

        this.contentTitle = resp.items[0].content.title;
        this.scheduleDateFrom = resp.items[0].schedule.date_from;
        this.scheduleDateTo = resp.items[0].schedule.date_to;
        this.time_to_pitch = resp.items[0].media.time_to_pitch;




        // fazer o mesmo para accordionTabs
        const customComponents = resp.items[0].content.cta.components.custom;
        this.customComponentsTmp = customComponents;
        if (!this.typeLive) {
          this.setCustom(this.customComponentsTmp);
        }

        // Mapeie os componentes custom para o formato AccordionTab

        const anyDefineIntervalTrue = this.accordionTabs.some(tab => tab.define_interval);
        if (anyDefineIntervalTrue) {
          this.accordionTabs.forEach(tab => tab.define_interval = true);
        } else {
          this.accordionTabs.forEach(tab => tab.define_interval = false);
          if (this.accordionTabs.length > 0) {

            this.isAddButtonDisabled = true;
          }
        }
        if (this.accordionTabs.length == 1 && this.accordionTabs[0].define_interval == false) {
          this.isAddButtonDisabled = false;
        }
        if (this.accordionTabs.length > 0) {
          const initialTab = this.accordionTabs[0]; // Ou alguma lógica para selecionar a aba correta
          this.stateManagementService.updateAccordionColors(
            initialTab.font_color_custom ?? '#FFFFFF', // Valor padrão se font_color_custom for undefined
            initialTab.bg_color_custom ?? '#000000' // Valor padrão se bg_color_custom for undefined
          );
        }


        this.playPauseColor = resp.items[0].extra.player.player_color != "" ? resp.items[0].extra.player.player_color : '#ff0000';
        this.progressBarColor = resp.items[0].extra.player.progress_bar_color != "" ? resp.items[0].extra.player.progress_bar_color : '#ff0000';
        this.updatePlayPauseColor(resp.items[0].extra.player.player_color);


        this.updateProgressBarColor(resp.items[0].extra.player.progress_bar_color);



        this.progressBarColor = resp.items[0].extra.player.progress_bar_color;
        //updatePlayerControlsColors
        this.stateManagementService.updatePlayerControlsColors(
          this.playPauseColor,
          this.progressBarColor
        );


        this.setupVisibleButton();
        // fazer o mesmo para whats
        const whatsComponents = resp.items[0].content.cta.components.whatsapp;
        this.whatsAppComponentsTmp = whatsComponents;
        if (this.typeLive) {
          this.setWhatsAppTabs(this.whatsAppComponentsTmp);
        }
        this.setupVisibleButtonWhatsApp()
        const anyDefineIntervalTrueWhats = this.whatsappTabs.some(tab => tab.define_interval);
        if (anyDefineIntervalTrueWhats) {
          this.whatsappTabs.forEach(tab => tab.define_interval = true);
        } else {
          this.whatsappTabs.forEach(tab => tab.define_interval = false);
          if (this.whatsappTabs.length > 0) {
            this.isAddButtonDisabledWhats = true;
          }

        }
        if (this.whatsappTabs.length == 1 && this.whatsappTabs[0].define_interval == false) {
          this.isAddButtonDisabledWhats = false;
        }
        // fazer o mesmo para whats
        // fazer o mesmo para AlertsTab
        const alertsComponents = resp.items[0].content.cta.components.alerts;
        this.alertsAppComponentsTmp = alertsComponents;
        // Mapeie os componentes custom para o formato AccordionTab
        if (this.typeLive) {
          this.setAlerts(this.alertsAppComponentsTmp);
        }


        this.displayButtons = true;

       // this.setupVisibleButton();
        this.setLinks(resp.items[0].content.cta.components.links);
        this.setupFilteredAlerts();

        this.setupFilteredLinks();
        this.setupFilteredLinksShortucut();
        // fazer o mesmo para AlertsTab targetAlerts
        this.showMenuInf = false;
        this.showControls = true;
        this.showBtns = true;
        this.marginTopVideo();

        this.hasData = true;


        if (!this.typeLive) {
          this.connectToRoom();
        }

        if (!this.typeLive) {

          //this.initVideoPlayer();

          this.initVideoPlayer();
        } else {
          this.iniciarTimeout();
        }
        //this.updateGridRowsProducts();
      },
      error: (err) => {

      }
    });
  }

  private normalizeProductData(productFromService: ProdutoShopify): Produto {
    let showInterval = false;

    // Verifica se há intervalo de tempo válido
    if (productFromService.created_at !== "00:00:00" || productFromService.updated_at !== "00:00:00") {
      showInterval = true;
    }
    //isSelected
    return {
      id: productFromService.id,                      // ID convertido corretamente
      sku: productFromService.sku,
      name: productFromService.name,     // Título do produto
      status: 1,                          // Status padrão
      inputQty: productFromService.inventory_quantity ?? 0,                         // Quantidade padrão
      position: 0,                        // Posição padrão
      price: productFromService.price,
      onsale_price: productFromService.compare_at_price || '', // Preço promocional
      price_exchange: '',                 // Não especificado
      thumbnail: productFromService.image || productFromService.thumbnail || '', // Imagem
      isSelected: true,  // Se não existir, define como falso
      show_item: productFromService.show_item ?? true,     // Visível por padrão
      highlight: productFromService.highlight ?? false,    // Não destacado por padrão
      define_interval: productFromService.define_interval ?? false,  // Sem intervalo por padrão
      show_slider: showInterval,                    // Slider ativado se houver intervalo
      comprar_font_color: productFromService.comprar_font_color ?? '#FFFFFF',  // Cor padrão
      comprar_bg_color: productFromService.comprar_bg_color ?? '#000000',
      adicionar_font_color: productFromService.adicionar_font_color ?? '#000000',
      adicionar_bg_color: productFromService.adicionar_bg_color ?? '#FFFFFF',
      interval_time: productFromService.interval_time ?? { start: '', end: '' }, // Intervalo vazio
    };
  }


  // private normalizeProductData(productFromService: ProdutoShopify): Produto {
  //   let showInterval = false;

  //   // Verifica se há intervalo de tempo válido
  //   if (productFromService.created_at !== "00:00:00" || productFromService.updated_at !== "00:00:00") {
  //     showInterval = true;
  //   }

  //   return {
  //     id: parseInt(productFromService.id.split('/').pop() || '0', 10), // Extrai o ID e converte para número
  //     name: productFromService.title, // Título do produto
  //     status: 1,                      // Status padrão
  //     inputQty: 0,                    // Quantidade padrão
  //     position: 0,                    // Posição padrão
  //     price: productFromService.price,
  //     onsale_price: productFromService.compare_at_price || '', // Preço promocional se existir
  //     price_exchange: '',             // Não especificado
  //     thumbnail: productFromService.thumbnail || '', // Imagem do produto
  //     isSelected: true,               // Produtos de target já começam selecionados
  //     show_item: true,                // Produto visível por padrão
  //     highlight: false,               // Não destacado por padrão
  //     define_interval: false,         // Sem intervalo definido por padrão
  //     show_slider: showInterval,      // Slider ativado se houver intervalo
  //     comprar_font_color: '#FFFFFF',  // Cor padrão para o botão Comprar
  //     comprar_bg_color: '#000000',
  //     adicionar_font_color: '#000000',// Cor padrão para o botão Adicionar
  //     adicionar_bg_color: '#FFFFFF',
  //     interval_time: { start: '', end: '' } // Intervalo vazio por padrão
  //   };
  // }

  loadPromotions(): void {
    console.log('Loading promotion');
    this.productsService.GetPromotions().pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => {
        this.sourcePromotions = resp;
      },
      error: (err) => console.error('Erro ao carregar produtos:', err)
    });
  }

  removeFromSelected(productToRemove: Produto): void {
    this.targetProducts = this.targetProducts.filter(product => product !== productToRemove);
    this.pickListObserverService.notifyChange();
  }
  onDefineIntervalChange(product: Produto): void {
    product.show_slider = !product.show_slider; // Alterna a visibilidade do slider
    this.pickListObserverService.notifyChange();
  }
  onMoveToSource(event: any): void {
    const picklistItem = document.getElementsByClassName('p-picklist-item');
    (picklistItem[0] as HTMLElement).style.setProperty('z-index', '15001', 'important');
    event.items.forEach((item: Produto) => item.isSelected = false);
    this.updateProductPositions();
    console.log("Move to source");
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
    if (this.filteredProducts$) {
      this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
        map(products =>
          products.filter(product => product.highlight).slice(0, 3)
        )
      );
    }
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
  }
  onMoveToTarget(event: any): void {
    const picklistItem = document.getElementsByClassName('p-picklist-item');
    (picklistItem[0] as HTMLElement).style.setProperty('z-index', '15001', 'important');
    console.log("Move to target");
    event.items.forEach((item: Produto) => {
      item.isSelected = true;
      // Outras propriedades para os switches e sliders
      item.highlight = false;
      item.define_interval = false;
      item.show_slider = false;
      item.show_item = false;
      item.interval_time = { start: "00:00:00", end: "00:00:00" },
        // Definindo cores padrão para botões e fundos
        item.comprar_font_color = "#FFFFFF"; // Cor branca para o texto do botão de compra
      item.comprar_bg_color = "#007BFF"; // Cor azul para o fundo do botão de compra
      item.adicionar_font_color = "#FFFFFF"; // Cor branca para o texto do botão de adicionar
      item.adicionar_bg_color = "#28A745";
    });
    console.log(this.targetProducts);

    this.updateProductPositions();
    this.targetProducts = [...this.targetProducts];
    this.filteredProducts$ = of([...this.targetProducts]);
    this.setupFilteredProducts();
    if (this.filteredProducts$) {
      this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
        map(products =>
          products.filter(product => product.highlight).slice(0, 3)
        )
      );
    }
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
  }
  onMoveToSourcePromos(event: any): void {
    event.items.forEach((item: Produto) => item.isSelected = false);
    this.pickListObserverService.notifyChange();
    console.log("Move to source");
    this.setupFilteredPromotions();
  }

  updateProductPositions(): void {
    this.targetProducts.forEach((product, index) => {
      product.position = index;
    });
    this.prevTargetProducts = [...this.targetProducts];
    this.debounceFunction();
  }
  onMoveToTargetPromos(event: any): void {
    console.log("Move to target");
    event.items.forEach((item: Produto) => {
      item.isSelected = true;
      // Outras propriedades para os switches e sliders
      item.highlight = false;
      item.define_interval = false;
      item.show_slider = false;
      item.show_item = false;
      item.interval_time = { start: "00:00:00", end: "00:00:00" },
        // Definindo cores padrão para botões e fundos
        item.comprar_font_color = "#FFFFFF"; // Cor branca para o texto do botão de compra
      item.comprar_bg_color = "#007BFF"; // Cor azul para o fundo do botão de compra
      item.adicionar_font_color = "#FFFFFF"; // Cor branca para o texto do botão de adicionar
      item.adicionar_bg_color = "#28A745";
    });

    this.pickListObserverService.notifyChange();
    this.setupFilteredPromotions();
  }
  smartAutoPlay: boolean = false;
  fakeBar: boolean = true;
  private _fakeBar = new BehaviorSubject<boolean>(true);
  fakeBar$ = this._fakeBar.asObservable();
  setFakeBar(value: boolean): void {
    this._fakeBar.next(value);
  }


  timeToPitch: boolean = false;
  timePitch?: string;
  barColor: string = '#FF1122';
  playPauseColor: string = '#FF0000';
  time_to_pitch?: string;
  saveAsset(n: number): void {
    console.log('no saveAsset111')
    this.mediaAspectRatio = this.valSelect1Video.value;
    this.issueForm = this.fb.group({
      widget: this.fb.array([ // Início do FormArray para 'widget'
        this.fb.group({ // Início do FormGroup dentro do array 'widget'
          assets: this.fb.group({ // Início do FormGroup para 'assets'
            items: this.fb.array([ // Início do FormArray para 'items'
              this.fb.group({ // Início do FormGroup para cada item em 'items'
                schedule: this.fb.group({
                  date_from: [this.scheduleDateFrom],
                  date_to: [this.scheduleDateTo]
                }),
                media: this.fb.group({
                  type: [this.mediaType],
                  src: [this.replaceDomain(this.mediaSrc)],
                  time_to_pitch: this.time_to_pitch,
                  img_slate: [""],
                  duration: [this.mediaDuration],
                  thumbnail: this.imageSelected ? [this.replaceDomain(this.mediaThumbnail)] : [],
                  aspect_ratio: [this.mediaAspectRatio],
                  status: [this.mediaStatus]
                }),
                content: this.fb.group({
                  title: [this.slickTitle],
                  description: [this.slickDesc],
                  cta: this.fb.group({
                    type: [0],
                    components: this.fb.group({
                      custom: this.fb.array(this.accordionTabs), // Se 'this.accordionTabs' já é um array, use diretamente sem colchetes adicionais
                      whatsapp: this.fb.array(this.whatsappTabs), // Se 'this.whatsappTabs' já é um array, use diretamente sem colchetes adicionais
                      products: this.fb.group({
                        items: this.fb.array(this.targetProducts) // Se 'this.targetProducts' já é um array, use diretamente sem colchetes adicionais
                      }),
                      promotions: this.fb.group({
                        items: this.fb.array(this.targetPromotions) // Se 'this.targetPromotions' já é um array, use diretamente sem colchetes adicionais
                      }),
                      alerts: this.fb.array(this.alertsTabs), // Se 'this.alertsTabs' já é um array, use diretamente sem colchetes adicionais
                      links: this.fb.array(this.linksSelecteds)
                    })
                  })
                }),
                extra: this.fb.group({ // Início do FormGroup 'extra'
                  player: this.fb.group({ // FormGroup 'player' dentro de 'extra'
                    time_to_pitch: [this.timeToPitch ? 1 : 0],
                    time_to_pitch_value: [this.timePitch],
                    player_color: [this.playPauseColor],
                    progress_bar_color: [this.progressBarColor],
                    auto_play: [this.smartAutoPlay ? 1 : 0],
                    fake_bar: [this.fakeBar ? 1 : 0],

                  }),
                  "tags": this.tagsString
                }) // Fim do FormGroup 'extra'
              }) // Fim do FormGroup para cada item em 'items'
            ]) // Fim do FormArray para 'items'
          }) // Fim do FormGroup para 'assets'
        }) // Fim do FormGroup dentro do array 'widget'
      ]) // Fim do FormArray para 'widget'
    });
    console.log('form')
    //console.log(JSON.stringify(this.issueForm.value, null, 2));
    this.liveShopService.updateAsset(this.issueForm.value, this.asset_id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp) => {
        if (n == 1) {
          this.loadingBtn = false;
          this.uploadOn = false;
          console.log("PPPPPPP")
          this.loadProducts();
          if (this.player) {
            this.player.src({ src: this.replaceDomain(this.mediaSrc) || '' });
          }
          //this.unregisterSwService.registerServiceWorker();
          // Exiba uma mensagem de sucesso
          this.messageService.add({
            severity: 'success',
            summary: 'Slick Salvo',
            detail: 'O slick foi salvo com sucesso!'
          });
          this.loadingBtn = false;
        }
      },
      error: (error) => {
        let message = '';
        this.savePixelLoading = false;
        if (error.status === 400 || error.status === 403 || error.status === 401) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          //this.showErrorDialog(message);

          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: message
          });
        }
        this.loadProducts();
        ///
      }
    });

  }
  showErrorDialog(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Erro',
      icon: 'pi pi-times',
      accept: () => {
        // Lógica de aceitação, se necessário
      }
    });
  }
  mediaType?: string | null;
  mediaSrc?: string | null;
  mediaDuration?: number | null;
  mediaThumbnail?: string | null;
  mediaAspectRatio?: string | null;
  mediaStatus?: number | null;
  contentTitle?: string | null;
  scheduleDateFrom?: string | null;
  scheduleDateTo?: string | null;
  asset_id!: number;

  customComponentsTmp?: any;
  whatsAppComponentsTmp?: any;
  alertsAppComponentsTmp?: any;
  playerTimeout?: any;
  linksSelecteds: Links[] = [];
  showBtns: boolean = false;
  // Botão de Comprar duration adjustVideoMargin 
  shouMuteImg: boolean = false;
  displayButtons: boolean = false;
  videoReadyToLoad: boolean = false;



  setVideoSRC(videoSRC: string | null | undefined): string | null {
    const replacedDomainURL = this.replaceDomain(videoSRC);
    if (replacedDomainURL) {
      return replacedDomainURL.split('?')[0] + '?ngsw-bypass=true';
    }
    return null; // ou qualquer valor padrão que faça sentido no seu caso
  }
  username: string = '';
  currentNumUsers: number = 0;
  private subscriptionsChat = new Subscription();
  user_chat?: string;
  private connectToRoom(): void {
    if (!this.uuid) {
      console.error('uuid is undefined');
      return; // ou definir um valor padrão antes de continuar
    }
    this.user_chat = localStorage.getItem('user_chat') ?? undefined;
    if (!this.user_chat) {
      const newUID = localStorage.getItem('user_id') ?? 'defaultUID';
      localStorage.setItem('user_chat', newUID);
      localStorage.setItem('user_ip', newUID);
      this.user_chat = newUID;
    }


    this.chatWebSocketService.joinRoom(this.uuid, 'admin', this.user_chat);
    this.chatWebSocketService.getInitialMessages(this.uuid);
    this.chatWebSocketService.receiveMessages().pipe(takeUntil(this.destroy$)).subscribe(message => {
      console.log('Received message:', message);
    });
    this.chatWebSocketService.receiveInitialMessages().pipe(takeUntil(this.destroy$)).subscribe(messages => {
      console.log('Initial messages:', messages);
    });
    // Subscrever para receber o número de usuários e atualizar a propriedade
    const numUsersSub = this.chatWebSocketService.getNumUsers(this.uuid).pipe(takeUntil(this.destroy$)).subscribe(numUsers => {
      this.currentNumUsers = numUsers;
      console.log('Number of users in chat:', numUsers);
    });
    this.subscriptionsChat.add(numUsersSub);
  }

  changeIntervalAlert() {
    this.setupFilteredAlerts();
    this.debounceFunction();
  }


  setLinks(linksComponents: any) {
    this.linksSelecteds = linksComponents.map((component: any) => {
      const start = component.time_from || "00:00:00";
      const end = component.time_to || "00:00:00";

      // Utiliza a função convertAccordionTimeToSliderValue para calcular os valores do slider
      const sliderTimeRange = this.convertAccordionTimeToSliderValue(start, end, this.sliderMaxValue);

      console.log(this.sliderMaxValue)
      return {
        id: component.id,
        name: component.name,
        bg_color: component.bg_color || '#000000', // Certifique-se de ter um valor padrão para background
        define_interval: component.define_interval, // Convertendo para booleano
        show_item: component.show_item, // Convertendo para booleano
        link_type: component.link_type,
        position: component.position, //
        redirect_url: component.redirect_url,
        click_url: component.click_url, //
        interval_time: {
          start: component.time_from || "00:00:00",
          end: component.time_to || "00:00:00"
        },
        slider_time_range: sliderTimeRange // Usando os valores calculados
      };
    });
    this.changeShowLinks();
  }
  changeShowLinks() {
    this.cdr.detectChanges();
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
    this.debounceFunction();
  }
  setAlerts(alertsComponents: any) {
    this.alertsTabs = alertsComponents.map((component: any) => {
      const start = component.time_from || "00:00:00";
      const end = component.time_to || "00:00:00";

      // Utiliza a função convertAccordionTimeToSliderValue para calcular os valores do slider
      const sliderTimeRange = this.convertAccordionTimeToSliderValue(start, end, this.sliderMaxValue);

      return {
        header: component.header,
        text: component.text,
        showAlerts: component.show_alerts || '',
        font_color: component.font_color || '#FFFFFF',
        background: component.background || '#000000', // Certifique-se de ter um valor padrão para background
        define_interval: component.define_interval, // Convertendo para booleano
        show_item: component.show_item, // Convertendo para booleano
        interval_time: {
          start: component.time_from || "00:00:00",
          end: component.time_to || "00:00:00"
        },
        slider_time_range: sliderTimeRange // Usando os valores calculados
      };
    });
    this.changeShowAlert();
  }
  changeShowAlert() {
    this.cdr.detectChanges();
    this.setupFilteredAlerts();
    this.debounceFunction();
  }

  setupFilteredAlerts(): void {
    this.filteredAlerts$ = combineLatest([
      of(this.alertsTabs),
    ]).pipe(
      map(([alerts]) => {
        let filteredAlerts = alerts;
        // Adiciona a verificação de show_item na filtragem
        return filteredAlerts.filter(alert => alert.show_item);
      })
    );


    this.filteredAlerts$.subscribe(alerts => {
      this.hasVisibleAlerts = alerts.length > 0;
      this.updateGridRowsProducts(); // Chamada para atualizar as linhas sempre que o estado do botão mudar

      this.resizeObserverBreakPoints = new ResizeObserver(entries => {
        for (const entry of entries) {
          const width = entry.contentRect.width;
          this.applySwiperStyles(width);
        }
      });

      if (this.gridContainer) {
        this.resizeObserverBreakPoints.observe(this.gridContainer.nativeElement);
      }
    });



  }
  setWhatsAppTabs(whatsComponents: any) {
    this.whatsappTabs = whatsComponents.map((component: any) => {
      const start = component.time_from || "00:00:00";
      const end = component.time_to || "00:00:00";
      console.log('whatsapp');
      console.log(this.sliderMaxValue);
      // Utiliza a função convertAccordionTimeToSliderValue para calcular os valores do slider
      const sliderTimeRange = this.convertAccordionTimeToSliderValue(start, end, this.sliderMaxValue);

      return {
        header: component.title,
        ddd: component.ddd,
        show_item: component.show_item,
        fone: component.phone || '',
        define_interval: component.define_interval, // Isto converte para booleano
        interval_time: {
          start: component.time_from || "00:00:00",
          end: component.time_to || "00:00:00"
        },
        slider_time_range: sliderTimeRange // Usando os valores calculados
      };
    });
  }
  setCustom(customComponents: any) {
    this.accordionTabs = customComponents.map((component: any) => {
      const start = component.time_from || "00:00:00";
      const end = component.time_to || "00:00:00";
      // Supondo que convertTimeToSliderValue já foi corrigido conforme discutido
      const sliderTimeRange = this.convertAccordionTimeToSliderValue(start, end, this.sliderMaxValue);
      return {
        header: component.title, // ou outro campo relevante para o cabeçalho
        button_text: component.name,
        show_item: component.show_item,
        redirect_url: component.redirect_url || '',
        font_color_custom: component.font || '#FFFFFF',
        bg_color_custom: component.background || '#000000',
        define_interval: component.define_interval,
        interval_time: {
          start: component.time_from || "00:00:00",
          end: component.time_to || "00:00:00"
        },
        // Use os valores convertidos para inicializar slider_time_range
        slider_time_range: sliderTimeRange
      };
    });
  }

  filteredProducts$?: Observable<Produto[]>;
  filteredProductsAll$: Observable<Produto[]> = of([]);;
  setupFilteredProducts(): void {
    const products$ = combineLatest([
      this.stateManagementService.getVideoTime(),
      of(this.targetProducts),
      this.stateManagementService.typeLive$
    ]).pipe(
      map(([videoTime, products, typeLive]) => {
        return products.filter(product => {
          if (typeLive) {
            return product.show_item; // Exibir todos os produtos que devem ser mostrados quando `typeLive` é verdadeiro
          }
          if (!product.define_interval) {
            return product.show_item; // Exibir sempre se o intervalo não está definido
          }

          const startSeconds = this.timeToSeconds(product.interval_time?.start || '00:00:00');
          const endSeconds = this.timeToSeconds(product.interval_time?.end || '00:00:00');
          const isInTimeInterval = (videoTime >= startSeconds && videoTime <= endSeconds) ||
            (startSeconds === 0 && endSeconds === 0);
          return isInTimeInterval && product.show_item;
        });
      })
    );

    // Exibe até 5 produtos no Swiper
    this.filteredProducts$ = products$.pipe(
      map(products => products.slice(0, 5))
    );

    // Exibe todos os produtos na Sidebar
    this.filteredProductsAll$ = products$;
  }
  openProductsSidebar(): void {
    this.showAllProductsSidebar = true;    // Abre a Sidebar
  }

  //////////////////////////
  visibleButton$?: Observable<AccordionTab | null>;

  setupVisibleButton(): void {
    this.visibleButton$ = combineLatest([
      this.stateManagementService.getVideoTime(),
      this.stateManagementService.typeLive$
    ]).pipe(
      map(([videoTime, typeLive]) => {
        const result = this.accordionTabs.find(tab => tab.show_item) || null;
        console.log('Resultado do botão visível:', result);
        return result;
      })
    );

    this.visibleButton$.subscribe(visibleButton => {
      this.hasVisibleButton = !!visibleButton;
    });
  }

  shouldShowButton(tab: AccordionTab, videoTime: number): boolean {

    if (tab.define_interval === false) {
      return true;
    }

    const startSeconds = this.timeToSeconds(tab.interval_time?.start || '00:00:00');
    const endSeconds = this.timeToSeconds(tab.interval_time?.end || '00:00:00');
    return (videoTime >= startSeconds && videoTime <= endSeconds) || (startSeconds === 0 && endSeconds === 0);
  }
  //////////////////////////
  visibleButtonWhatsApp$?: Observable<WhatsAppTab | null>;

  setupVisibleButtonWhatsApp(): void {
    this.visibleButtonWhatsApp$ = combineLatest([
      this.stateManagementService.getVideoTime(),
      this.stateManagementService.typeLive$
    ]).pipe(
      map(([videoTime, typeLive]) => {
        // Se typeLive for true, retorne o primeiro botão WhatsApp que deveria ser mostrado
        return this.whatsappTabs.find(tab => tab.show_item) || null;
      })
    );
  }

  shouldShowButtonWhatsApp(tab: WhatsAppTab, videoTime: number): boolean {

    if (tab.define_interval === false) {
      return true;
    }

    const startSeconds = this.timeToSeconds(tab.interval_time?.start || '00:00:00');
    const endSeconds = this.timeToSeconds(tab.interval_time?.end || '00:00:00');
    return (videoTime >= startSeconds && videoTime <= endSeconds) || (startSeconds === 0 && endSeconds === 0);
  }

  // Extrai a lógica de abertura do WhatsApp para sua própria função para reutilização.
  displayDialogWhats: boolean = false;

  cancelRedirectWhats() {
    this.displayDialogWhats = false;
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  }
  openWhatsApp(tab: WhatsAppTab): void {
    this.tmpWhasPhone = `55${tab.ddd}${tab.fone}`;
    this.displayDialogWhats = true;
    if (!document.pictureInPictureElement && this.player.requestPictureInPicture) {
      this.player.requestPictureInPicture();
    }

  }


  convertToRgba(hexColor: string | undefined, opacity: number): string {
    if (!hexColor) {
      return ''; // Retorne um valor padrão ou mantenha vazio
    }

    return hexColor + '30'; // Retorna a cor original caso algo dê errado ou o formato não seja esperado
  }


  confirmRedirectWhats() {
    this.displayDialogWhats = false;
    this.openWhatsAppUrl(this.tmpWhasPhone);
    console.log(this.tmpWhasPhone)
  }
  tmpWhasPhone: string = '';
  // A função para abrir o WhatsApp foi extraída para ser reutilizada.
  openWhatsAppUrl(phoneNumber: string) {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');

  }

  //////////////////////////
  filteredPromotions$: Observable<Promotion[]> = of([]);
  setupFilteredPromotions(): void {
    this.filteredPromotions$ = combineLatest([
      this.stateManagementService.getVideoTime(),
      of(this.targetPromotions),
      this.stateManagementService.typeLive$
    ]).pipe(
      map(([videoTime, promotions, typeLive]) => {
        if (typeLive) {
          // Se typeLive for true, retorne todas as promoções
          return promotions;
        } else {

          // Se typeLive for false, aplique o filtro baseado em videoTime
          return promotions.filter(promotion => {
            if (promotion.define_interval === false) {
              return true;
            }
            const startSeconds = this.timeToSeconds(promotion.interval_time?.start || '00:00:00');
            const endSeconds = this.timeToSeconds(promotion.interval_time?.end || '00:00:00');
            return (videoTime >= startSeconds && videoTime <= endSeconds) ||
              (startSeconds === 0 && endSeconds === 0);
          });
        }
      })
    );
  }
  //////////////////////////



  filteredLinks$?: Observable<Links[]>;
  setupFilteredLinks(): void {
    this.filteredLinks$ = combineLatest([
      of(this.linksSelecteds),
    ]).pipe(
      map(([links]) => {
        let filteredLinks = links.filter(link => !link.link_type || link.link_type === 'swiper');
        // Adiciona a verificação de show_item na filtragem
        return filteredLinks.filter(link => link.show_item);
      }),
      takeUntil(this.destroy$)
    );


    this.filteredLinks$.subscribe(() => {
      this.resizeObserverBreakPoints = new ResizeObserver(entries => {
        for (const entry of entries) {
          const width = entry.contentRect.width;
          this.applySwiperStyles2(width);
        }
      });

      if (this.gridContainer) {
        this.resizeObserverBreakPoints.observe(this.gridContainer.nativeElement);
      }
    });

  }
  showAllShortucutLinks: boolean = false;
  filteredLinksShortucut$?: Observable<Links[]>;
  hasLinks$?: Observable<boolean>;
  additionalLinks$?: Observable<Links[]>;
  private resizeSubscription: Subscription | null = null;

  @ViewChild('btnsRightSup') btnsRightSup?: ElementRef;
  trackByLinkId(index: number, link: Links): number {
    return link.id; // ou outra propriedade única
  }
  setupFilteredLinksShortucut(): void {
    const linksSource$ = combineLatest([
      of(this.linksSelecteds),
    ]).pipe(
      map(([links]) => {
        // Primeiro, filtrar links que são do tipo 'shortcut' e que devem ser mostrados.
        let filteredLinks = links.filter(link => link.link_type === 'shortcut' && link.show_item);
        return filteredLinks;
      })
    );

    // this.filteredLinksShortucut$ = linksSource$.pipe(
    //   map(links => links)  // Aqui você pode aplicar outras transformações conforme necessário
    // );

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const totalHeight = entry.contentRect.height;
        const fixedHeight = 28 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        const availableHeight = totalHeight - fixedHeight;

        const itemsHeight = 6 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        const itemsToShow = Math.floor(availableHeight / itemsHeight);

        this.updateLinksDisplay(itemsToShow, linksSource$);
      }
    });

    if (this.btnsRightSup?.nativeElement) {
      resizeObserver.observe(this.btnsRightSup.nativeElement);
      this.resizeSubscription = new Subscription(() => resizeObserver.disconnect());
    }

    // this.hasLinks$ = this.filteredLinksShortucut$.pipe(
    //   map(links => links.length > 0)
    // );

    // Criar um observable para os links adicionais que não estão entre os três primeiros.
    this.additionalLinks$ = linksSource$.pipe(
      map(links => links.length > 0 ? links : [])
    );

    if (this.filteredLinks$)
      this.filteredLinks$.subscribe(alerts => {
        this.hasVisibleLinksSwiper = alerts.length > 0;
        this.updateGridRowsProducts(); // Chamada para atualizar as linhas sempre que o estado do botão mudar
      });
  }

  getActionMenu2(): MenuItem[] {
    const actionMenu: MenuItem[] = [];

    // Adicionar o item de Modo Teatro de acordo com as regras definidas
    if (this.hasTheaterMode && !this.isInTheaterMode) {
      actionMenu.push({
        label: 'Abrir Modo Teatro',
        icon: 'bi bi-aspect-ratio',
        command: () => this.modoTeatro()
      });
    } else if (this.hasTheaterMode && this.isInTheaterMode) {
      actionMenu.push({
        label: 'Sair do Modo Teatro',
        icon: 'bi bi-aspect-ratio-fill',
        command: () => this.sairModoTeatro()
      });
    }

    // Adicionar o item de Modo Teatro de acordo com as regras definidas
    if (!this.isFullScreen) {
      actionMenu.push({
        label: 'Abrir modo Tela cheia',
        icon: 'pi pi-window-maximize',
        command: () => this.goFullscreen()
      });
    } else if (this.isFullScreen) {
      actionMenu.push({
        label: 'Sair do modo Tela cheia',
        icon: 'pi pi-window-minimize',
        command: () => this.goMinscreen()
      });
    }

    // Adicionar o item de PiP
    actionMenu.push({
      label: 'PiP',
      icon: this.isPiPActive ? 'bi bi-pip-fill' : 'bi bi-pip',
      command: () => this.togglePiP()
    });

    return actionMenu;
  }

  updateLinksDisplay(itemsToShow: number, linksSource$: Observable<Links[]>): void {
    this.filteredLinksShortucut$ = linksSource$.pipe(
      map(links => links.slice(0, itemsToShow))
    );
    this.hasLinks$ = this.filteredLinksShortucut$.pipe(
      map(links => links.length > 0)
    );
    this.resizeObserverBreakPoints = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        this.applySwiperStyles2(width);
        this.applySwiperStyles3(width);
      }
    });

    if (this.gridContainer) {
      this.resizeObserverBreakPoints.observe(this.gridContainer.nativeElement);
    }
  }


  updateLinkType() {


    // Encontre o índice com base no identificador único
    const index = this.linksSelecteds.findIndex(link => link.name === this.linkSelecionado.name);
    //const index = this.linksSelecteds['id'];
    console.log('index:', index);
    if (index !== -1) {
      this.linksSelecteds[index] = { ...this.linkSelecionado };
    }

    console.log('Depois da atualização:', this.linksSelecteds);
    this.linksSelecteds = [...this.linksSelecteds];
    console.log('Lista reatribuída:', this.linksSelecteds);

    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
    this.debounceFunction();
  }

  // getLinksSource(): Observable<Links[]> {
  //   // Simula a obtenção dos links (deverá ser substituído pelo seu serviço real)
  //   return this.stateManagementService.getLinksObservable();
  // }

  openSidebarCTALinks() {
    console.log('chamou método')
    this.showAllShortucutLinks = !this.showAllShortucutLinks;
  }

  //////////////////////////
  sidebarPromotions: boolean = true;
  sidebarVisible = false;
  @ViewChild('promoDiv') promoDiv?: ElementRef;
  //private clickOutsideHandler: (event: MouseEvent) => void;
  toggleSidebarPromos(event: MouseEvent): void {
    //document.body.classList.add('body-fixed');
    event.stopPropagation();
    this.sidebarVisible = !this.sidebarVisible;
    //this.adjustVideoMargin();
  }

  //////////////////////////


  normalizePromosData(productFromService: any) {
    let showInterval = false;
    if (productFromService.interval_time.start != "00:00:00" || productFromService.interval_time.end != "00:00:00") {
      showInterval = true;
    }
    return {
      id: productFromService.id, // Use id em vez de product_id
      name: productFromService.header,
      value: productFromService.value,
      status: productFromService.status,
      isSelected: true,
      show_item: productFromService.show_item,
      highlight: productFromService.highlight,
      define_interval: productFromService.define_interval, // Supondo que você queria essa propriedade baseado na imagem
      show_slider: showInterval,
      interval_time: productFromService.interval_time,
      // Outras propriedades que você deseja incluir...
    };
  }
  convertPriceProduct(price: any): number {
    // Verifica se price é uma string e se contém uma vírgula
    if (typeof price === 'string' && price.includes(',')) {
      // Substitui a vírgula por ponto para fazer a conversão correta para float
      return parseFloat(price.replace(',', '.'));
    } else if (typeof price === 'string') {
      // Se for uma string, mas não tiver vírgula, tenta converter diretamente
      return parseFloat(price);
    } else {
      // Se não for uma string ou se não puder ser convertido, retorna 0 ou algum outro valor padrão
      // Você pode ajustar este retorno conforme a necessidade da sua aplicação
      return price;
    }
  }


  toggleDefineInterval(value: boolean): void {

    if (this.selectedProductForEdit) { // onDefineIntervalChange
      // Criar uma cópia atualizada do produto
      const updatedProduct = {
        ...this.selectedProductForEdit,
        define_interval: value,
        show_slider: value
      };

      // Atualizar o produto na lista de produtos alvo (targetProducts)
      const index = this.targetProducts.findIndex(p => p.id === this.selectedProductForEdit.id);
      if (index !== -1) {
        this.targetProducts[index] = updatedProduct;
        // Atualizar a referência para selectedProductForEdit
        this.selectedProductForEdit = updatedProduct;
      }

      this.targetProducts = [...this.targetProducts];
      this.setupFilteredProducts();

      if (this.filteredProducts$) {
        this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
          map(products =>
            products.filter(product => product.highlight).slice(0, 3)
          )
        );
      }

      this.cdr.detectChanges();
      this.debounceFunction();
    }
  }
  toggleDefineIntervalPromotions(value: boolean): void {

    if (this.selectedProductForEdit) { // onDefineIntervalChange
      // Criar uma cópia atualizada do produto
      const updatedProduct = {
        ...this.selectedProductForEdit,
        define_interval: value,
        show_slider: value
      };

      // Atualizar o produto na lista de produtos alvo (targetPromotions)
      const index = this.targetPromotions.findIndex(p => p.id === this.selectedProductForEdit.id);
      if (index !== -1) {
        //this.targetPromotions[index] = updatedProduct;
        // Atualizar a referência para selectedProductForEdit
        this.selectedProductForEdit = updatedProduct;
      }

      this.targetPromotions = [...this.targetPromotions];
      this.setupFilteredPromotions();

      ////////////this.cdr.detectChanges();
      this.debounceFunction();
    }
  }



  onSliderChangeCustom(index: number, event: any): void {
    // Assumindo que o evento traz um objeto com 'start' e 'end'
    const { start, end } = event.values;

    // Verifique se ambos os valores são strings de tempo válidas
    if (typeof start === 'string' && typeof end === 'string') {
      const tab = this.accordionTabs[index];

      // Atualize o objeto 'tab' diretamente com os novos valores
      if (!tab.interval_time) {
        tab.interval_time = { start: "00:00:00", end: "00:00:00" };
      }

      //tab.interval_time = { start: start, end: end };
      tab.interval_time.start = this.convertNumericToTime(event.values[0]);
      tab.interval_time.end = this.convertNumericToTime(event.values[1]);
      // this.debounceFunction();
    } else {
      console.error('Valores do slider não são strings de tempo:', start, end);
    }
  }

  convertNumericToTime(numericValue: number): string {
    const hours = Math.floor(numericValue / 60);
    const minutes = numericValue % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
  }


  onSliderChangeWhats(index: number, event: any): void {
    const tab = this.whatsappTabs[index];
    // Aqui você chama o método de conversão passando os valores do slider (event.values) para obter os novos tempos de início e fim formatados.
    const { start, end } = this.convertSliderValueToTime(event.values);
    tab.interval_time!.start = start;
    tab.interval_time!.end = end;
  }

  convertSliderValueToTime(values: number[]): { start: string, end: string } {
    const convertValueToTimeString = (val: number): string => {
      const hours = Math.floor(val / 3600);
      const minutes = Math.floor((val % 3600) / 60);
      const seconds = val % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return {
      start: convertValueToTimeString(values[0]),
      end: convertValueToTimeString(values[1])
    };
  }
  convertAccordionTimeToSliderValue(start: string, end: string, sliderMaxValue: number): [number, number] {
    const convertTimeStringToSliderValue = (timeString: string): number => {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      // Converte o tempo para segundos totais
      const totalTimeInSeconds = (hours * 3600) + (minutes * 60) + seconds;
      // Ajusta o valor para o intervalo do slider
      return totalTimeInSeconds / sliderMaxValue; // Proporção do sliderMaxValue
    };

    const startValue = convertTimeStringToSliderValue(start) * sliderMaxValue;
    const endValue = convertTimeStringToSliderValue(end) * sliderMaxValue;

    // Garante que os valores estejam dentro dos limites do slider
    return [startValue, endValue];
  }




  convertTimeToSliderValue(time: { start: string, end: string } | undefined): number[] {
    if (time) {
      const convertTimeStringToValue = (timeString: string): number => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        // Aqui estamos convertendo tudo para segundos, se necessário.
        return (hours * 3600) + (minutes * 60) + seconds;
      };

      return [convertTimeStringToValue(time.start), convertTimeStringToValue(time.end)];
    }
    // Retorne um valor padrão se 'time' for 'undefined'.
    return [0, 86400]; // Pode ajustar para um valor padrão específico, se necessário.
  }

  issueForm?: FormGroup;

  openDialogDrag: boolean = false;
  openDialogDragPromotion: boolean = false;
  selectedProductIndex: number | null = null;
  // sliderValue
  openSidebarEditConfigs(product: Produto, index: number): void {
    // Inicialize o intervalTime caso não esteja definido
    console.log("Interval time before conversion:", product.interval_time);
    const intervalTime = product.interval_time || { start: '00:00:00', end: '00:00:00' };

    // Atualize a referência de selectedProductForEdit para o objeto dentro de targetProducts
    this.selectedProductIndex = this.targetProducts.findIndex(p => p.id === product.id);
    if (this.selectedProductIndex !== -1) {
      this.selectedProductForEdit = this.targetProducts[this.selectedProductIndex];
    } else {
      this.selectedProductForEdit = product;
    }

    this.sliderValue = this.convertTimeToSliderValue(intervalTime);
    console.log("sliderValue time before conversion:", this.sliderValue);
    this.openDialogDrag = true;
    this.pickListObserverService.notifyChange();
  }

  updateProductSwiper(product: Produto, changes: any) {
    const index = this.targetProducts.findIndex(p => p.id === product.id);
    if (index !== -1) {
      const updatedProduct = { ...this.targetProducts[index], ...changes };
      this.targetProducts[index] = updatedProduct;
      this.setupFilteredProducts();
      ////////////this.cdr.detectChanges();
    }
  }
  openSidebarEditConfigsPromotion(product: Produto, index: number): void {
    // Inicialize o intervalTime caso não esteja definido
    const intervalTime = product.interval_time || { start: '00:00:00', end: '00:00:00' };

    // Atualize a referência de selectedProductForEdit para o objeto dentro de targetProducts
    this.selectedProductIndex = this.targetProducts.findIndex(p => p.id === product.id);
    if (this.selectedProductIndex !== -1) {
      this.selectedProductForEdit = this.targetProducts[this.selectedProductIndex];
    } else {
      this.selectedProductForEdit = product;
    }

    this.sliderValuePromotion = this.convertTimeToSliderValue(intervalTime);
    this.openDialogDragPromotion = true;
    this.pickListObserverService.notifyChange();
  }

  sliderValue: number[] = [0, 1440];
  sliderValuePromotion: number[] = [0, 1440];

  fontColorOptions = [
    { label: 'Preto', value: 'black' },
    { label: 'Branco', value: 'white' },
  ];

  bgColorOptions = [
    { label: 'Vermelho', value: 'red' },
    { label: 'Verde', value: 'green' },
  ];

  closeSidebarDetail() {
    this.openDialogDrag = false;
  }
  closeSidebarDetailPromos() {
    this.openDialogDragPromotion = false;
  }
  closeSidebarDetailCtasLinks() {
    this.sideBarLinks = false;
  }

  defineIntervalCustom: boolean = false;
  intervalCustom: Custom = {
    id: 0,
    interval_time: { start: "00:00:00", end: "00:00:00" },
  }
  fontColorCustom: string = "#000000";
  bgColorCustom: string = "#ffffff";


  accordionTabs: AccordionTab[] = [
    {
      header: 'Botão 1',
      button_text: 'Botão 1',
      redirect_url: '',
      show_item: false,
      font_color_custom: '#FFFFFF',
      bg_color_custom: '#000000',
      define_interval: false,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0]
    }
  ];
  whatsappTabs: WhatsAppTab[] = [
    {
      header: 'Whatsapp 1',
      ddd: '',
      fone: '',
      define_interval: false,
      show_item: true,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0]
    }
  ];

  linksTabs: Links[] = [{
    id: 0,
    name: '',
    show_link: true,
    bg_color: '#000000',
    redirect_url: '',
    click_url: '',
    define_interval: false,
    show_item: false,
    time_from: '',
    time_to: '',
    interval_time: { start: "00:00:00", end: "00:00:00" },
    slider_time_range: [0, 0]
  }
  ];

  alertsTabs: AlertsTab[] = [
    {
      header: 'Alerta 1',
      text: '',
      font_color: '#FFFFFF',
      background: '#000000',
      define_interval: false,
      show_item: false,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0]
    }
  ];


  addComponentWhatsApp() {
    const newTabNumber = this.whatsappTabs.length + 1;
    if (this.whatsappTabs.length > 0) {
      this.whatsappTabs.push({
        header: `Whatsaap ${newTabNumber}`,
        ddd: '',
        fone: '',
        define_interval: true,
        show_item: true,
        interval_time: { start: "00:00:00", end: "00:00:00" },
        slider_time_range: [0, 0]
      });
    } else {
      this.whatsappTabs.push({
        header: `Whatsaap ${newTabNumber}`,
        ddd: '',
        fone: '',
        define_interval: false,
        show_item: true,
        interval_time: { start: "00:00:00", end: "00:00:00" },
        slider_time_range: [0, 0]
      });
      this.isAddButtonDisabledWhats = true;
    }

    this.pickListObserverService.notifyChange();
  }
  addComponentCustom() {
    const newTabNumber = this.accordionTabs.length + 1;
    if (this.accordionTabs.length > 0) {
      this.accordionTabs.push({
        header: `Botão ${newTabNumber}`,
        button_text: 'Botão 1',
        redirect_url: '',
        font_color_custom: '#FFFFFF',
        bg_color_custom: '#000000',
        define_interval: false,
        show_item: false,
        interval_time: { start: "00:00:00", end: "00:00:00" }, // Garantido que sempre existe
        slider_time_range: [0, 0]
      });
    } else {
      this.accordionTabs.push({
        header: `Botão ${newTabNumber}`,
        button_text: 'Botão 1',
        redirect_url: '',
        font_color_custom: '#FFFFFF',
        bg_color_custom: '#000000',
        define_interval: false,
        show_item: false,
        interval_time: { start: "00:00:00", end: "00:00:00" }, // Garantido que sempre existe
        slider_time_range: [0, 0]
      });
      this.isAddButtonDisabled = true;
    }
    this.setupVisibleButton();
    this.pickListObserverService.notifyChange();
  }
  handleDefineIntervalChange(tab: any) {
    const newValue = tab.define_interval;
    // Atualiza todos os itens para ter o mesmo valor de define_interval_custom
    this.accordionTabs.forEach(item => {
      item.define_interval = newValue;
    });
    // Atualiza o estado do botão de adicionar "+" baseado no novo valor
    this.isAddButtonDisabled = !newValue;
  }
  handleDefineIntervalChangeWhats(tab: any) {
    const newValue = tab.define_interval;
    // Atualiza todos os itens para ter o mesmo valor de define_interval_custom
    this.whatsappTabs.forEach(item => {
      item.define_interval = newValue;
    });
    console.log('atualiza btn em handleDefineIntervalChangeWhats');
    // Atualiza o estado do botão de adicionar "+" baseado no novo valor
    this.isAddButtonDisabledWhats = !newValue;
  }

  isAddButtonDisabled: boolean = false;
  isAddButtonDisabledWhats: boolean = false;

  removeComponentCustom(index: number): void {
    if (index > -1 && index < this.accordionTabs.length) {
      this.accordionTabs.splice(index, 1);
      ////////////this.cdr.detectChanges();
    }

    console.log('tamanho')
    console.log(this.accordionTabs.length)
    if (this.accordionTabs.length == 0) {
      this.isAddButtonDisabled = false;
    }

    this.pickListObserverService.notifyChange();
    this.setupVisibleButton();
  }
  removeComponentWhatsapp(index: number): void {
    if (index > -1 && index < this.whatsappTabs.length) {
      this.whatsappTabs.splice(index, 1);
      ////////////this.cdr.detectChanges();
    }
    this.pickListObserverService.notifyChange();
    this.setupVisibleButtonWhatsApp();
    if (this.whatsappTabs.length == 0) {
      this.isAddButtonDisabledWhats = false;
    }
  }
  removeComponentAlerts(index: number): void {
    if (index > -1 && index < this.alertsTabs.length) {
      this.alertsTabs.splice(index, 1);
      //////////this.cdr.detectChanges();
    }
    this.pickListObserverService.notifyChange();
    this.setupFilteredAlerts();
  }
  removeProduct(product: any) {
    // Defina isSelected como false antes de mover o produto de volta para a source.
    product.isSelected = false;

    // Remove o produto da lista de target.
    this.targetProducts = this.targetProducts.filter(p => p !== product);

    // Adiciona o produto de volta à lista de source.
    this.sourceProducts.push(product);
    this.pickListObserverService.notifyChange();
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
    if (this.filteredProducts$) {
      this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
        map(products =>
          products.filter(product => product.highlight).slice(0, 3)
        )
      );
    }
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
    this.cdr.detectChanges();
  }
  removeLink(index: number): void {
    // Remove o item no índice fornecido
    this.linksSelecteds.splice(index, 1);
    // Atualiza a lista para uma nova referência, garantindo que o Angular detecte a mudança
    this.linksSelecteds = [...this.linksSelecteds];
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
    this.pickListObserverService.notifyChange();
  }
  removePromotion(promotion: any) {
    // Defina isSelected como false antes de mover o produto de volta para a source.
    promotion.isSelected = false;

    // Remove o produto da lista de target.
    this.targetPromotions = this.targetPromotions.filter(p => p !== promotion);

    // Adiciona o produto de volta à lista de source.
    this.sourcePromotions.push(promotion);
    this.pickListObserverService.notifyChange();
    this.targetPromotions = [...this.targetPromotions];
    this.setupFilteredPromotions();
  }

  onSliderChange(product: Produto, event: any): void {
    if (product.interval_time) {
      // Asegura que os valores de `event.values` estejam dentro do range
      const startValue = Math.min(event.values[0], this.sliderMaxValue);
      const endValue = Math.min(event.values[1], this.sliderMaxValue);

      product.interval_time.start = this.formatSliderValue(startValue);
      product.interval_time.end = this.formatSliderValue(endValue);

      this.pickListObserverService.notifyChange();
    }
  }

  updateIntervalTime(values: number[]): void { // ddddddddddd
    console.log(this.sliderMaxValue); // Para depuração
    if (this.selectedProductForEdit && this.selectedProductForEdit.interval_time && this.selectedProductIndex !== null) {
      // Agora trata os valores como segundos para converter para o formato de tempo HH:MM:SS
      const startTime = this.secondsToTimeString(values[0]);
      const endTime = this.secondsToTimeString(values[1]);

      this.selectedProductForEdit.interval_time.start = startTime;
      this.selectedProductForEdit.interval_time.end = endTime;

      // Atualiza o produto na lista targetProducts
      //this.targetProducts[this.selectedProductIndex] = { ...this.selectedProductForEdit };

      this.debounceFunction();
      this.setupFilteredProducts();
      if (this.filteredProducts$) {
        this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
          map(products =>
            products.filter(product => product.highlight).slice(0, 3)
          )
        );
      }
      //this.targetProducts = [...this.targetProducts];
      this.setupFilteredProducts();
    }
  }
  // updateInputsFromSlider slider_time_range
  updateIntervalTime2(values: number[]): void {
    console.log(this.sliderMaxValue); // Para depuração
    if (this.selectedProductForEdit && this.selectedProductForEdit.interval_time && this.selectedProductIndex !== null) {
      // Agora trata os valores como segundos para converter para o formato de tempo HH:MM:SS
      const startTime = this.secondsToTimeString(values[0]);
      const endTime = this.secondsToTimeString(values[1]);

      this.selectedProductForEdit.interval_time.start = startTime;
      this.selectedProductForEdit.interval_time.end = endTime;

      // Atualiza o produto na lista targetProducts
      this.targetProducts[this.selectedProductIndex] = { ...this.selectedProductForEdit };
      this.setupFilteredProducts();
      if (this.filteredProducts$) {
        this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
          map(products =>
            products.filter(product => product.highlight).slice(0, 3)
          )
        );
      }
      this.pickListObserverService.notifyChange();
      this.targetProducts = [...this.targetProducts];
      this.setupFilteredProducts();
    }
  }
  secondsToTimeString(seconds: number): string {
    // Certifique-se de que está convertendo segundos para horas, minutos e segundos
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }
  minutesToTimeString(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:00`;
  }

  formatSliderValue(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    // Formato HH:MM:SS
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
  }
  showAlerts: boolean = false;
  addComponentAlerts() {
    const newTabNumber = this.alertsTabs.length + 1;
    this.alertsTabs.push({
      header: `Alerta ${newTabNumber}`,
      text: 'Alerta 1',
      font_color: '#FFFFFF',
      background: '#000000',
      define_interval: false,
      show_item: false,
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0]
    });
    this.pickListObserverService.notifyChange();
    this.cdr.detectChanges();
  }


  closeSidebarSaldo() {
    this.displaySidebarSaldo = false;
  }
  closeSidebarGeral() {
    this.displaySidebar = false;
    if (this.observer) {
      this.observer.disconnect();
    }


    this.uploadProgress = 0;
    this.uploadProgressVideo = 0;

  }
  @Input() facebookPixel: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['facebookPixel'] && changes['facebookPixel'].currentValue === true) {
      this.loadPixelsLazy(); // Chame seu método aqui
    }
  }
  openFacebookPixel() {
    this.facebookPixel = true;
    this.loadPixelsLazy();
  }
  filterValue: string = '';
  searchTerm: string = '';
  onFilterSlicks(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.toLowerCase();
  }
  filteredPixels: any[] = [];
  onFilterSlicksPixel(event: any) {
    if (!this.searchTerm.trim()) {
      this.loadPixelsLazy();
    } else {
      // Converta o termo de busca para minúsculas para busca case-insensitive
      const searchTermLower = this.searchTerm.toLowerCase();
      // Filtre os pixels baseado no termo de pesquisa
      this.filteredPixels = this.pixels.filter((pixel) => {
        return pixel.key.toLowerCase().includes(searchTermLower) ||
          pixel.pixel_id.toString().toLowerCase().includes(searchTermLower);
      });
    }

    // Atualize a contagem total de registros com o número de itens filtrados
    this.totalRecords = this.filteredPixels.length;
    // Peça para o Angular atualizar a interface
    this.cdr.detectChanges();
  }


  addPixelDialog: boolean = false;
  savePixelLoading: boolean = false;
  isEditing: boolean = false;
  openAddPixel() {
    this.formData = {
      key: '',
      pixel_id: '',
      view_events: {
        fb_pixel_view: {
          view_events: {
            view_percentage: false,
            pitch_hit: false
          }
        }
      },
      conversion_events: {
        fb_pixel_conversion: {
          conversion_events: {
            skus: [], // Array vazio para iniciar
            product_view: false,
            credit_card_purchase: false,
            pixel_purchase: {
              trigger: false,
              conversion_value: null
            },
            slip_purchase: {
              trigger: false,
              conversion_value: null
            },
            cart_abandonment: false,
            credit_card_purchase_denied: false
          }
        }
      }
    }; // Limpe o formulário ou redefina para os valores padrões
    this.isEditing = false;
    this.addPixelDialog = true;
    this.updateFormattedProducts();
  }
  selectedItems: any[] = [];
  closeFacebookPixel() {
    this.facebookPixel = false;
  }
  closeAddFacebookPixel() {
    this.loadPixelsLazy();
    this.addPixelDialog = false;

  }
  formData: any = {
    key: '',
    pixel_id: '',
    view_events: {
      fb_pixel_view: {
        view_events: {
          view_percentage: false,
          pitch_hit: false
        }
      }
    },
    conversion_events: {
      fb_pixel_conversion: {
        conversion_events: {
          skus: [], // Array vazio para iniciar
          product_view: false,
          credit_card_purchase: false,
          pixel_purchase: {
            trigger: false,
            conversion_value: null
          },
          slip_purchase: {
            trigger: false,
            conversion_value: null
          },
          cart_abandonment: false,
          credit_card_purchase_denied: false
        }
      }
    }
  };
  pixels: any[] = [];
  actionMenuModel?: any[];
  totalRecords: number = 0;
  loadingPixels: boolean = true;
  loadingPixelsTable: boolean = true;
  loadPixelsLazy(event: any = {}) {
    if (!this.facebookPixel) return;
    this.loadingPixels = true;
    this.loadingPixelsTable = true;
    let page = ((event.first ?? 0) / (event.rows ?? 10)) || 0;
    let size = event.rows ?? 10;

    this.liveShopService.listPixelLazy(this.uuid!, page, size).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        console.log(data); // Logar os dados recebidos
        this.filteredPixels = data.data;
        this.totalRecords = data.total;
        this.ngZone.run(() => {
          this.loadingPixels = false;
          this.loadingPixelsTable = false;
        });
      },
      error: (error) => {
        this.ngZone.run(() => {
          this.loadingPixels = false;
          this.loadingPixelsTable = false;
        });
        let message = '';
        this.savePixelLoading = false;  // Supõe-se que você tenha um indicador de loading

        // Manuseio de erro conforme os códigos de status HTTP
        if (error.status === 400 || error.status === 403 || error.status === 401) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        // Mostrar erro se houver uma mensagem
        if (message) {
          this.showErrorDialog(message);
        }
      }
    });
  }
  // listPixel() {
  //   this.liveShopService.list_pixel(this.uuid!).subscribe({
  //     next: (resp: any) => {
  //       this.pixels = resp; // Assumindo que resp já é o array de pixels
  //       this.filteredPixels = this.pixels;


  //       ////////////////////////
  //       this.filteredPixels.forEach(pixel => {
  //         pixel.actionMenuModel = this.getActionMenuPixel(pixel);
  //       });
  //       ////////////////////////

  //       console.log(resp);
  //     },
  //     error: (error) => {
  //       let message = '';
  //       this.savePixelLoading = false;
  //       if (error.status === 400 || error.status === 403 || error.status === 401) {
  //         message = error.error.message;
  //       } else if (error.status === 500) {
  //         message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
  //       }

  //       if (message) {
  //         this.showErrorDialog(message);
  //       }
  //     }
  //   });
  // }
  hasErrorNamePixel: boolean = false;
  hasErrorIdPixel: boolean = false;
  savePixel() {
    this.hasErrorNamePixel = false;
    this.hasErrorIdPixel = false;

    // Verifica se o nome do pixel é vazio ou nulo
    if (!this.formData.key || this.formData.key.trim() === '') {
      this.hasErrorNamePixel = true;
    }

    // Verifica se o ID do pixel é vazio ou nulo
    if (!this.formData.pixel_id || this.formData.pixel_id.trim() === '') {
      this.hasErrorIdPixel = true;
    }

    // Se houver algum erro, interrompe a execução do método
    if (this.hasErrorNamePixel || this.hasErrorIdPixel) {
      return;
    }
    this.savePixelLoading = true;


    if (this.issueForm) {
      this.issueForm = this.fb.group({
        key: this.formData.key,
        pixel_id: this.formData.pixel_id,
        category: 'facebook',
        view_events: {
          fb_pixel_view: {
            view_events: this.formData.view_events.fb_pixel_view.view_events
          }
        },
        conversion_events: {
          fb_pixel_conversion: {
            conversion_events: this.formData.conversion_events.fb_pixel_conversion.conversion_events
          }
        }
      });

      console.log('form')
      //console.log(JSON.stringify(this.issueForm.value, null, 2));
      this.liveShopService.create_pixel(this.issueForm.value, this.uuid!).pipe(takeUntil(this.destroy$)).subscribe({
        next: (resp) => {
          this.savePixelLoading = false;
          this.addPixelDialog = false;
          console.log(resp);
          this.messageService.add({
            severity: 'success',
            summary: 'Pixel Criado',
            detail: 'O pixel foi criado com sucesso!'
          });
          this.loadPixelsLazy();
        },
        error: (error) => {
          let message = '';
          this.savePixelLoading = false;
          if (error.status === 400 || error.status === 403 || error.status === 401) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          if (message) {
            this.showErrorDialog(message);
          }
        }
      });
    }


  }

  pixel_id?: number;
  updatePixel() {
    this.hasErrorNamePixel = false;
    this.hasErrorIdPixel = false;

    // Verifica se o nome do pixel é vazio ou nulo
    if (!this.formData.key || this.formData.key.trim() === '') {
      this.hasErrorNamePixel = true;
    }

    // Verifica se o ID do pixel é vazio ou nulo
    if (!this.formData.pixel_id || this.formData.pixel_id.trim() === '') {
      this.hasErrorIdPixel = true;
    }

    // Se houver algum erro, interrompe a execução do método
    if (this.hasErrorNamePixel || this.hasErrorIdPixel) {
      return;
    }
    this.savePixelLoading = true;


    if (this.issueForm) {
      this.issueForm = this.fb.group({
        key: this.formData.key,
        pixel_id: this.formData.pixel_id,
        view_events: {
          fb_pixel_view: {
            view_events: this.formData.view_events.fb_pixel_view.view_events
          }
        },
        conversion_events: {
          fb_pixel_conversion: {
            conversion_events: this.formData.conversion_events.fb_pixel_conversion.conversion_events
          }
        }
      });

      console.log('form')
      //console.log(JSON.stringify(this.issueForm.value, null, 2));
      this.liveShopService.update_pixel(this.issueForm.value, this.pixel_id!).pipe(takeUntil(this.destroy$)).subscribe({
        next: (resp) => {
          this.savePixelLoading = false;
          this.addPixelDialog = false;
          console.log(resp);
          this.messageService.add({
            severity: 'success',
            summary: 'Pixel Editado',
            detail: 'O pixel foi editado com sucesso!'
          });
          this.loadPixelsLazy();
        },
        error: (error) => {
          let message = '';
          this.savePixelLoading = false;
          if (error.status === 400 || error.status === 403 || error.status === 401) {
            message = error.error.message;
          } else if (error.status === 500) {
            message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
          }

          if (message) {
            this.showErrorDialog(message);
          }
        }
      });
    }


  }

  getActionMenuPixel(pixel: any) {
    return [
      { label: 'Editar', icon: 'pi pi-file-edit', command: () => { console.log('Editar clicked'); this.editPixel(pixel.id); } },
      { label: 'Deletar', icon: 'pi pi-trash', command: () => { console.log('Deletar clicked'); this.confirmDeletionPixel(pixel.id); } },
    ];
  }


  editPixel(pixel_id: any) {
    this.updateFormattedProducts();
    console.log(pixel_id);
    //this.facebookPixel = false;
    this.pixel_id = pixel_id;
    // list_pixel
    this.liveShopService.detail_pixel(pixel_id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp: any) => {
        console.log(resp);

        this.isEditing = true; // Defina o estado para edição

        this.formData = this.convertPixelDataToFormData(resp); // Assumindo que essa função ajusta os dados para o seu formulário
        this.cdr.detectChanges();
        console.log(this.formData)
        this.addPixelDialog = true;

      },
      error: (error) => {
        let message = '';
        this.savePixelLoading = false;
        if (error.status === 400 || error.status === 403 || error.status === 401) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });
  }
  convertPixelDataToFormData(pixelData: any) {
    return {
      key: pixelData.key || '',
      pixel_id: pixelData.pixel_id || '',
      view_events: {
        fb_pixel_view: {
          view_events: {
            view_percentage: pixelData.value_2?.view_events?.view_percentage || false,
            pitch_hit: pixelData.value_2?.view_events?.pitch_hit || false,
          }
        }
      },
      conversion_events: {
        fb_pixel_conversion: {
          conversion_events: {
            skus: pixelData.value_3?.conversion_events?.skus,
            product_view: pixelData.value_3?.conversion_events?.product_view || false,
            credit_card_purchase: pixelData.value_3?.conversion_events?.credit_card_purchase || false,
            pixel_purchase: {
              trigger: pixelData.value_3?.conversion_events?.pixel_purchase?.trigger || false,
              conversion_value: pixelData.value_3?.conversion_events?.pixel_purchase?.conversion_value || null,
            },
            slip_purchase: {
              trigger: pixelData.value_3?.conversion_events?.slip_purchase?.trigger || false,
              conversion_value: pixelData.value_3?.conversion_events?.slip_purchase?.conversion_value || null,
            },
            cart_abandonment: pixelData.value_3?.conversion_events?.cart_abandonment || false,
            credit_card_purchase_denied: pixelData.value_3?.conversion_events?.credit_card_purchase_denied || false,
          }
        }
      }
    };
  }


  confirmDeletionPixel(pixel_id: any) {
    this.facebookPixel = false;
    this.confirmationService.confirm({
      message: 'Você realmente deseja remover este pixel?',
      header: 'Confirmação de Exclusão',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletarPixel(pixel_id);
      },
      reject: () => {
        this.facebookPixel = true;
      }
    });
  }
  deletarPixel(pixel_id: any): void {
    this.liveShopService.deletar_pixel(pixel_id).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        // Remova o pixel da lista de pixels filtrados
        this.filteredPixels = this.filteredPixels.filter(pixel => pixel.id !== pixel_id);
        this.totalRecords = this.totalRecords - 1; // Atualize o total de registros
        this.facebookPixel = true;
        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Pixel Deletado',
          detail: 'O pixel foi deletado com sucesso!'
        });
      },
      error: (err) => {
        // Exiba uma mensagem de erro
        this.messageService.add({
          severity: 'error',
          summary: 'Erro',
          detail: 'Erro ao deletar pixel.'
        });
      }
    });
  }




  qrCodeUrl?: string;
  url_share?: string = "www.google.com";
  compartilharLink() {
    if (navigator.share) {
      navigator.share({
        title: 'Compartilhar Link',
        url: this.url_share
      }).then(() => {
        console.log('Link compartilhado com sucesso!');
      }).catch(err => {
        console.error('Erro ao compartilhar:', err);
      });
    } else {
      console.error('A Web Share API não é suportada neste navegador.');
    }
  }
  copiarLink() {
    if (this.url_share) {
      navigator.clipboard.writeText(this.url_share).then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Link copiado',
          detail: 'Link copiado com sucesso!'
        });
        console.log('Link copiado com sucesso!');
      }).catch(err => {
        console.error('Erro ao copiar o link:', err);
      });
    } else {
      console.error('Erro: O link está vazio ou indefinido.');
    }
  }
  simuladorDialog: boolean = false;
  simulateLive() {
    this.simuladorDialog = true;
  }
  closeLive() {
    this.simuladorDialog = false;
  }

  selectedIntegration: any = null;
  integrations: any[] = [
    { name: 'Inline', key: 'I' },
    { name: 'Popup', key: 'P' },
  ];

  script_share: string = '<script type="text/javascript" src="https://shopper.slicksell.com.br/gp-shopper.js" defer></script>';
  widget_share: string = '<script type="text/javascript" src="https://shopper.slicksell.com.br/gp-shopper.js" defer></script>';
  copiarScript() {
    if (this.script_share) {
      navigator.clipboard.writeText(this.script_share).then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Script copiado',
          detail: 'Script copiado com sucesso!'
        });
      }).catch(err => {
        console.error('Erro ao copiar o Script:', err);
      });
    } else {
      console.error('Erro: O Script está vazio ou indefinido.');
    }
  }
  copiarWidget() {
    if (this.widget_share) {
      navigator.clipboard.writeText(this.widget_share).then(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Script copiado',
          detail: 'Script copiado com sucesso!'
        });
      }).catch(err => {
        console.error('Erro ao copiar o Script:', err);
      });
    } else {
      console.error('Erro: O Script está vazio ou indefinido.');
    }
  }


  slickTitle?: string = '';
  slickDesc?: string = '';
  hasError1: boolean = false;
  hasError2: boolean = false;
  hasError3: boolean = false;
  onInputChange() {
    this.hasError1 = false;
  }
  slickTypes = [
    { label: 'Shorts', value: 'shorts' },
    // { label: 'Live', value: 'live' }
  ];
  typeLive: boolean = false;
  //selectedSlickType?: SlickType;
  selectedSlickType: SlickType = { label: '', value: '' };

  onTypeChange(event: any) {
    console.log('Evento completo:', event);
    console.log('Valor selecionado:', event.value.value);
    this.typeLive = event.value.value === 'shorts';

    if (this.selectedSlickType) {
      this.hasError2 = false;
    } else {
      this.hasError2 = true;
    }
  }
  disabledDates: Date[] = [];
  dataSelecionada?: Date;
  onDateChange(event: Date | null) {
    if (event === null) {
      console.log(null); // Data não está selecionada
      return null;
    } else {
      // Formate a data ao receber uma nova seleção
      //const formattedDate = formatDate(event, 'yyyy-MM-dd HH:mm:ss', this.locale);
      if (this.dataSelecionada) {
        const formattedDate = formatDate(this.dataSelecionada, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        console.log(formattedDate); // "2023-05-20 02:26:03" ou outra data formatada
        return formattedDate;
      } else {
        console.log('Nenhuma data selecionada');
        return null;
      }



    }
  }
  tags = [
    { label: 'Novo', value: 'novo' },
    { label: 'Atual', value: 'atual' },
    { label: 'Antigo', value: 'antigo' }
  ];
  selectedTags: string[] = [];
  tagsString: string = '';
  updateTagsString() {
    this.tagsString = this.selectedTags.join(',');
  }
  publicarAgora: boolean = true;
  armazenarEvento: boolean = false;
  resultSimulatePrice?: string;
  targetTime?: any[];

  viewers!: string;
  averageViewTimeValue!: number;
  hour: string = '00';
  minute: string = '40';
  second: string = '00';
  durationLive?: string;
  //selectedTime!: number;
  selectedTime!: { label: string; value: number };
  liveTime!: string;
  hasErrorTime: boolean = false;
  hasErrorViewers: boolean = false;
  hasErrorSelectedTime: boolean = false;
  loadingSimulate: boolean = false;
  convertTimeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  convertTimeToMinutesPitch(time: string): number {
    // Verifica se o tempo está no novo formato sem ":" (por exemplo, "1430")
    if (time.length === 4) {
      // Extrai as horas e os minutos da string
      const hours = Number(time.substring(0, 2));
      const minutes = Number(time.substring(2, 4));
      return hours * 60 + minutes;
    } else {
      // Se o tempo ainda estiver no formato antigo (por exemplo, "14:30"), mantém a lógica antiga
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }
  }

  simular() {

    // Inicia sem erros
    this.hasErrorTime = false;
    this.hasErrorViewers = false;
    this.hasErrorSelectedTime = false;

    // Verifica se o tempo de duração é nulo ou vazio
    if (!this.liveTime || this.liveTime.trim() === '') {
      this.hasErrorTime = true;
      console.error('Erro: O tempo de duração é inválido.');
    }

    // Verifica se o número de espectadores é nulo ou vazio
    if (!this.viewers || this.viewers.trim() === '') {
      this.hasErrorViewers = true;
      console.error('Erro: O número de espectadores é inválido.');
    }

    // Verifica se o tempo médio de permanência é nulo ou não selecionado
    if (!this.selectedTime || Object.keys(this.selectedTime).length === 0) {
      this.hasErrorSelectedTime = true;
      console.error('Erro: O tempo médio de permanência não foi selecionado.');
    }

    // Se houver algum erro, interrompe a execução do método aqui
    if (this.hasErrorTime || this.hasErrorViewers || this.hasErrorSelectedTime) {
      return;
    }
    this.loadingSimulate = true;
    const liveTimeInMinutes = this.convertTimeToMinutesPitch(this.liveTime);

    console.log('em minutos: ' + liveTimeInMinutes);
    console.log('antes: ' + this.liveTime);



    this.liveShopService.liveSimulator(liveTimeInMinutes, 720, this.viewers, this.selectedTime.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: (resp: any) => {
        this.resultSimulatePrice = resp.price;
        this.loadingSimulate = false;
      },
      error: (error) => {
        this.loadingSimulate = false;
        let message = '';
        this.savePixelLoading = false;
        if (error.status === 400 || error.status === 403 || error.status === 401) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });
  }


  convertToMinutes(time: string): number {
    if (!time.includes(':')) {
      if (time.length === 4) {
        return (parseInt(time.substring(0, 2)) * 60) + parseInt(time.substring(2, 4));
      } else {
        // Handle unexpected formats or throw an error
        return NaN;
      }
    }
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60) + minutes;
  }
  uploadProgress: number = 0;
  uploadProgressVideo: number = 0;
  videoFile: File | null = null;
  imageFile: File | null = null;
  uploadProgressBar: boolean = false;
  loadingBtn: boolean = false;
  uploadOn: boolean = false;
  imageSelected: boolean = false;
  imagePreviewUrl: string | null = null;
  showImageDeleteButton: boolean = false;
  removeImage(event: MouseEvent): void {
    event.stopPropagation();
    this.imageSelected = false;
    this.imagePreviewUrl = null;
    this.showImageDeleteButton = false;
  }
  myImageUploadMethod(event: any): void {
    // Implemente a lógica para o upload da imagem
  }
  erroTamanhoImagem: boolean = false;
  erroTipoImagem: boolean = false;
  onImageSelect(event: any): void {
    this.erroTamanhoImagem = false;
    this.erroTipoImagem = false;
    if (event.files && event.files.length > 0) {
      this.imageFile = event.files[0]; // Atribuir o arquivo selecionado à variável imageFile

      // Verificar o tamanho do arquivo (máximo 1MB)
      if (this.imageFile && this.imageFile.size > 1048576) {
        this.erroTamanhoImagem = true;
        this.imageFile = null;
        this.imageSelected = false;
        return;
      }


      // Verificar o tipo do arquivo (webp, png, jpg, jpeg)
      const fileType = this.imageFile?.type;
      if (fileType && !['image/webp', 'image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
        this.erroTipoImagem = true;
        this.imageFile = null;
        this.imageSelected = false;
        return;
      }


      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSelected = true;
        this.imagePreviewUrl = e.target.result; // Atribui a URL da imagem para visualização
      };
      if (this.imageFile) {
        reader.readAsDataURL(this.imageFile);
      }

    } else {
      this.imageSelected = false;
      this.imageFile = null; // Resetar imageFile se nenhum arquivo for selecionado
    }
  }
  videoPreviewUrl: string | null = null;
  invoiceSelected: boolean = false;
  removeVideo(event: MouseEvent): void {
    event.stopPropagation();
    this.videoPreviewUrl = '';
    this.invoiceSelected = false;
  }
  myUploadMethod(event: any) {
    console.log('chamou myUploadMethod');
    const formData: FormData = new FormData();

    // Vou assumir que você quer enviar o primeiro arquivo selecionado
    const file = event.files[0];
    formData.append('mime', file, file.name);

    // O campo 'folder' é fixo e deve ser enviado junto, como você mostrou no Postman
    formData.append('folder', 'thumbnail');


  }
  fileExtension?: string;
  duration: number = 0;
  closeDialogDuration() {
    this.openDialogDuration = false;
  }
  zeraDialogDuration() {
    this.openDialogDuration = false;

    this.accordionTabs.forEach(tab => {
      if (tab.interval_time) {
        const startInSeconds = this.convertTimeToSeconds(tab.interval_time.start);
        const endInSeconds = this.convertTimeToSeconds(tab.interval_time.end);

        if (this.duration > startInSeconds || this.duration > endInSeconds) {
          tab.interval_time.start = "00:00:00";
          tab.interval_time.end = "00:00:00";
          tab.slider_time_range = [0, 0]; // Se necessário, atualizar também o slider_time_range
        }
      }
    });
    this.whatsappTabs.forEach(tab => {
      if (tab.interval_time) {
        const startInSeconds = this.convertTimeToSeconds(tab.interval_time.start);
        const endInSeconds = this.convertTimeToSeconds(tab.interval_time.end);

        if (this.duration > startInSeconds || this.duration > endInSeconds) {
          tab.interval_time.start = "00:00:00";
          tab.interval_time.end = "00:00:00";
          tab.slider_time_range = [0, 0]; // Se necessário, atualizar também o slider_time_range
        }
      }
    });
    this.alertsTabs.forEach(tab => {
      if (tab.interval_time) {
        const startInSeconds = this.convertTimeToSeconds(tab.interval_time.start);
        const endInSeconds = this.convertTimeToSeconds(tab.interval_time.end);

        if (this.duration > startInSeconds || this.duration > endInSeconds) {
          tab.interval_time.start = "00:00:00";
          tab.interval_time.end = "00:00:00";
          tab.slider_time_range = [0, 0]; // Se necessário, atualizar também o slider_time_range
        }
      }
    });
    this.linksSelecteds.forEach(tab => {
      if (tab.interval_time) {
        const startInSeconds = this.convertTimeToSeconds(tab.interval_time.start);
        const endInSeconds = this.convertTimeToSeconds(tab.interval_time.end);

        if (this.duration > startInSeconds || this.duration > endInSeconds) {
          tab.interval_time.start = "00:00:00";
          tab.interval_time.end = "00:00:00";
          tab.slider_time_range = [0, 0]; // Se necessário, atualizar também o slider_time_range
        }
      }
    });
    this.debounceFunction();
  }

  convertTimeToSeconds2(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  }
  openDialogDuration: boolean = false;
  hasSizeVideoError: boolean = false;
  hasTypeVideoError: boolean = false;
  onFileSelect(event: any): void {
    this.invoiceSelected = true;
    this.hasError3 = false;
    this.hasTypeVideoError = false;
    this.hasSizeVideoError = false;
    if (event.files && event.files[0]) {
      this.videoFile = event.files[0]; // Atribuir o arquivo selecionado à variável videoFile fileExtension
      if (this.videoFile) {
        this.videoPreviewUrl = URL.createObjectURL(this.videoFile);

        // Verificar o tamanho do arquivo (máximo 400 MB)
        if (this.videoFile.size > 400 * 1024 * 1024) {
          this.hasSizeVideoError = true;
          this.videoPreviewUrl = null;
          this.invoiceSelected = false;
          this.videoFile = null; // Resetar videoFile se o tamanho exceder o limite
          return;
        }

        // Verificar o tipo do arquivo
        const allowedTypes = ['video/mp4', 'video/quicktime', 'audio/mpeg', 'audio/wav', 'video/webm', 'video/vnd.avi', 'video/x-ms-wmv', 'video/x-matroska', 'video/x-flv', 'video/mpeg', 'video/3gpp', 'video/x-m4v'];
        if (!allowedTypes.includes(this.videoFile.type)) {
          this.hasTypeVideoError = true;
          this.videoPreviewUrl = null;
          this.invoiceSelected = false;
          this.videoFile = null; // Resetar videoFile se o tipo não for permitido
          return;
        }
        this.videoPreviewUrl = URL.createObjectURL(this.videoFile);
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        const contentType: string = this.videoFile.type;
        this.fileExtension = this.getFileExtension(contentType);

        videoElement.onloadedmetadata = () => {
          window.URL.revokeObjectURL(videoElement.src); // Limpe a URL após o uso
          this.duration = videoElement.duration;
          console.log(`A duração do vídeo é: ${this.duration} segundos.`);

          if (this.mediaDuration) {
            if (this.mediaDuration > this.duration) {
              this.openDialogDuration = true;
            }
          }


        };

        videoElement.onerror = () => {
          console.error('Erro ao carregar o vídeo.');
        };

        videoElement.src = this.videoPreviewUrl;
        videoElement.load();

        this.invoiceSelected = true;
      }

    } else {
      this.videoPreviewUrl = null;
      this.invoiceSelected = false;
      this.videoFile = null; // Resetar videoFile se nenhum arquivo for selecionado
    }
  }
  getFileExtension(contentType: string): string {
    // Mapear o tipo MIME para a extensão do arquivo
    const extensionMap: { [key: string]: string } = {
      'video/mp4': 'mp4',
      'video/quicktime': 'mov',
      // Adicione mais mapeamentos de tipos MIME conforme necessário
    };

    // Obter a extensão correspondente ao tipo MIME
    return extensionMap[contentType] || '';
  }
  url_slick_maps_1!: string;
  url_slick_maps_2!: string;
  loadingBtnSlicks: boolean = false;
  valuesMaps: string[] | undefined;
  loadDataChipsMaps() {
    this.liveShopService.listSlickMaps(this.uuid!).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        if (res && res.length > 0) {
          // Considerando que todos os itens têm o mesmo 'type', usamos o primeiro item como referência
          const referenceType = res[0].type; // Ou algum outro critério lógico

          if (referenceType === 1) {
            this.selectedIntegration = this.integrations.find(i => i.key === 'P'); // Assumindo que 'P' corresponde ao 'type' 1
          } else {
            this.selectedIntegration = this.integrations.find(i => i.key !== 'P'); // Assumindo que qualquer coisa diferente de 'P' corresponde ao 'type' 2
          }

          this.valuesMaps = res.map((item: SlickMaps) => item.url);
          if (this.valuesMaps && this.valuesMaps?.length > 0) {
            this.showClearSlickMaps = true;
          } else {
            this.showClearSlickMaps = false;
          }
        } else {
          this.selectedIntegration = this.integrations[1];
        }
      },
      error: (error) => {
        // Trate o erro conforme necessário
      }
    });
  }
  saveSlickMaps() {
    console.log("chamou salvar");
    this.loadingBtnSlicks = true;
    this.issueForm = this.fb.group({
      asset_uniq_id: [this.uuid],
      type: [this.selectedIntegration.key == 'P' ? 1 : 2],
      url: [this.valuesMaps],
      status: [1],
    });
    this.liveShopService.createSlickMaps(this.issueForm.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.loadingBtnSlicks = false;
        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Slick Maps Salvo',
          detail: 'O slick maps foi salvo com sucesso!'
        });
        this.displayDialogSidebar = false;
        // Router
      },
      error: (error) => {
        this.loadingBtnSlicks = false;
        let message = '';
        this.displayDialogSidebar = false;
        this.loadingBtn = false;
        this.uploadOn = false;
        if (error.status === 400 || error.status === 403 || error.status === 401) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });
  }

  generateUID() {
    return uuidv4();
  }
  media_folder: string | null = null;
  saveEditSlick() {
    console.log('clicou em salvar/editar');
    this.loadingBtn = true;
    this.uploadSave();
  }
  async removeServiceWorkerAndCaches(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        // Força a atualização do SW (se houver um novo)
        const registrations = await navigator.serviceWorker.getRegistrations();
        // for (const registration of registrations) {
        //   registration.update();
        // }

        // Desregistra o service worker
        for (const registration of registrations) {
          const success = await registration.unregister();
          if (success) {
            console.log('Service worker unregistered successfully');
          }
        }

        // Limpa os caches
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
          await caches.delete(name);
        }
        console.log('All caches deleted successfully');

        // Força o Bypass for network, removendo o controller do SW
        if (navigator.serviceWorker.controller) {
          console.log('Service worker still controlling, applying bypass...');
          navigator.serviceWorker.controller.postMessage({ action: 'skipWaiting' });
        }

      } catch (error) {
        console.error('Error while unregistering service worker or clearing caches:', error);
      }
    }
  }

  //async uploadSave() {}
  async uploadSave() {
    this.uploadProgressVideo = 0;
    this.uploadProgress = 0;
    console.log('entrou em salvar/editar');

    if (!this.selectedSlickType) {
      this.hasError2 = true;
    }
    console.log('entrou 1');
    if (!this.slickTitle || this.slickTitle.trim() === '') {
      this.hasError1 = true;
    }
    console.log('entrou 2');

    if (!this.hasError1 && !this.hasError2) {
      console.log('entrou 3');
      if (this.selectedSlickType?.value === 'shorts' && !this.invoiceSelected && (this.videoPreviewUrl == null || this.videoPreviewUrl == "")) {
        this.hasError3 = true;
        return;
      }

      // Remover Service Worker
      // await this.removeServiceWorkerAndCaches();  // Garante que o SW seja removido antes do upload

      // Inicia os uploads e salva
      this.loadingBtn = true;
      const uploadStatus = {
        video: false,
        image: false,
      };

      // Upload do vídeo
      if (this.selectedSlickType?.value === 'shorts' && this.videoFile) {
        console.log('tem vídeo');
        const contentType = this.getContentType(this.fileExtension!) ?? '.mp4';

        try {
          const videoUrl = await this.uploadFile(this.videoFile, `media/${this.media_folder}/${this.generateUID()}.${this.fileExtension ?? '.mp4'}`, contentType, 1);
          if (!videoUrl.includes('https://d2434dp583bttd.cloudfront.net/public/')) {
            this.mediaSrc = `https://d2434dp583bttd.cloudfront.net/public/${videoUrl}`;
          } else {
            this.mediaSrc = videoUrl;
          }
          uploadStatus.video = true;
        } catch (error: any) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro no upload do vídeo: ' + error.message,
          });
          this.loadingBtn = false;
          return;
        }
      } else {
        uploadStatus.video = true;
      }


      // Upload da imagem
      if (this.imageFile) {
        try {
          const imageUrl = await this.uploadFile(this.imageFile, `media/${this.media_folder}/${this.generateUID()}.jpeg`, 'image/jpeg', 2);
          if (!imageUrl.includes('https://d2434dp583bttd.cloudfront.net/public/')) {
            this.mediaThumbnail = `https://d2434dp583bttd.cloudfront.net/public/${imageUrl}`;
          } else {
            this.mediaThumbnail = imageUrl;
          }
          console.log('entrou 5.5');
          uploadStatus.image = true;
        } catch (error: any) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro no upload da imagem: ' + error.message,
          });
          this.loadingBtn = false;
          return;
        }
      } else {
        console.log('entrou 5.6');
        uploadStatus.image = true;
      }

      // Verifica se ambos os uploads foram concluídos
      if (uploadStatus.video && uploadStatus.image) {
        this.saveSlicks();
        this.loadingBtn = false;
      }
    } else {
      console.log('entrou erro');
      if (this.selectedSlickType?.value === 'shorts' && !this.invoiceSelected) {
        this.hasError3 = true;
      }
      return;
    }
  }

  async uploadFile(file: File, storageKey: string, contentTypeSend: string, bar: number): Promise<string> {
    console.log('Chamou uploadFile');

    try {
      const uploadTask = uploadData({
        key: storageKey,
        data: file,
        options: {
          accessLevel: 'guest',
          contentType: contentTypeSend,
          onProgress: (progressEvent) => {
            const progress = (progressEvent.transferredBytes / (progressEvent.totalBytes ?? 1)) * 100;
            let roundedProgress = Math.round(progress);

            console.log(`Progresso atual: ${roundedProgress}%`);
            if (bar === 2) {
              this.uploadProgress = roundedProgress;
            } else if (bar === 1) {
              this.uploadProgressVideo = roundedProgress;
            }
          }
        }
      });

      // Aguarda o resultado do upload
      await uploadTask.result;

      console.log('Upload concluído com sucesso.');
      return storageKey;  // Retorna o storageKey como resultado

    } catch (error) {
      console.error('Erro no upload:', error);
      throw error;  // Lança o erro para ser tratado onde a função for chamada
    }
  }

  getContentType(fileExtension: string): string {
    const contentTypeMap: { [key: string]: string } = {
      'mp4': 'video/mp4',
      'mov': 'video/quicktime',
      'mp3': 'audio/mpeg',
      'wav': 'audio/wav',
      'webm': 'video/webm',
      'avi': 'video/vnd.avi',
      'wmv': 'video/x-ms-wmv',
      'mkv': 'video/x-matroska',
      'flv': 'video/x-flv',
      'mpeg': 'video/mpeg',
      'mpg': 'video/mpeg',
      '3gp': 'video/3gpp',
      'm4v': 'video/x-m4v',
    };
    return contentTypeMap[fileExtension.toLowerCase()] || ''; // Ajuste para normalizar a extensão para minúsculas
  }





  checkUploadsAndSave(uploadStatus: any) {
    // Verifica se ambos os uploads (vídeo e imagem) foram concluídos
    if (uploadStatus.video && uploadStatus.image) {
      console.log('Todos os uploads foram concluídos');
      // Aguarda até que this.uploadProgressVideo atinja 100%
      if (uploadStatus.video) {
        console.log('Todos os uploads foram concluídos');
        const interval = setInterval(() => {

          if (this.selectedSlickType?.value == 'shorts') {
            if (this.uploadProgressVideo === 100) {
              console.log('Todos os uploads foram concluídos');
              clearInterval(interval); // Para o intervalo
              console.log('Upload de vídeo concluído');
              this.saveSlicks(); // Chama o método para salvar após a conclusão dos uploads
              this.loadingBtn = false; // Atualiza o estado do botão, se necessário
            }
          } else {
            if (this.uploadProgress === 100) {
              console.log('Todos os uploads foram concluídos');
              clearInterval(interval); // Para o intervalo
              console.log('Upload de vídeo concluído');
              this.saveSlicks(); // Chama o método para salvar após a conclusão dos uploads
              this.loadingBtn = false; // Atualiza o estado do botão, se necessário
            }
          }
          console.log(this.uploadProgressVideo)

        }, 100); // Verifica a cada segundo
      } else {
        this.saveSlicks();
        this.loadingBtn = false;
      }

    } else {
      this.saveSlicks();
    }
  }

  getForm() {
    return
  }

  saveSlicks() {

    this.saveAsset(1);

    // this.liveShopService.updateAsset(this.issueForm.value, this.asset_id).pipe(takeUntil(this.destroy$)).subscribe({
    //   next: (res) => {
    //     this.loadingBtn = false;
    //     this.uploadOn = false;
    //     console.log("PPPPPPP")
    //     this.loadProducts();
    //     if (this.player) {
    //       this.player.src({ src: this.replaceDomain(this.mediaSrc) || '' });
    //     }
    //     //this.unregisterSwService.registerServiceWorker();
    //     // Exiba uma mensagem de sucesso
    //     this.messageService.add({
    //       severity: 'success',
    //       summary: 'Slick Salvo',
    //       detail: 'O slick foi salvo com sucesso!'
    //     });
    //     // this.displayDialogSidebar = false;
    //     // this.uuid = this.route.snapshot.paramMap.get('uuid') ?? '';

    //     // this.videoUrl = this.replaceDomain(this.mediaSrc) || '';
    //     // if (!this.typeLive) {
    //     //   this.initVideoPlayer();
    //     // }
    //     // Router
    //   },
    //   error: (error) => {
    //     let message = '';
    //     this.displayDialogSidebar = false;
    //     this.loadingBtn = false;
    //     this.uploadOn = false;
    //     if (error.status === 400 || error.status === 403 || error.status === 401) {
    //       message = error.error.message;
    //     } else if (error.status === 500) {
    //       message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
    //     }

    //     if (message) {
    //       this.showErrorDialog(message);
    //     }
    //   }
    // });
  }
  displayDialog: boolean = false;
  displayDialogSidebar: boolean = false;
  resetFormCreator() {
    this.slickTitle = undefined;
    this.slickDesc = undefined;
    //this.selectedSlickType = undefined;
    this.selectedTags = [];
    this.publicarAgora = true;
    this.armazenarEvento = false;
    this.dataSelecionada = undefined;
    this.imageSelected = false;
    this.imageFile = null;
    this.videoPreviewUrl = null;
    this.invoiceSelected = false;
    this.typeLive = false;
    this.videoFile = null;
  }
  validateTime(event: KeyboardEvent) {
    const controlKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight'];
    if (controlKeys.includes(event.key)) {
      return;
    }

    // Assegura que event.target é um HTMLInputElement
    const input = event.target as HTMLInputElement | null;

    if (input) {
      // Usa o operador de coalescência nula para garantir que temos um número
      const selectionStart = input.selectionStart ?? 0;
      const selectionEnd = input.selectionEnd ?? 0;

      const regex = new RegExp("^[0-9]{0,2}[:]?[0-9]{0,2}$");
      const currentValue = input.value;
      const nextValue = currentValue.substring(0, selectionStart) +
        event.key +
        currentValue.substring(selectionEnd);

      if (!regex.test(nextValue)) {
        //event.preventDefault();
      }
    }
  }

  showSlickClearFunction() {
    if (this.valuesMaps && this.valuesMaps?.length > 0) {
      this.showClearSlickMaps = true;
    } else {
      this.showClearSlickMaps = false;
    }
  }

  showClearSlickMaps: boolean = false;
  clearSlickMaps() {
    this.valuesMaps = [];
    this.showClearSlickMaps = false;
    this.liveShopService.clearSlickMaps(this.uuid!).pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        this.loadingBtnSlicks = false;
        // Exiba uma mensagem de sucesso
        this.messageService.add({
          severity: 'success',
          summary: 'Slick Maps Limpo',
          detail: 'O slick maps foi limpo com sucesso!'
        });
        this.displayDialogSidebar = false;
        // Router
      },
      error: (error) => {
        this.loadingBtnSlicks = false;
        let message = '';
        this.displayDialogSidebar = false;
        this.loadingBtn = false;
        this.uploadOn = false;
        if (error.status === 400 || error.status === 403 || error.status === 401 || error.status === 404) {
          message = error.error.message;
        } else if (error.status === 500) {
          message = "Ops... Algo de errado aconteceu. Tente novamente mais tarde.";
        }

        if (message) {
          this.showErrorDialog(message);
        }
      }
    });

  }


  selectedProductForEdit: Produto = {
    id: 0,
    sku: 0,
    name: '',
    status: 0,
    inputQty: 0,
    position: 0,
    price: '0',
    onsale_price: '',
    price_exchange: '',
    thumbnail: '',
    isSelected: false,
    highlight: false,
    define_interval: false,
    show_slider: false,
    interval_time: { start: "00:00:00", end: "00:00:00" },
    comprar_font_color: '#ffffff',
    comprar_bg_color: '#000000',
    adicionar_font_color: '#000000',
    adicionar_bg_color: '#ffffff',
  };
  private debounceTimer: any = null;

  public currentlyEditingTab: AccordionTab | null = null;

  setCurrentTabAndDebounce(tab: AccordionTab) {
    this.currentlyEditingTab = tab;

    this.stateManagementService.updateAccordionColors(this.currentlyEditingTab?.font_color_custom ?? '#000000', this.currentlyEditingTab?.bg_color_custom ?? '#FFFFFF');

    this.debounceFunction();
  }
  debounceFunction() {


    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.pickListObserverService.notifyChange();
    }, 750);
    //this.checkIntervalsForOverlap(this.accordionTabs, this.whatsappTabs);
  }

  updateColors() {
    // Atualiza as cores imediatamente para a reatividade da UI
    if (this.selectedProductForEdit && this.selectedProductForEdit.id) {
      this.stateManagementService.updateProductColors(this.selectedProductForEdit.id, {
        comprarFontColor: this.selectedProductForEdit.comprar_font_color ?? '#FFFFFF',
        comprarBgColor: this.selectedProductForEdit.comprar_bg_color ?? '#000000',
        adicionarFontColor: this.selectedProductForEdit.adicionar_font_color ?? '#000000',
        adicionarBgColor: this.selectedProductForEdit.adicionar_bg_color ?? '#FFFFFF',
      });
    }

    // Debounce a chamada de notificação de mudança
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.pickListObserverService.notifyChange();
    }, 750); // Debounce de 500ms; ajuste conforme necessário
  }



  /// reorganiza postion em produtos
  private prevTargetProducts: Produto[] = [];
  ngDoCheck() {
    if (!this.arraysEqual(this.prevTargetProducts, this.targetProducts)) {
      // A ordem dos produtos no targetProducts mudou
      this.updateProductPositions(); // Atualiza as posições
      this.prevTargetProducts = [...this.targetProducts]; // Atualiza a cópia
      this.targetProducts = [...this.targetProducts]; // Atualiza a cópia
      this.setupFilteredProducts();
      if (this.filteredProducts$) {
        this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
          map(products =>
            products.filter(product => product.highlight).slice(0, 3)
          )
        );
      }
      this.targetProducts = [...this.targetProducts];
      this.setupFilteredProducts();
    }
  }
  private arraysEqual(a: any[], b: any[]) {
    return (
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }
  isDragging: boolean = false;
  /// reorganiza postion em produtos
  @ViewChild('progressContainer') progressContainer?: ElementRef;
  @ViewChild('parallelProgress') parallelProgress?: ElementRef;
  @ViewChild('originalProgress') originalProgress?: ElementRef;
  @ViewChild('marker') marker?: ElementRef<HTMLDivElement>;


  listenersAdded: boolean = false;
  private dragEvents = new Subject<MouseEvent | TouchEvent>();
  initPlayPause: boolean = false;
  ////////////////////////////
  onDragStart(event: MouseEvent | TouchEvent): void {
    //event.preventDefault(); // Impede comportamentos padrão, como seleção de texto ou eventos de clique.
    this.isDragging = true; // Marca que o arraste começou
    this.initPlayPause = !this.player.paused();
    //this.player.pause();
    this.moveMarker(event); // Move a marker para a posição inicial

    // Adiciona event listeners para movimentos e término do arraste
    if (this.progressContainer && !this.listenersAdded) {
      this.progressContainer.nativeElement.addEventListener('mousemove', this.boundOnDragging);
      this.progressContainer.nativeElement.addEventListener('mouseup', this.boundOnDragEnd);
      this.progressContainer.nativeElement.addEventListener('touchmove', this.boundOnDragging);
      this.progressContainer.nativeElement.addEventListener('touchend', this.boundOnDragEnd);
      this.listenersAdded = true;
      document.addEventListener('mousemove', this.boundOnDragging);
      document.addEventListener('mouseup', this.boundOnDragEnd);
      document.addEventListener('touchmove', this.boundOnDragging);
      document.addEventListener('touchend', this.boundOnDragEnd);
    }

    const wrapper = this.progressContainerWrapper?.nativeElement;
    // Definir os listeners do sprite diretamente no onDragging e onDragEnd
    const mouseMoveListener = (event: MouseEvent) => {
      this.onDragging(event); // Executar o arrasto
      this.updateThumbnail(event.clientX); // Atualizar a posição da miniatura
    };

    const touchMoveListener = (event: TouchEvent) => {
      this.onDragging(event); // Executar o arrasto
      this.updateThumbnail(event.touches[0].clientX); // Atualizar a posição da miniatura
    };

    const touchEndListener = () => {
      if (this.thumbnailPreview && this.thumbnailPreview.nativeElement) {
        this.thumbnailPreview.nativeElement.style.display = 'none';
      }
    };

    const mouseLeaveListener = () => {
      if (this.thumbnailPreview && this.thumbnailPreview.nativeElement) {
        this.thumbnailPreview.nativeElement.style.display = 'none';
      }
    };


    // Adicionar listeners ao progress bar e à janela para finalizar
    wrapper?.addEventListener('mousemove', mouseMoveListener);
    wrapper?.addEventListener('touchmove', touchMoveListener, { passive: true });
    wrapper?.addEventListener('mouseup', this.onDragEnd.bind(this));
    wrapper?.addEventListener('touchend', this.onDragEnd.bind(this));
    wrapper?.addEventListener('touchcancel', this.onDragEnd.bind(this));
    wrapper?.addEventListener('mouseleave', mouseLeaveListener);

    // Armazenar os listeners para remoção futura
    this.spriteListeners = { touchMoveListener, mouseMoveListener, mouseLeaveListener, touchEndListener };
  }
  private spriteListeners: {
    touchMoveListener?: (event: TouchEvent) => void,
    touchEndListener?: () => void,
    mouseMoveListener?: (event: MouseEvent) => void,
    mouseLeaveListener?: () => void
  } = {};
  moveMarker(event: MouseEvent | TouchEvent): void {
    console.log('chamou moveMarker: ' + this.isDragging);
    // if (this.progressContainer) {
    //   this.updateProgress(event);
    //   console.log('teste de dragging: ' + this.isDragging)
    // }
  }

  //////////////////////////
  isSeeking: boolean = false;
  displayedTime: string = '';
  // Atualize isto com o valor real ou estimado
  formatDuration2(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    let timeString = '';

    if (hours > 0) {
      // Formata como 00h00m00s
      timeString += `${hours.toString().padStart(2, '0')}h`;
    }
    if (hours > 0 || minutes > 0) {
      // Inclui minutos se houver horas ou minutos
      timeString += `${minutes.toString().padStart(2, '0')}m`;
    }
    // Sempre inclui segundos
    timeString += `${remainingSeconds.toString().padStart(2, '0')}s`;

    return timeString;
  }
  updateDisplayedTime(time: number) {
    this.displayedTime = this.formatDuration2(time);  // Assumindo que você tenha uma função para formatar o tempo em um formato legível
    //this.displayedTime = time.toString();  // Assumindo que você tenha uma função para formatar o tempo em um formato legível
  }
  getClientXFromEvent(event: MouseEvent | TouchEvent): number | null {
    if (event instanceof MouseEvent) {
      return event.clientX;
    } else if (this.isTouchEvent(event)) {
      let clientX: number | null = null; // Inicializado como null
      if (event.type === 'touchend' || event.type === 'touchcancel') {
        if (event.changedTouches && event.changedTouches.length > 0) {
          clientX = event.changedTouches[0].clientX; // Usar changedTouches em touchend/touchcancel
        } else {
          console.warn('TouchEvent (touchend/touchcancel) sem clientX válido');
        }
      } else if (event.touches && event.touches.length > 0) {
        clientX = event.touches[0].clientX; // Usar touches para eventos normais de touch
      }
      return clientX; // Agora garantimos que `clientX` será null ou um número
    }
    return null;
  }
  //////////////////////////
  // Eventos de arraste - arrastando

  updateProgressBarWidth() {
    if (this.videoUploadContainerInside && this.videoUploadContainerInside.nativeElement &&
      this.btnsRightSup && this.btnsRightSup.nativeElement &&
      this.progressContainerWrapper && this.progressContainerWrapper.nativeElement) {

      //this.cdr.detectChanges();  // Assegura que as mudanças sejam detectadas antes de calcular as larguras

      // Obter a largura do container de upload de vídeo
      const videoContainerWidth = this.videoUploadContainerInside.nativeElement.offsetWidth;

      // Obter a largura dos botões à direita
      const rightWidth = this.btnsRightSup.nativeElement.offsetWidth;

      // Calcular totalWidth como a diferença entre a largura do videoContainer e a largura dos botões
      //const totalWidth = videoContainerWidth - rightWidth;
      const totalWidth = videoContainerWidth - 0;

      // Ajustar a margem direita para ser igual à largura do btnsRightSup
      this.progressContainerWrapper.nativeElement.style.marginRight = `${0}px`;

      // A margem adicional da classe ml-3
      const marginLeft = 12;  // Valor em pixels para ml-3, se 1rem = 16px

      // Subtrair a margem do cálculo da nova largura
      //const newWidth = totalWidth - marginLeft;
      const newWidth = totalWidth - 0;
      console.log('newWidth');
      console.log(newWidth);

      // Aplicar a nova largura ao progressContainerWrapper
      this.progressContainerWrapper.nativeElement.style.width = `${100}%`;
    }
  }
  @ViewChild('thumbnailContainer') thumbnailContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('timeDisplay') timeDisplay!: ElementRef;
  private animationFrameId?: number;
  onDragging(event: MouseEvent | TouchEvent): void {
    if (!this.isDragging) return;
    //this.player.pause();

    const progressContainer = this.progressContainerWrapper?.nativeElement;
    if (!progressContainer) return;
    /////////////////////////////////
    const rect = progressContainer.getBoundingClientRect();
    let clientX: number | null = this.getClientXFromEvent(event);
    if (clientX === null) return;

    const clickX = clientX - rect.left;
    const seekTime = (clickX / rect.width) * this.player.duration();

    /////////////////////////////////

    // update da barra de progress
    if (this.progressContainer) {
      if (this.progressContainer) {
        if (this.isMobile) {
          this.progressContainer.nativeElement.classList.add('touch-active');
        }

      }
      this.setOpacity(1, 0);
      const rect = this.progressContainer.nativeElement.getBoundingClientRect();
      let clientX: number | null = null;
      if (this.isTouchEvent(event) && event.touches && event.touches.length > 0) {
        clientX = event.touches[0].clientX;
      } else if (event instanceof MouseEvent) {
        clientX = event.clientX;
      }

      if (clientX === null) {
        return;
      }
      const clickX = clientX - rect.left;
      const containerWidth = rect.width;
      const seekPercentage = clickX / containerWidth;
      let progressPercentage;
      if (this.fakeBar) {
        // Se estiver usando a barra de progresso paralela (fake)
        if (seekPercentage < 0.3) {
          progressPercentage = (seekPercentage / 0.3) * 70;
        } else {
          progressPercentage = 70 + ((seekPercentage - 0.3) / 0.7) * 30;
        }
        if (this.parallelProgress) {
          this.parallelProgress.nativeElement.style.width = `${progressPercentage}%`;
        }
      } else {
        // Barra de progresso original
        progressPercentage = seekPercentage * 100;
        if (this.originalProgress) {
          this.originalProgress.nativeElement.style.width = `${progressPercentage}%`;
        }
      }
      //this.updateProgress(event);

      // Atualiza a posição da barra de progresso e outras alterações no próximo frame de animação
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
      }
      // Atualiza o tempo mostrado
      this.animationFrameId = requestAnimationFrame(() => {
        this.updateDisplayedTime(seekTime);

        if (this.progressContainer) {
          if (this.isMobile) {
            this.progressContainer.nativeElement.classList.add('ss-g-progress-container-expanded');
          }
        }
        if (this.timeDisplay) {
          this.timeDisplay.nativeElement.style.display = 'block';
        }
        this.setOpacity(1, 0);
      });
    }
  }
  updateThumbnail(clientX: number): void {
    const progressBar = this.progressContainerWrapper?.nativeElement;
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;

    if (!progressBar || !thumbnailContainer) return;

    const rect = progressBar.getBoundingClientRect();
    const boundedClientX = Math.max(rect.left, Math.min(clientX, rect.right));
    const relativeX = boundedClientX - rect.left;

    const spriteTime = (relativeX / rect.width) * this.player.duration();
    this.displayedTime = this.formatDuration2(spriteTime);

    // Posicionar o contêiner de miniaturas
    thumbnailContainer.style.display = 'flex';
    const thumbnailWidth = thumbnailContainer.offsetWidth;

    // Define o espaço de margem mínima (ex: 10px)
    const margin = 28;

    // Centralizar e garantir que não ultrapasse os limites com margem
    const positionLeft = Math.min(
      Math.max(relativeX - thumbnailWidth / 2, margin), // Não ultrapassa o lado esquerdo (adiciona margem)
      rect.width - thumbnailWidth - margin // Não ultrapassa o lado direito (adiciona margem)
    );


    thumbnailContainer.style.left = `${positionLeft}px`;
    thumbnailContainer.style.top = this.valSelect1Video.value === 1 ? '-40px' : '-20px';

    // Atualizar o sprite
    const thumbnailPreview = this.thumbnailPreview?.nativeElement;
    if (thumbnailPreview) {
      const thumbnailIndex = Math.floor(spriteTime);
      const imgCloud = 'https://raw.githubusercontent.com/GiriAakula/samuel-miller-task/master/openvideo.png';

      let backgroundPosX: number;
      let backgroundSize: string;

      if (this.valSelect1Video.value === 1) {
        // Dimensões do quadro
        const frameWidth = 120; // Largura de cada quadro (1600px dividido por 10 quadros)
        const frameHeight = 190; // Altura de cada quadro (fixa)

        // Calcula a posição do quadro no sprite
        backgroundPosX = thumbnailIndex * -frameWidth; // Deslocamento horizontal do quadro
        backgroundSize = `${1200}px ${950}px`; // Tamanho total do sprite (mantém as dimensões originais)

        // Aplica as dimensões ao contêiner de miniaturas
        thumbnailPreview.style.width = `${frameWidth}px`; // Define a largura de um único quadro
        thumbnailPreview.style.height = `${frameHeight}px`; // Define a altura de um único quadro
      } else {
        // Ajustes para outras proporções
        backgroundPosX = thumbnailIndex * -160;
        backgroundSize = '1600px 450px';


      }
      thumbnailPreview.style.display = 'block';
      thumbnailPreview.style.backgroundImage = `url(${imgCloud})`;
      thumbnailPreview.style.backgroundPosition = `${backgroundPosX}px 0px`;
      thumbnailPreview.style.backgroundSize = backgroundSize;

    }
  }
  hoverListeners: { mouseEnterListener?: (event: MouseEvent) => void, mouseMoveListener?: (event: MouseEvent) => void, mouseLeaveListener?: (event: MouseEvent) => void } = {};
  // Eventos de arraste - terminar o arraste
  onDragEnd(event: MouseEvent | TouchEvent): void {
    if (this.isDragging) {
      this.isDragging = false;
      const progressContainer = this.progressContainerWrapper?.nativeElement;

      if (this.progressContainer) {
        if (this.isMobile) {
          this.progressContainer.nativeElement.classList.remove('touch-active');
        }

      }

      // Limpar a animação do `requestAnimationFrame`
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = undefined;
      }
      this.setOpacity(0, 1);
      this.updateProgress(event);
      if (this.progressContainer) {
        this.progressContainer.nativeElement.removeEventListener('mousemove', this.boundOnDragging);
        this.progressContainer.nativeElement.removeEventListener('mouseup', this.boundOnDragEnd);
        this.progressContainer.nativeElement.removeEventListener('touchmove', this.boundOnDragging);
        this.progressContainer.nativeElement.removeEventListener('touchend', this.boundOnDragEnd);
        this.listenersAdded = false;
        document.removeEventListener('mousemove', this.boundOnDragging);
        document.removeEventListener('mouseup', this.boundOnDragEnd);
        document.removeEventListener('touchmove', this.boundOnDragging);
        document.removeEventListener('touchend', this.boundOnDragEnd);

        //this.player.play();

        if (this.videoElementRef && this.videoElementRef.nativeElement) {
          this.showPlayPauseButton = true; // Sempre mostra o botão enquanto interage.
        }
        if (this.initPlayPause) {
          this.player.play();
        }

      }

      this.removeSpriteListeners();
      this.removeHoverListeners();
      const thumbnailContainer = this.thumbnailContainer?.nativeElement;
      if (thumbnailContainer) {
        thumbnailContainer.style.display = 'none'; // Ocultar manualmente a miniatura
      }
      if (this.timeDisplay) {
        this.timeDisplay.nativeElement.style.display = 'none';
      }
    }
  }
  removeHoverListeners(): void {
    const progressBar = this.progressContainerWrapper?.nativeElement;

    if (!progressBar || !this.hoverListeners) return;

    const { mouseEnterListener, mouseMoveListener, mouseLeaveListener } = this.hoverListeners;

    // Remover eventos de hover do progress bar apenas se eles existirem
    if (mouseEnterListener) progressBar.removeEventListener('mouseenter', mouseEnterListener);
    if (mouseMoveListener) progressBar.removeEventListener('mousemove', mouseMoveListener);
    if (mouseLeaveListener) progressBar.removeEventListener('mouseleave', mouseLeaveListener);
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;
    if (thumbnailContainer) {
      thumbnailContainer.style.display = 'none'; // Ocultar manualmente a miniatura
    }
    // Limpar os listeners armazenados
    this.hoverListeners = {};

  }
  removeSpriteListeners(): void {
    const wrapper = this.progressContainerWrapper?.nativeElement;
    if (!wrapper) return;

    const { touchMoveListener, mouseMoveListener, mouseLeaveListener, touchEndListener } = this.spriteListeners;

    if (touchMoveListener) wrapper.removeEventListener('touchmove', touchMoveListener);
    if (mouseMoveListener) wrapper.removeEventListener('mousemove', mouseMoveListener);
    if (mouseLeaveListener) wrapper.removeEventListener('mouseleave', mouseLeaveListener);
    if (touchEndListener) {
      wrapper.removeEventListener('touchend', touchEndListener);
      wrapper.removeEventListener('touchcancel', touchEndListener);
    }

    // Garantir que a miniatura seja removida
    const thumbnailPreview = this.thumbnailPreview?.nativeElement;
    if (thumbnailPreview) {
      thumbnailPreview.style.display = 'none'; // Ocultar manualmente a miniatura
    }
    const thumbnailContainer = this.thumbnailContainer?.nativeElement;
    if (thumbnailContainer) {
      thumbnailContainer.style.display = 'none'; // Ocultar manualmente a miniatura
    }

    this.spriteListeners = {};
  }
  private isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
  }
  // Atualiza a reprodução do vídeo quando a marker é solta
  updateProgress(event: MouseEvent | TouchEvent): void {
    if (!this.player) return;
    this.ngZone.runOutsideAngular(() => {
      if (this.progressContainer) {
        const rect = this.progressContainer.nativeElement.getBoundingClientRect();
        let clientX: number | null = null;
        if (this.isTouchEvent(event)) {
          if (event.type === 'touchend' || event.type === 'touchcancel') {
            if (event.changedTouches && event.changedTouches.length > 0) {
              clientX = event.changedTouches[0].clientX; // Usar changedTouches em touchend
            } else {
              console.warn('TouchEvent (touchend) sem clientX válido');
            }
          } else if (event.touches && event.touches.length > 0) {
            clientX = event.touches[0].clientX;
          } else {
            console.warn('TouchEvent sem clientX válido');
          }
        } else if (event instanceof MouseEvent) {
          clientX = event.clientX;
        }

        if (clientX === null) return;
        const clickX = clientX - rect.left;
        const seekTime = (clickX / rect.width) * this.player.duration();
        const containerWidth = rect.width;
        const seekPercentage = clickX / containerWidth;


        this.player.currentTime(seekTime);

        // update da barra de progress
        let progressPercentage;
        if (this.fakeBar) {
          // Se estiver usando a barra de progresso paralela (fake)
          if (seekPercentage < 0.3) {
            progressPercentage = (seekPercentage / 0.3) * 70;
          } else {
            progressPercentage = 70 + ((seekPercentage - 0.3) / 0.7) * 30;
          }
          if (this.parallelProgress) {
            this.parallelProgress.nativeElement.style.width = `${progressPercentage}%`;
          }
        } else {
          // Barra de progresso original
          progressPercentage = seekPercentage * 100;
          if (this.originalProgress) {
            this.originalProgress.nativeElement.style.width = `${progressPercentage}%`;
          }
        }

        if (this.marker) {
          this.marker.nativeElement.style.left = `${clickX}px`;
        }
        //
      }
    });

  }
  ////////////////////////////

  @ViewChild('progressContainerWrapper') progressContainerWrapper?: ElementRef<HTMLDivElement>;

  @ViewChild('rewindButton') rewindButton?: ElementRef;
  @ViewChild('playPauseButton') playPauseButton?: ElementRef;
  @ViewChild('forwardButton') forwardButton?: ElementRef;
  @ViewChild('videoElement') videoElement?: ElementRef<HTMLVideoElement>;

  // Método para retroceder
  rewind(): void {
    if (this.player) {
      let newTime = this.player.currentTime() - 10;
      newTime = Math.max(0, newTime); // Não vai abaixo de 0
      this.player.currentTime(newTime);
    }
  }
  forward(): void {
    if (this.player) {
      let newTime = this.player.currentTime() + 10;
      newTime = Math.min(newTime, this.player.duration()); // Não excede a duração total
      this.player.currentTime(newTime);
    }
  }
  // Método para alternar play/pause
  //isPaused:boolean = false;


  // Método para avançar

  progressBarColor: string = '#FF6347'; // Cor padrão da barra de progresso
  updatePlayPauseColor(value: string | object): void {
    const color = typeof value === 'string' ? value : '#FF0000';
    this.playPauseColor = color;
    this.stateManagementService.updatePlayerControlsColors(this.playPauseColor, this.progressBarColor);
  }

  updateProgressBarColor(value: string | object): void {
    const color = typeof value === 'string' ? value : ''; // Use um valor padrão ou faça uma conversão conforme necessário
    this.stateManagementService.updatePlayerControlsColors(this.playPauseColor, this.progressBarColor);
  }


  checkIntervalsForOverlap(customTabs: AccordionTab[], whatsappTabs: WhatsAppTab[]) {
    const allIntervals: Interval[] = [];

    customTabs.forEach(tab => {
      if (tab.define_interval && tab.interval_time && !(tab.interval_time.start === "00:00:00" && tab.interval_time.end === "00:00:00")) {
        allIntervals.push({
          start: this.convertTimeToMilliseconds(tab.interval_time.start),
          end: this.convertTimeToMilliseconds(tab.interval_time.end),
          source: 'Custom'
        });
      }
    });

    whatsappTabs.forEach(tab => {
      if (tab.define_interval && tab.interval_time && !(tab.interval_time.start === "00:00:00" && tab.interval_time.end === "00:00:00")) {
        allIntervals.push({
          start: this.convertTimeToMilliseconds(tab.interval_time.start),
          end: this.convertTimeToMilliseconds(tab.interval_time.end),
          source: 'WhatsApp'
        });
      }
    });

    for (let i = 0; i < allIntervals.length; i++) {
      for (let j = i + 1; j < allIntervals.length; j++) {
        // Verifica se ambos os intervalos não são ambos "00:00:00"
        if (!(allIntervals[i].start === 0 && allIntervals[i].end === 0 && allIntervals[j].start === 0 && allIntervals[j].end === 0) && this.intervalsOverlap(allIntervals[i], allIntervals[j])) {
          console.warn(`Overlap detected between ${allIntervals[i].source} and ${allIntervals[j].source} intervals.`);
          // Emita seu aviso aqui
        }
      }
    }
  }


  convertTimeToMilliseconds(timeStr: string): number {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return ((hours * 60 + minutes) * 60 + seconds) * 1000;
  }
  intervalsOverlap(intervalA: Interval, intervalB: Interval): boolean {
    // Retorna true se houver sobreposição
    return intervalA.start < intervalB.end && intervalB.start < intervalA.end;
  }



  convertMinutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:00`;
  }
  getSliderValues(index: number): number[] {
    const tab = this.accordionTabs[index];
    if (tab && tab.interval_time) {
      return [
        this.convertTimeToMinutes(tab.interval_time.start),
        this.convertTimeToMinutes(tab.interval_time.end)
      ];
    }
    return [0, 1440]; // Um valor padrão que representa o início e o fim do dia, ajuste conforme necessário
  }


  /// conditins to custom
  numberToTimeString(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    return [hours, minutes, seconds]
      .map(val => val < 10 ? '0' + val : val)
      .join(':');
  }
  timeStringToNumber(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  // Atualiza os valores do slider com base nos inputs de tempo
  updateSliderFromInput(tab: any, which: 'start' | 'end', value: string) {
    const timeValue = this.timeStringToNumber(value);

    if (!tab.interval_time_range) {
      tab.interval_time_range = [0, 0];
    }

    if (which === 'start') {
      tab.interval_time_range[0] = timeValue;
    } else {
      tab.interval_time_range[1] = timeValue;
    }
  }

  // Atualiza os inputs de tempo com base nos valores do slider
  updateInputsFromSlider(tab: any, event: any) {


    console.log('tab antes de tudo');
    console.log(tab);

    //return;

    if (!tab.interval_time) {
      tab.interval_time = { start: "00:00:00", end: "00:00:00" };
    }


    console.log('antes de converter');
    console.log(event.values[0]);
    console.log('converter');
    console.log(this.numberToTimeString(event.values[0]));


    tab.interval_time.start = this.numberToTimeString(event.values[0]);
    tab.interval_time.end = this.numberToTimeString(event.values[1]);


    console.log('nova tab (stringified):', JSON.stringify(tab, null, 2));


    this.debounceFunction();
  }
  /// conditins to custom
  handleChanges(index: number, type: 'text' | 'fontColor' | 'backgroundColor', value: string) {
    // Aqui você chama o debounceFunction conforme necessário
    // Mas também prepara e envia as mudanças para o serviço
    let change: Partial<AlertChange> = { index };
    switch (type) {
      case 'text':
        change.text = value;
        break;
      case 'fontColor':
        change.fontColor = value;
        break;
      case 'backgroundColor':
        change.backgroundColor = value;
        break;
    }
    this.stateManagementService.emitAlertChange(change as AlertChange);
    // Assumindo que debounceFunction não precisa de argumentos específicos
    // ou você pode ajustá-lo conforme necessário
    this.debounceFunction();
  }
  configSwiper: SwiperOptions = {
    // Exemplo de configuração
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 10,
    keyboard: {
      enabled: true,
    },
    pagination: false,
    navigation: false,
  };
  configSwiperSecond: SwiperOptions = {
    loop: false,
    slidesPerView: 1.2, // Exemplo, se você quiser mostrar mais slides de uma vez
    spaceBetween: 10,
    pagination: false,
    navigation: false,
  };
  configSwiperThird: SwiperOptions = {
    slidesPerView: 'auto',
    loop: false,
    spaceBetween: 10,
    pagination: false,
    navigation: false,
  };


  // onHighlightChange(productToHighlight: Produto) {
  //   if (productToHighlight.highlight) {
  //     // Conta quantos produtos estão destacados
  //     const highlightedCount = this.targetProducts.filter(p => p.highlight).length;

  //     // Se mais de três produtos estão destacados, desmarca o primeiro destacado
  //     if (highlightedCount > 3) {
  //       // Encontra o primeiro produto destacado
  //       const firstHighlighted = this.targetProducts.find(p => p.highlight);
  //       // Garante que um produto foi encontrado e não é o atualmente alterado
  //       if (firstHighlighted && firstHighlighted !== productToHighlight) {
  //         // Desmarca o `highlight`
  //         firstHighlighted.highlight = false;
  //       }
  //     }
  //   }

  //   // Não esqueça de atualizar a lista de produtos se estiver usando detecção de mudança ou imutabilidade
  //   this.targetProducts = [...this.targetProducts];
  // }
  onHighlightChange(productToHighlight: Produto) {
    if (productToHighlight.highlight) {
      // Conta quantos produtos estão destacados
      const highlightedProducts = this.targetProducts.filter(p => p.highlight);
      console.log('produtos com hilight true');
      console.log(highlightedProducts.length);
      // Se mais de três produtos estão destacados
      if (highlightedProducts.length > 3) {
        // Encontra o primeiro produto destacado que não é o produto atualmente alterado
        // Assumindo que `this.targetProducts` está ordenada de modo que o "mais antigo" seja o primeiro
        const firstHighlightedNotCurrent = highlightedProducts.find(p => p !== productToHighlight);

        // Garante que um produto foi encontrado
        if (firstHighlightedNotCurrent) {
          // Desmarca o `highlight`
          firstHighlightedNotCurrent.highlight = false;
        }
      }
      this.targetProducts = [...this.targetProducts];
    }

    // Atualiza a UI ou estado do componente se necessário
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
    if (this.filteredProducts$) {
      this.highlightedProductsLimited$ = this.filteredProducts$.pipe(
        map(products =>
          products.filter(product => product.highlight).slice(0, 3)
        )
      );
    }
    this.targetProducts = [...this.targetProducts];
    this.setupFilteredProducts();
  }

  // onHighlightChange(product: Produto): void {
  //   this.pickListObserverService.notifyChange();
  //   // Lógica para tratar mudança no switch "Destacar"
  // }
  onShowProductChange(p: Produto) {

  }
  updateShowItem(p: Produto) {
    p.show_item = p.show_item == true ? true : false;
    this.targetProducts = [...this.targetProducts];
    //this.targetProducts = this.targetProducts.filter(p => p.show_item);
  }
  showControls: boolean = false;
  formattedProducts: any[] = [];
  updateFormattedProducts(): void {

    console.log('target')
    console.log(this.targetProducts)


    this.formattedProducts = this.targetProducts.map(product => ({
      label: product.name,
      value: product.id  // Ou simplesmente passe o objeto inteiro se preferir: value: product
    }));

    console.log(this.formattedProducts)
  }



  showCTAs: boolean = true;
  showAllCTAs() {

  }

  convertToHHMMSS(minutesValue: number): string {
    // Converte minutos para horas, minutos e segundos
    let hours = Math.floor(minutesValue / 60);
    let minutes = Math.floor(minutesValue % 60);
    let seconds = Math.floor((minutesValue - hours * 60 - minutes) * 60);

    // Formata para 2 dígitos
    const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

    // Retorna no formato HH:MM:SS
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  }

  toggleShowCTAs(): void {
    this.showCTAs = !this.showCTAs;
    this.showAllCTAs(); // Chama a função que manipula a mudança
    //this.adjustVideoMargin();
    this.updateItemsDock();


    this.resizeObserverBreakPoints = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        this.applySwiperStyles(width);
        this.applySwiperStyles2(width);
        this.applySwiperStyles3(width);
      }
    });

    if (this.gridContainer) {
      this.resizeObserverBreakPoints.observe(this.gridContainer.nativeElement);
    }
  }

  handleButtonClick(event: MouseEvent) {
    event.stopPropagation();
  }



  isControlKey(event: KeyboardEvent): boolean {
    // Lista de teclas de controle que não produzem caracteres e podem ser permitidas
    const controlKeys = [
      'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
      'Backspace', 'Delete', 'Tab', 'End', 'Home', 'Enter'
    ];
    return controlKeys.includes(event.key);
  }

  validateTimeSimulator(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos.

    // Limita a string a 4 caracteres numéricos para HHmm.
    value = value.substring(0, 4);

    // Formata a string como 'HH:mm' se for longa o suficiente.
    if (value.length >= 4) {
      // Separa horas e minutos.
      const hours = parseInt(value.substring(0, 2), 10);
      const minutes = parseInt(value.substring(2, 4), 10);

      // Valida horas e minutos. Ajustado para o intervalo correto das horas até 23.
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        value = `${value.substring(0, 2)}:${value.substring(2)}`;
      } else {
        // Se horas ou minutos não são válidos, mantém o valor como está, mas marca como inválido se necessário.
        value = input.value; // Mantém o valor atual para permitir correção manual pelo usuário.
      }
    } else if (value.length > 2) {
      // Se apenas parte do horário foi inserido, insere ':' após os dois primeiros dígitos.
      value = `${value.substring(0, 2)}:${value.substring(2)}`;
    }

    // Atualiza o valor do campo e o modelo Angular (ngModel) com o valor corrigido.
    input.value = value;
    this.liveTime = value;
  }


  onModelChange(value: string) {
    // Verifica se o valor é numérico e se não termina com um sinal de porcentagem
    if (value && !isNaN(+value) && !value.endsWith('%')) {
      // Atualiza o valor do modelo para incluir o sinal de porcentagem
      this.formData.conversion_events.fb_pixel_conversion.conversion_events.slip_purchase.conversion_value = `${value}%`;
    }
  }
  validateNumber(event: KeyboardEvent, maxLength: number) {
    const pattern = /[0-9]/;
    const input = event.target as HTMLInputElement;

    // Checa se a tecla pressionada é um número e se atende ao limite de caracteres
    if (!pattern.test(event.key) || (input.value.length >= maxLength && !this.isControlKey(event))) {
      // Se a tecla pressionada não é um número ou excede o limite de comprimento, previne a ação padrão.
      //event.preventDefault();
    }
  }
  validateNumberX(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const input = event.target as HTMLInputElement;

    // Checa se a tecla pressionada é um número e se atende ao limite de caracteres
    if (!pattern.test(event.key)) {
      // Se a tecla pressionada não é um número ou excede o limite de comprimento, previne a ação padrão.
      //event.preventDefault();
    }
  }

  @ViewChild('menu') menu?: MenuItem;
  @ViewChild('actionDiv') actionDiv?: Menu;
  toggleMenu(event: MouseEvent, rowIndex: number) {
    // Captura o elemento do botão clicado
    const actionButton = event.target as HTMLElement;

    // Identifica o elemento do menu relacionado usando o rowIndex ou outro identificador
    const menuId = `actionMenu${rowIndex}`;
    const menuElement = document.getElementById(menuId);

    if (actionButton && menuElement) {
      const buttonRect = actionButton.getBoundingClientRect();

      // Calcula a nova posição para o menu. Aqui, estamos posicionando o menu logo abaixo do botão.
      // Você pode ajustar isso conforme necessário para sua UI.
      const topPosition = buttonRect.bottom + window.scrollY;
      const leftPosition = buttonRect.left + window.scrollX;

      // Aplica a posição calculada ao menu. Você pode precisar ajustar isto para trabalhar com seu layout e estilos.
      menuElement.style.position = 'absolute';
      menuElement.style.top = `${topPosition}px`;
      menuElement.style.left = `${leftPosition}px`;

      // Garante que o menu é visível e posicionado corretamente
    }
  }
  checkPiP(visibleButton: any): void { // handleLinkClick
    // Impede que o evento de clique padrão aconteça imediatamente. 
    if (this.videoElementRef) {
      const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;

      // Se o PiP já estiver ativo, abre o link diretamente.
      if (document.pictureInPictureElement) {
        this.openLink(visibleButton.redirect_url);
      } else {
        // Se o PiP não estiver ativo, tenta entrar no PiP e depois abre o link.
        if (this.player.requestPictureInPicture) {
          this.player.requestPictureInPicture()
            .then(() => {
              console.log('PiP ativado, agora abrindo o link');
              // PiP foi ativado, agora tenta abrir o link.
              this.openLink(visibleButton.redirect_url);
            });
        }
      }
    }
  }

  // A função para abrir o link foi extraída para ser reutilizada.
  openLink(url: string) {
    console.log('abrindo url: ' + url);
    window.open('https://' + url, '_blank');
  }

  // PIP
  isPiPActive: boolean = false;
  togglePiP() {
    if (document.pictureInPictureElement) {
      // Sai do PiP
      this.player.exitPictureInPicture().then(() => {
        this.isPiPActive = false; // Atualiza quando o PiP é fechado programaticamente
      });
    } else {
      // Entra no PiP
      this.player.requestPictureInPicture().then(() => {
        this.isPiPActive = true; // Atualiza quando o PiP é aberto
      });
    }
  }


  @ViewChild('volumeSlider', { static: false }) volumeSlider!: ElementRef;
  volumeValue: number = 0;
  updateVolumeSlider(volume: number) {
    const volumeSlider = this.volumeSlider.nativeElement;
    volumeSlider.value = volume * 100; // Converte o volume para a escala de 0 a 100
  }
  showMute: boolean = true;
  toggleMute() {
    this.showMute = false;
    const isMuted = this.player.muted();
    this.player.muted(!isMuted);

    if (isMuted) {
      // Se estava mudo e vai desmutar, restaura o volume anterior.
      this.player.volume(this.lastVolume);
      this.volumeValue = this.lastVolume * 100; // Atualiza o ngModel da slider.
    } else {
      // Se não estava mudo e vai mutar, guarda o último volume e zera.
      this.lastVolume = this.player.volume();
      this.player.volume(0);
      this.volumeValue = 0; // Atualiza o ngModel da slider.
    }
  }
  setVolume(event: any) {
    const volume = event.target.value / 100;
    this.volumeValue = event.target.value;
    this.player.volume(volume);
    this.lastVolume = volume;
    this.saveVolume(volume);

    // Apenas desmuta o vídeo se o volume estiver acima de 0.
    this.player.muted(false);
    if (volume == 0) {
      this.player.muted(true);
    }
  }

  showVolumeControl() {
    this.volumeControlVisible = true;
    clearTimeout(this.volumeControlTimeout); // Limpa qualquer timeout existente antes de definir um novo
  }

  hideVolumeControl() {
    this.volumeControlTimeout = setTimeout(() => {
      this.volumeControlVisible = false;
    }, 2000); // Aguarda 3 segundos para esconder a barra de volume
  }
  volumeControlVisible: boolean = false;
  volumeControlTimeout: any;


  // setVolume(event: any) {
  //   const volume = event.target.value / 100;
  //   this.volumeValue = event.target.value; // Atualiza o ngModel diretamente com o valor do evento.
  //   this.player.volume(volume);
  //   this.lastVolume = volume;
  //   this.saveVolume(volume);

  //   // Desmuta o vídeo se o volume for ajustado para mais que 0.
  //   if (volume > 0 && this.player.muted()) {
  //     this.player.muted(false);
  //   }
  // }

  loadVolume() {
    // Garante que o volume seja carregado e aplicado ao iniciar o componente.
    const volume = parseFloat(localStorage.getItem('volume') || '0.5');
    this.lastVolume = volume;
    this.player.volume(volume);
    this.volumeValue = volume * 100; // Atualiza o ngModel da slider.
  }

  saveVolume(volume: number) {
    if (volume > 0) {
      localStorage.setItem('volume', volume.toString());
    }

  }
  isVideoMuted(): boolean {
    return this.player && this.player.muted();
  }



  private subscriptions: Subscription[] = [];
  formatTimeBar(seconds: number): string {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }


  sliderValuePitch: number = 0;
  updateTimeValue() {
    // Converte o valor do slider (em segundos) para o formato desejado (00h00m00s)
    let hours = Math.floor(this.sliderValuePitch / 3600);
    let minutes = Math.floor((this.sliderValuePitch - (hours * 3600)) / 60);
    let seconds = this.sliderValuePitch - (hours * 3600) - (minutes * 60);


    console.log('upd ' + this.sliderValuePitch);

    this.timePitch = `${this.pad(hours)}h${this.pad(minutes)}m${this.pad(seconds)}s`;

    this.debounceFunction();
  }

  // Função auxiliar para garantir que os valores de tempo sejam sempre dois dígitos
  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  convertTimeToSeconds(timeString: string): number {
    if (timeString !== undefined && timeString !== null) {
      const timeParts = timeString.match(/(\d+)[hms]/g) || [];
      let seconds = 0;

      timeParts.forEach(part => {
        const unit = part.charAt(part.length - 1);
        const value = parseInt(part.slice(0, -1), 10);

        if (unit === 'h') {
          seconds += value * 3600;
        } else if (unit === 'm') {
          seconds += value * 60;
        } else if (unit === 's') {
          seconds += value;
        }
      });

      return seconds;
    }
    return 0;
  }

  onlyNumber(event: any) {
    // Expressão regular que corresponde a qualquer coisa que não seja um número
    const nonNumericRegex = /[^0-9]/g;

    // Verifica se o valor contém algo que não seja um número e o remove
    if (nonNumericRegex.test(event.target.value)) {
      event.target.value = event.target.value.replace(nonNumericRegex, '');
      // Atualiza o valor da variável ligada ao ngModel manualmente, se necessário
      this.viewers = event.target.value;
    }
  }

  limitInput(event: any) {
    const value = event.target.value;
    if (value.length > 2) {
      event.target.value = value.slice(0, 2);
      // Atualize o valor no seu modelo, se necessário
      // Isso pode ser necessário porque a atualização direta do valor do input
      // pode não refletir automaticamente no modelo Angular.
    }
  }
  preventE(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  }

  correctMinutes(value: string): void {
    if (value.length === 4) { // Garante que o valor tem o comprimento esperado
      const hours = value.substring(0, 2);
      let minutes = parseInt(value.substring(2, 4), 10);

      // Corrige os minutos para 59 se estiverem acima desse valor
      if (minutes > 59) {
        minutes = 59;
        // Atualiza o modelo com as horas originais e os minutos corrigidos
        this.liveTime = hours + (minutes < 10 ? '0' : '') + minutes.toString();
      }
    }
  }

  moveItemOnTop(event: any) {
    event.item.moveOnTop();
  }

  onDialogPixelClose() {
    this.hasErrorNamePixel = false;
    this.hasErrorIdPixel = false;

  }
  sideBarLinks: boolean = false;
  displayDialogIconsGifs: boolean = false;
  // selectedLink()
  updateShowLink(link: Links, isChecked: boolean) {
    // Atualiza apenas a propriedade necessária sem redefinir todo o objeto
    const linkToUpdate = this.linksSelecteds.find(l => l === link);
    if (linkToUpdate) {
      linkToUpdate.show_item = isChecked;

    }
    const index = this.linksSelecteds.findIndex(link => link === this.linkSelecionado);
    if (index !== -1) {
      this.linksSelecteds[index] = { ...this.linkSelecionado };  // Cria uma nova referência para o objeto atualizado
    }
    this.linksSelecteds = [...this.linksSelecteds];
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
    this.debounceFunction();
  }
  addLinks() {
    const newLink: Links = {
      id: 0,
      name: '',
      show_link: false,
      redirect_url: '',
      click_url: '',
      position: this.linksSelecteds.length + 1,
      bg_color: '#FF0000',
      link_type: 'shortcut',
      define_interval: false,
      show_item: false,
      time_from: '00:00:00',
      time_to: '00:00:00',
      interval_time: { start: "00:00:00", end: "00:00:00" },
      slider_time_range: [0, 0]
    };

    this.linksSelecteds = [...this.linksSelecteds, newLink];

    this.defineIntervalLinkSelected = false;

    this.selectedLinkIndex = this.linksSelecteds.length - 1;  // Assegure-se que isto está correto
    this.linkSelecionado = this.linksSelecteds[this.selectedLinkIndex];
    this.sideBarLinks = true;

    this.LinkSelectedName = '';
    this.selectedLinkColor = '#FF0000';
    this.LinkSelectedURL = '';
    this.iconGifSelected = '';
    this.hasIconGif = false;
    this.isTransparent = false;
    this.intervalTimeEndLinkSelected = "00:00:00";
    this.intervalTimeStartLinkSelected = "00:00:00";
    this.sliderValue = [0, 0];
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
    this.debounceFunction();
  }


  updateLinkName(name: string) {
    if (this.selectedLinkIndex !== undefined) {
      this.linksSelecteds[this.selectedLinkIndex].name = name;
      this.debounceFunction();
    }
  }

  updateLinkURL(url: string) {
    if (this.selectedLinkIndex !== undefined) {
      this.linksSelecteds[this.selectedLinkIndex].redirect_url = url;
      this.debounceFunction();
    }
  }
  updateLinksColors(color: string) {
    if (this.selectedLinkIndex !== undefined) {
      this.linksSelecteds[this.selectedLinkIndex].bg_color = color;
      this.refreshLinks();
      this.debounceFunction();
      this.setupFilteredLinks();
      this.setupFilteredLinksShortucut();
    }
  }
  refreshLinks() {
    const index = this.linksSelecteds.findIndex(link => link === this.linkSelecionado);
    if (index !== -1) {
      this.linksSelecteds[index] = { ...this.linkSelecionado };  // Cria uma nova referência para o objeto atualizado
    }
    this.linksSelecteds = [...this.linksSelecteds]; // Atualiza o array para refletir mudanças na view
  }
  updateLinksPositions(): void {
    this.linksSelecteds.forEach((link, index) => {
      link.position = index;  // Atualiza a propriedade position baseada no índice atual do item no array
    });
    this.debounceFunction();
    this.setupFilteredLinks();
    this.setupFilteredLinksShortucut();
  }
  icons: any[] = [];

  nameIconSearch: string = "";
  nameGifSearch: string = "";
  loadingLinks: boolean = false;

  iconGifColor?: string;
  iconGifShape?: string;
  selectedColors = {
    black: false,
    white: false,
    multicolor: false,
    gradient: false
  };

  setIconGifShape(option: { label: string, value: string }) {
    this.iconGifShape = option.value;
    this.loadData({ first: 0, rows: 25 });
  }
  setIconGifColor(option: { label: string, value: string }) {
    console.log('Valor recebido:', option); // Agora espera um objeto
    this.iconGifColor = option.value;
    console.log('iconGifColor definido como:', this.iconGifColor); // Deve mostrar o valor correto agora

    this.loadData({ first: 0, rows: this.rowsIcons });
  }



  closeIconsModal() {
    this.displayDialogIconsGifs = false;
  }
  //current_page
  loadMoreData() {

  }
  totalRecordsIcons: number = 0;
  totalRecordsGifs: number = 0;
  limitIcons: number = 25;

  rowsIcons: number = 0;


  loadAllDataIcons: boolean = false;
  clearSearchIcons() {
    this.nameIconSearch = ''; // Limpa a busca
    this.icons = []; // Limpa os resultados atuais
    this.totalRecordsIcons = 0; // Reset o contador de registros
    this.valSelect1 = ''; // Reset a seleção de Cor
    this.valSelect2 = ''; // Reset a seleção de Shapes

    // Chame as funções que lidam com mudanças nesses SelectButtons, se necessário
    // Isto irá garantir que qualquer lógica adicional ligada à mudança desses valores seja executada
    // this.setIconGifColor(null);
    // this.setIconGifShape(null);

    this.cdr.detectChanges();// Se necessário para atualizar a view
  }
  clearSearchGifs() {
    this.nameGifSearch = ''; // Limpa a busca
    this.gifs = []; // Limpa os resultados atuais
    this.totalRecordsGifs = 0; // Reset o contador de registros
    this.cdr.detectChanges(); // Se necessário para atualizar a view
  }

  loadData(event: LazyLoadEvent) {
    if (!this.loadAllDataIcons) {
      console.log("O diálogo ainda não foi aberto. Carregamento de dados barrado.");
      this.loadAllDataIcons = true;
      return;
    }
    console.log("Paginação alterada:", event);
    this.loadingLinks = true;

    // Usa os valores padrão se 'event.first' ou 'event.rows' forem undefined
    const first = event.first ?? 0;
    this.rowsIcons = event.rows ?? 96;

    // Calcula a página atual baseada nos valores de 'first' e 'rows'
    const page = Math.floor(first / this.rowsIcons) + 1;

    // Chamada ao serviço com os parâmetros atualizados
    this.productsService.searchIcons(this.nameIconSearch, first, this.rowsIcons, this.iconGifColor, this.iconGifShape).pipe(takeUntil(this.destroy$)).subscribe({
      next: data => {
        this.icons = data.data;

        if (data.meta.pagination.total > 10080) {
          this.totalRecordsIcons = 10080;
        } else {
          this.totalRecordsIcons = data.meta.pagination.total;
        }
        // Supondo que a resposta tem a propriedade 'meta'
        console.log('total de itens: ' + this.totalRecordsIcons);
        this.loadingLinks = false;
        this.cdr.detectChanges();
      },
      error: err => {
        console.error('Erro ao carregar ícones:', err);
        this.loadingLinks = false;
      }
    });
  }

  gifs: Gif[] = [];


  loadDataGifs(event: LazyLoadEvent) {
    console.log("Paginação alterada em gifs:", event);
    this.loadingLinks = true;

    const first = event.first ?? 0;
    const rows = event.rows ?? 25;
    const page = Math.floor(first / rows) + 1; // Assegure que isso corresponde ao esperado pelo backend.

    this.productsService.searchGifs(this.nameGifSearch, first, rows).pipe(takeUntil(this.destroy$)).subscribe({
      next: data => {
        this.gifs = [...data.data];
        this.totalRecordsGifs = data.pagination.total_count; // Certifique-se de que isso é atualizado corretamente
        console.log(`Dados carregados: ${data.data.length} itens, página ${page}`);
        this.loadingLinks = false;
      },
      error: err => {
        console.error('Erro ao carregar GIFs:', err);
        this.loadingLinks = false;
      }
    });
  }
  private debounceTimerCTALinks: any;
  searchIcons() {
    if (this.debounceTimerCTALinks) {
      clearTimeout(this.debounceTimerCTALinks);
    }

    this.debounceTimerCTALinks = setTimeout(() => {
      this.loadData({ first: 0, rows: this.rowsIcons });
    }, 750);
  }
  valSelect1: string = 'black';
  valSelect2: string = '-';
  linkOptions = [
    { label: '-', value: '' },
    { label: 'Preto', value: 'black' },
    { label: 'Branco', value: 'white' },
    { label: 'Colorful', value: 'multicolor' },
    { label: 'Gradiente', value: 'gradient' }
  ];
  linkOptionsGif = [
    { label: '-', value: '' },
    { label: 'Outline', value: 'outline' },
    { label: 'Filed', value: 'fill' },
    { label: 'Sketch', value: 'hand-drawn' }
  ];





  searchGifs() {
    if (this.debounceTimerCTALinks) {
      clearTimeout(this.debounceTimerCTALinks);
    }

    this.debounceTimerCTALinks = setTimeout(() => {
      this.loadDataGifs({ first: 0, rows: 25 });
    }, 750);
  }
  hasIconGif: boolean = false;
  iconGifSelected?: string = '';
  getIcon(url: string) {
    this.displayDialogIconsGifs = false;
    this.hasIconGif = true;
    this.iconGifSelected = url;


    this.updateLinkIcon(url);
  }
  LinkSelectedName?: string = "";
  LinkSelectedURL?: string = "";
  selectedLinkColor?: string = "";

  selectedClickURL?: string = "";

  defineIntervalLinkSelected?: boolean = false;
  intervalTimeStartLinkSelected?: string = '00:00:00';
  intervalTimeEndLinkSelected?: string = '00:00:00';
  updateIntervalTimeLinks(values: number[]): void {
    console.log('chamou método muda inputs')
    console.log(this.sliderMaxValue); // Para depuração
    if (this.linkSelecionado && this.selectedLinkIndex !== null) {
      console.log('existe link')

      const startTime = this.secondsToTimeString(values[0]);
      const endTime = this.secondsToTimeString(values[1]);

      // Atualiza o interval_time do link selecionado
      this.linkSelecionado.interval_time!.start = startTime;
      this.linkSelecionado.interval_time!.end = endTime;

      // Atualizar os campos da interface de usuário
      this.intervalTimeStartLinkSelected = startTime;
      this.intervalTimeEndLinkSelected = endTime;

      console.log('Intervalo atualizado para:', startTime, 'a', endTime);
      this.linkSelecionado.time_from = startTime;
      this.linkSelecionado.time_to = endTime;
      this.linkSelecionado.define_interval = this.defineIntervalLinkSelected;
      this.debounceFunction();
      if (this.selectedLinkIndex) {
        // Essa linha é crucial para garantir que as mudanças sejam refletidas na interface do usuário
        this.linksSelecteds[this.selectedLinkIndex] = { ...this.linkSelecionado };

        this.setupFilteredLinks();
        this.setupFilteredLinksShortucut();
        // Opcional: Se você precisa de algum tratamento adicional ou recálculo com base na mudança de intervalos
        this.debounceFunction();
      }
    }
  }

  toggleDefineIntervalLinks(newValue: boolean): void {

    if (this.selectedLinkIndex != null && this.linksSelecteds[this.selectedLinkIndex]) {
      this.linksSelecteds[this.selectedLinkIndex].define_interval = newValue;
      // if(newValue){
      //   this.linksSelecteds[this.selectedLinkIndex].show_item = false;
      // }
      // /updateIntervalTimeLinks
      this.linksSelecteds[this.selectedLinkIndex] = { ...this.linkSelecionado };

      console.log('Intervalo definido alterado para:', newValue, 'no item:', this.linksSelecteds[this.selectedLinkIndex]);
      this.cdr.detectChanges();
      this.setupFilteredLinks();
      this.setupFilteredLinksShortucut();
      this.debounceFunction();
    }
  }
  linkSelecionado: Links = {
    id: 0,
    name: '',
    show_link: false,
    bg_color: '',
    position: 0,
    redirect_url: '',
    click_url: '',
    link_type: 'shortcut', // Ou qualquer valor padrão apropriado
    define_interval: false,
    show_item: false,
    time_from: '',
    time_to: '',
    interval_time: { start: '00:00:00', end: '00:00:00' },
    slider_time_range: [0, 0]
  };
  selectedLinkIndex?: number = 0;
  selectedLink(linkSelected: Links, index: number) {
    this.selectedLinkIndex = index; // Atualiza sempre o índice selecionado
    this.linkSelecionado = linkSelected;

    this.LinkSelectedName = linkSelected.name;
    this.LinkSelectedURL = linkSelected.redirect_url;
    this.selectedLinkColor = linkSelected.bg_color;
    this.defineIntervalLinkSelected = linkSelected.define_interval;

    this.intervalTimeStartLinkSelected = linkSelected.interval_time?.start || "00:00:00";
    this.intervalTimeEndLinkSelected = linkSelected.interval_time?.end || "00:00:00";

    // Converte os tempos em segundos para usar no slider
    const startSeconds = this.timeToSeconds(this.intervalTimeStartLinkSelected);
    const endSeconds = this.timeToSeconds(this.intervalTimeEndLinkSelected);

    // Atualiza o valor do slider
    this.sliderValue = [startSeconds, endSeconds];

    if (linkSelected.click_url != '' && linkSelected.click_url != 'theny') {
      this.hasIconGif = true;
      this.iconGifSelected = linkSelected.click_url;
    } else {
      this.hasIconGif = false;
    }

    if (linkSelected.bg_color == 'transparent') {
      this.isTransparent = true;
    } else {
      this.isTransparent = false;
    }

    //this.linkSelecionado = linkSelected;
    this.selectedLinkIndex = index;
    this.sideBarLinks = true;
  }


  updateLinkIcon(url: string) {
    if (this.selectedLinkIndex !== undefined) {
      this.linksSelecteds[this.selectedLinkIndex].click_url = url;  // Atualiza a URL da imagem do ícone/GIF
      this.debounceFunction();  // Chama uma função debounced para aplicar possíveis mudanças
    }
  }
  saveNewLink(): void {
    const newLink: Links = {
      id: 0,
      name: this.LinkSelectedName,
      bg_color: this.selectedLinkColor,
      redirect_url: this.LinkSelectedURL,
      click_url: this.iconGifSelected,
      show_link: true, // Suponho que isso pode ser true ou depende de outra lógica
      position: this.linksSelecteds.length + 1, // Um exemplo simples para determinar a posição
      define_interval: false, // Valor default ou dependente de outra entrada do usuário
      show_item: true, // Suponho que seja visível por default
      interval_time: { start: '', end: '' }, // Valores default ou lógica específica
      time_from: '',
      time_to: '',
      slider_time_range: [0, 100] // Valores default ou entrada do usuário
    };
    //this.linksSelecteds.push(newLink);
    this.linksSelecteds = [...this.linksSelecteds, newLink];
    this.debounceFunction();
  }

  linkTypes = [
    { label: 'Shortcut', value: 'shortcut', iconUrl: 'https://cdn-icons-png.freepik.com/128/9970/9970692.png' },
    { label: 'Swiper', value: 'swiper', iconUrl: 'https://cdn-icons-png.freepik.com/128/9417/9417114.png' }
  ];



  selectedType?: string;

  //activeLinkOption: any;  // Supõe-se que você tenha essa variável definida em algum lugar do seu componente
  tempIconGifUrl: string = '';  // Guarda temporariamente a URL do ícone/GIF
  errorGif: boolean = false;
  // Método para detectar mudanças no input
  onInputChange2(): void {
    this.errorGif = false;
  }
  selectIconGif(url: string): void {
    this.errorGif = false;
    // Verifica se a URL termina com uma das extensões de imagem permitidas
    const validImageExtensions = /\.(png|jpg|jpeg|gif|webp|svg|ico|bmp|heif|avif|tiff)$/i;

    if (!validImageExtensions.test(url)) {
      this.errorGif = true;
      return;
    }

    if (url && validImageExtensions.test(url)) {
      this.iconGifSelected = url;  // Atribui a URL temporária à variável final
      this.tempIconGifUrl = '';
      this.linkSelecionado.click_url = url;

      const index = this.linksSelecteds.findIndex(link => link === this.linkSelecionado);
      if (index !== -1) {
        this.linksSelecteds[index] = { ...this.linkSelecionado };  // Cria uma nova referência para o objeto atualizado
      }
      this.linksSelecteds = [...this.linksSelecteds];

      this.hasIconGif = true;
      this.displayDialogIconsGifs = false;
      this.debounceFunction();
      this.setupFilteredLinks();
      this.setupFilteredLinksShortucut();
    } else {
      console.error('URL inválida ou não aponta para uma imagem.');
      this.hasIconGif = false;
    }
  }
  // confirmRedirect
  confirmRedirect(): void {
    localStorage.setItem(this.tempUrl, 'confirmed'); // Salva a confirmação no localStorage
    this.navigateTo(this.tempUrl);
    this.displayDialog = false;
  }
  async cancelRedirect(): Promise<void> {
    this.displayDialog = false;
    await this.closePictureInPicture();
    //this.player?.play(); // Opcional: Reiniciar o vídeo após cancelar
  }
  async closePictureInPicture(): Promise<void> {
    if (document.pictureInPictureElement) {
      try {
        await document.exitPictureInPicture();
      } catch (error) {
        console.error('Erro ao fechar PiP:', error);
      }
    }
  }
  navigateTo(url: string | undefined): void {
    if (!url) return;  // Verifica se URL é nula ou vazia

    if (url.startsWith('http')) {
      window.open(url, '_blank');
    } else {
      window.open('https://' + url, '_blank');
    }
    // Verifica se a URL é externa

  }
  tempUrl: string = '';
  async handleLinkClick(link: any): Promise<void> {
    this.tempUrl = link.redirect_url;
    this.displayDialog = true;
    //this.player?.pause(); // Pausar o vídeo

    // Iniciar PiP
    if (this.player && document.pictureInPictureEnabled) {
      try {
        await this.player.requestPictureInPicture();
      } catch (error) {
        console.error('Erro ao iniciar PiP:', error);
      }
    }
  }
  getLinkIcon(linkType: string): string {
    if (linkType === 'shortcut') {
      return 'https://cdn-icons-png.freepik.com/128/9970/9970692.png';
    } else if (linkType === 'swiper') {
      return 'https://cdn-icons-png.freepik.com/128/9417/9417114.png';
    }
    return ''; // Pode retornar uma imagem padrão ou uma string vazia caso não tenha correspondência
  }

  handleCanPlayThrough() {
    console.log('Video can play through without interruption.');
  }


  replaceDomain(url: string | null | undefined): string | null | undefined {
    const s3Domain = 'https://krabo163508-dev.s3.sa-east-1.amazonaws.com';
    const cloudFrontDomain = 'https://d2434dp583bttd.cloudfront.net';
    if (url && url.includes(s3Domain)) {
      return url.replace(s3Domain, cloudFrontDomain);
    }
    return url; // Retorna a URL original, que pode ser undefined
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.linksSelecteds, event.previousIndex, event.currentIndex);
    this.updateLinksPositions();
  }


  onVideoError(event: any): void {
    //console.error('Video Error:', event);
    // const videoElement = event.target as HTMLVideoElement;
    // console.log('Video Source:', videoElement.src);
    // console.log('Network State:', videoElement.networkState);
    // console.log('Ready State:', videoElement.readyState);
  }

  onVideoLoaded(event: any): void {
    // console.log('Video Loaded:', event);
    // const videoElement = event.target as HTMLVideoElement;
    // console.log('Video Source:', videoElement.src);
    // console.log('Network State:', videoElement.networkState);
    // console.log('Ready State:', videoElement.readyState);
  }
  columnSize?: string;
  itemsDock: MenuItem[] | undefined;
  openFinder() {

  }

  loadingPage: boolean = false;
  ngOnInit(): void {


    this.breakpointObserver.observe([
      '(max-width: 993px)'
    ]).subscribe(result => {
      // Aqui adicionamos a verificação de this.valSelectviewMode
      if (result.matches && this.valSelectviewMode == 1) {
        this.columnSize = 'col-12 md:col-8 lg:col-8'; // Colunas para viewMode == 1 e tela pequena
      } else {
        this.columnSize = 'col-12'; // Coluna padrão para outros casos
      }
    });


    this.pauseSlick = false;
    this.typeLive = false;
    // items
    this.fullScreen = false;
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.id = this.uuid;
    if (this.uuid) {
      this.loadProducts();

    }
    this.menuitems = [
      { label: 'View', icon: 'pi pi-search' },
      { label: 'Refresh', icon: 'pi pi-refresh' }
    ]

    this.landscapeOptions = [
      {
        label: 'Player', command: () => {
          this.addItem();
        }
      },
      {
        label: 'CTA', command: () => {
          this.addItem();
        }
      },
      {
        label: 'Chat', command: () => {
          this.addItem();
        }
      },
      {
        label: 'Configurações', command: () => {
          this.addItem();
        }
      },
      {
        label: 'Analytics', command: () => {
          this.addItem();
        }
      },
      { label: 'Integrações', routerLink: 'lives' },
    ];
    this.landscapeOptions2 = [
      { icon: 'pi pi-mobile', name: 'Vertical', value: 1 },
      { icon: 'pi pi-mobile rotate-90', name: 'Horizontal', value: 2 },
    ];
    this.landscapeOptions3 = [
      { icon: 'pi pi-mobile rotate-minus-90', name: '', value: 1 },
      { icon: 'pi pi-desktop rotate-minus-90', name: '', value: 2 },
    ];
    this.valSelectviewMode = this.landscapeOptions3[0];
    console.log('valSelectviewMode: ', this.valSelectviewMode);
    this.ctaOptions = [

      { label: 'Custom', icon: 'algum-icon', command: () => this.selecionarOpcao(2) },
      { label: 'Produtos', icon: 'algum-icon', command: () => this.selecionarOpcao(1) },
      { label: 'Whatsapp', icon: 'algum-icon', command: () => this.selecionarOpcao(3) },
      //{ label: 'Promoções', icon: 'algum-icon', command: () => this.selecionarOpcao(4) },
      { label: 'Alertas', icon: 'algum-icon', command: () => this.selecionarOpcao(5) },
      { label: 'Links', icon: 'algum-icon', command: () => this.selecionarOpcao(6) },
    ];


    this.linksOptions = [
      { label: 'Ícones', icon: 'pi pi-fw pi-images', command: () => { this.activeLinkOption = this.linksOptions[0]; } },
      { label: 'Custom', icon: 'pi pi-fw pi-pencil', command: () => { this.activeLinkOption = this.linksOptions[1]; } },
      { label: 'GIFS', icon: 'pi pi-fw pi-file', command: () => { this.activeLinkOption = this.linksOptions[2]; } },
    ];
    this.activeLinkOption = this.linksOptions[0];



    this.activeItem = this.ctaOptions[0];


    this.valSelect1Video = this.landscapeOptions2[0];
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);

    // this.photoService.getImages().then(images => {
    //   this.images = images;
    // });

    this.menuInferior = [
      {
        label: 'Ver Slick',
        icon: 'pi pi-eye',
        command: (event) => {
          this.redirectToSlick();
        }
      },
      {
        label: 'Compartilhar',
        icon: 'pi pi-share-alt',
        command: (event) => {
          // Chame o método desejado aqui
          this.compartilhar();
        }
      }
      // },
      // {
      //   label: 'RTMP infos',
      //   icon: 'bi bi-pencil',
      //   command: (event) => {
      //     // Chame o método desejado aqui
      //     this.rtmpInfos();
      //   }
      // },
      // {
      //   label: 'Recarregar Saldo',
      //   icon: 'pi pi-fw pi-star',
      //   command: (event) => {
      //     this.recarregarSaldo();
      //   }
      // }
    ];

    //this.loadPromotions();
    this.selectedIntegration = this.integrations[1];

    //updateColors
    this.subscription = this.pickListObserverService.pickListChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.saveAsset(0);
    });

    this.qrCodeLoad();
    this.targetTime = Array.from({ length: 10 }, (_, i) => ({
      label: `${(i + 1) * 10}%`,
      value: (i + 1) * 10,
    }));

    this.loadDataChipsMaps();

    this.subscription2 = this.stateManagementService.getIconColor().pipe(takeUntil(this.destroy$)).subscribe(color => {
      this.iconColor = color;
    });

    this.subscriptionAlerts = this.stateManagementService.alertChange$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (change) => {
        if (change.index >= 0 && change.index < this.alertsTabs.length) {
          if (change.text !== undefined) {
            this.alertsTabs[change.index].text = change.text;
          }
          if (change.fontColor !== undefined) {
            this.alertsTabs[change.index].font_color = change.fontColor;
          }
          if (change.backgroundColor !== undefined) {
            this.alertsTabs[change.index].background = change.backgroundColor;
          }
        }
      },
    });
    // this.checkCameras();


    if (this.observer) {
      this.observer.disconnect();
    }



    this.fakeBarSubscription = this.fakeBar$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.fakeBar = value; // Atualiza sua variável local fakeBar
      this.updateProgressBar(); // Chama a função para atualizar a barra de progresso e o marcador
    });


    this.stopRecordingTimer();
    this.cliclkStop = false;

    window.addEventListener('beforeunload', this.boundBeforeUnloadHandler);

    this.getStoreData();


    // this.center = this.center + this.bottom3 + this.bottom2;
    // this.bottom3 = 0;
    // //this.bottom2 = 0;



  }

  // XSmall: Telas menores que 600px de largura.
  // Small: Telas de 600px até 959px de largura.
  // Medium: Telas de 960px até 1279px de largura.
  // Large: Telas de 1280px até 1919px de largura.
  // XLarge: Telas maiores que 1920px de largura.



  principal: boolean = false;
  hasAlerts: boolean = true;
  gridTemplateRows: string = '8% 10% 34.5% 10% 15% 12% 10.5%';
  gridTemplateRowsMobileFullScreen: string = '8% 10% 35.5% 10% 18% 8% 10.5%';

  updateGridRows() {
    // Ajuste as proporções do grid quando não há alertas
    this.gridTemplateRows = this.hasAlerts ? '8% 10% 34.5% 10% 15% 12% 10.5%' : '8% 0% 44.5% 10% 15% 12% 10.5%';
  }
  hasProducts: boolean = false;
  hasLinksSwiper: boolean = false;


  hasVisibleButton: boolean = false;
  hasVisibleAlerts: boolean = false;
  hasVisibleProducts: boolean = false;
  hasVisibleLinksSwiper: boolean = false;
  checkLinks(): void {
    if (this.filteredLinks$)
      this.filteredLinks$.subscribe(links => {
        this.hasLinksSwiper = links.length > 0;
      });
  }

  removeBtnCustom: boolean = true;
  hasVisibleDesc: boolean = false;
  removeAlerts: boolean = true;
  centerTmp: number = 40.5;
  sumTotal: number = 0;

  sum100() {
    this.sumTotal = this.top1 + this.top2 + this.center + this.bottom0 + this.bottom1 + this.bottom2 + this.bottom3;
    let diff = 100 - this.sumTotal;
    if (diff > 0) {
      this.center = this.center + diff;
    } else if (diff < 0) {
      this.center = this.center - diff;
    }
    this.gridTemplateRows = `${this.top1}% ${this.top2}% ${this.center}% ${this.bottom0}% ${this.bottom1}% ${this.bottom2}% ${this.bottom3}%`;
  }

  async updateGridRowsProducts() {
    // if (!this.hasVisibleAlerts) {
    //   if (this.top2 > 0) {  // Certifique-se de que só ajusta se top2 ainda não foi ajustado
    //     this.center += this.top2;
    //     this.top2 = 0;
    //     this.sum100();
    //   }
    // } else {
    //   if (this.top2 === 0) {  // Reverte se necessário
    //     this.top2 = 10;
    //     this.center -= this.top2;
    //     this.sum100();
    //   }
    // }

    // //////////////////////////////////
    // if (this.slickDesc === '' || this.slickDesc == null) {
    //   this.hasVisibleDesc = false;
    // } else {
    //   this.hasVisibleDesc = true;
    // }

    // if (!this.hasVisibleButton && !this.hasVisibleLinksSwiper && !this.hasVisibleDesc) {
    //   if (this.bottom3 > 0) {
    //     this.center += this.bottom3;
    //     this.bottom3 = 0;
    //     this.sum100();
    //   }
    // } else {
    //   let newBottom3 = 0;
    //   if (this.hasVisibleButton) {
    //     if (this.showBtnVerMaisFechar) {
    //       newBottom3 += 16;
    //     } else {
    //       newBottom3 += 8;
    //     }
    //   }
    //   if (this.hasVisibleLinksSwiper) {
    //     newBottom3 += 12;  // altura de bottom3 original
    //   }
    //   if (this.hasVisibleDesc) {
    //     newBottom3 += 9;  // altura de bottom0
    //   }


    //   if (newBottom3 === 0 && this.bottom3 > 0) {
    //     this.center += this.bottom3;  // Adiciona a altura de bottom3 ao centro
    //     this.bottom3 = 0;
    //     this.sum100();
    //   } else if (newBottom3 > 0 && this.bottom3 !== newBottom3) {
    //     this.center -= (newBottom3 - this.bottom3);  // Ajusta o centro ao reduzir ou aumentar bottom3
    //     this.bottom3 = newBottom3;
    //     this.sum100();
    //   }
    // }
    // this.sum100();
    // ///////////////////////////////////////////

    // // Continue com outros ajustes conforme necessário

    // // SlickDesc e TargetProducts
    // if (this.slickDesc === '' || this.slickDesc == null) {
    //   if (this.bottom0 > 0) {
    //     this.center += this.bottom0;
    //     this.bottom0 = 0;
    //     this.sum100();
    //   }
    // }
    // if (this.targetProducts.length === 0) {
    //   if (this.bottom2 > 0) {
    //     this.center += this.bottom2;
    //     this.bottom2 = 0;
    //     this.sum100();
    //   }
    // }
  }

  combinedStylesBar() {
    let progressBarTop = '0px'; // Valor padrão

    if (this.gridContainer) {
      const gridHeight = this.gridContainer.nativeElement.offsetHeight;
      progressBarTop = `${gridHeight - 8.2}px`; // Posição dinâmica para a barra
    }
    return {
      'z-index': '15000',
      'top': progressBarTop,
      'right': '0px',
      'position': 'absolute'
    };
  }

  @ViewChild('bottomDiv') bottomDiv!: ElementRef<HTMLDivElement>;
  dynamicHeight: string = 'auto';
  handleTruncatedDesc(): void {
    if (this.slickDesc) {

      if (this.valSelectviewMode.value === 1) {
        if (this.slickDesc.length > 25) {
          this.truncatedDesc = `${this.slickDesc.substring(0, 25)}...`;
          this.showBtnVerMais = true;
        } else {
          this.truncatedDesc = this.slickDesc;
          this.showBtnVerMais = false;

        }
      } else {
        if (this.slickDesc.length > 120) {
          this.truncatedDesc = `${this.slickDesc.substring(0, 120)}...`;
          this.showBtnVerMais = true;
        } else {
          this.truncatedDesc = this.slickDesc;
          this.showBtnVerMais = false;

        }
      }



    }
  }
  @ViewChild('progressBarTime') progressBarTime!: ElementRef;
  private setOpacity(progressOpacity: number, gridOpacity: number): void {
    if (this.progressBarTime && this.progressBarTime.nativeElement) {
      if (progressOpacity == 1) {
        this.progressBarTime.nativeElement.classList.add('visible2');
        this.progressBarTime.nativeElement.classList.remove('hiddenBar');
      } else {
        this.progressBarTime.nativeElement.classList.add('hiddenBar');
        this.progressBarTime.nativeElement.classList.remove('visible2');
      }
    }

    if (this.gridContainer && this.gridContainer.nativeElement) {
      this.gridContainer.nativeElement.style.opacity = gridOpacity.toString();
    }
  }
  @ViewChild('gridContainer') gridContainer?: ElementRef<HTMLDivElement>;
  private resizeObserverBreakPoints?: ResizeObserver;
  resizeObserver?: ResizeObserver; // quebras de tela para definir largura de colunas do grid
  showBtnVerMais: boolean = true;
  showBtnVerMaisFechar: boolean = false;
  truncatedDesc?: string = '';
  fullViewActive: boolean = false;
  goVerMaisFechar() {
    this.fullViewActive = false;
    this.showBtnVerMais = true;
    this.showBtnVerMaisFechar = false;
    this.center = this.center + 8;
    this.bottom3 = this.bottom3 - 8;
    this.gridTemplateRows = `${this.top1}% ${this.top2}% ${this.center}% ${this.bottom0}% ${this.bottom1}% ${this.bottom2}% ${this.bottom3}%`;
    this.handleTruncatedDesc();
    this.sum100();
  }
  goVerMais() {
    this.fullViewActive = true;
    this.showBtnVerMaisFechar = true;
    this.showBtnVerMais = false;
    this.center = this.center - 8;
    this.bottom3 = this.bottom3 + 8;
    this.truncatedDesc = this.slickDesc;
    this.gridTemplateRows = `${this.top1}% ${this.top2}% ${this.center}% ${this.bottom0}% ${this.bottom1}% ${this.bottom2}% ${this.bottom3}%`;
    this.sum100();
  }
  toggleAlerts() {
    this.hasAlerts = !this.hasAlerts;
    this.updateGridRows();
  }
  liked: boolean = false;
  sendLike() {
    this.liked = !this.liked;
  }


  tempoAtual = new BehaviorSubject<string>('');
  durationTotal: string = '00:00';
  maxTimeWidth: number = 200;
  get timeWidth(): number {
    let currentTime = parseInt(this.tempoAtual.value);
    let totalDuration = parseInt(this.durationTotal);
    return Math.min((currentTime / totalDuration) * this.maxTimeWidth, this.maxTimeWidth);
  }

  get progressContainerWidth(): string {
    return `100%`;
  }
} 
