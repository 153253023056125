import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sku: number | null = null;

  // Define o SKU
  setSku(sku: number): void {
    this.sku = sku;
    console.log('chegou no service: ', this.sku)
  }

  // Retorna o SKU
  getSku(): number | null {
    return this.sku;
  }

  // Limpa o SKU
  clearSku(): void {
    this.sku = null;
  }
}
