<swiper #swiper slidesPerView="auto" [spaceBetween]="spaceBetween" [navigation]="true" [pagination]="false"
  [loop]="false" (activeIndexChange)="onSwiperActiveIndexChange()">
  <ng-container *ngFor="let video of videoUrls; let idx = index; trackBy: trackByFn">
    <ng-template swiperSlide>
      <div class="carousel-item">
        <div [class]="itemClass" (click)="!isDragging && openModal(video)" class="ss-l-video-container-widget "
          *ngIf="!video.isLoading; else loadingTemplate">
          <video [poster]="video.thumbnail" class=" ">
            <source [src]="video.src" type="video/mp4">
            Seu navegador não suporta a tag de vídeo.
          </video>

          <div *ngIf="showDescription" style="max-width: 90%;"
            class="py-1 px-2 text-base card border-none text-left shadow-none font-semibold"
            [style.background]="backgroundColor" [style.color]="font_color" [ngClass]="['p-description', position]">
            {{video.title}}</div>
        </div>

        <ng-template #loadingTemplate>
          <div class="loading-spinner">
            <i class="pi pi-spin pi-spinner text-4xl"></i>
          </div>
        </ng-template>
      </div>
      <div *ngIf="showProducts && video.products && video.products.length > 0" class="product mt-2">
        <div class="link-container cursor-pointer" (click)="openProductSidebar(video.products[0].sku)">
          <div class="link-card">
            <div class="text-xs ss-l-discount-badge font-bold">50% OFF</div>
            <div class="link-image">
              <img [src]="video.products[0].image || 'assets/imgTmp.webp'" alt="Produto" class="image-style"
                loading="lazy">
            </div>
            <div class="link-name px-2">
              <div class="link-txt text-xs">{{ video.products[0].name }}</div>
              <div class="flex gap-1 align-items-center ss-l-values">
                <div *ngIf="video.products[0].price" class="link-value text-lg font-bold">
                  R$ {{ video.products[0].price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </ng-template>
  </ng-container>
</swiper>

<p-sidebar [(visible)]="showAllProductsSidebar2" position="right" 
  class="ss-g-custom-sidebar-width ss-l-pSidebarChat ss-l-sideBarBottom ss-l-productsSidebar z-9 p-0">

  <app-show-products [sku]="selectedSku"></app-show-products>

</p-sidebar>


<!-- Modal Carousel -->
<app-video-modal [videoUrls]="selectedVideoUrls" [widget_id]="widget_id" [show]="isModalOpen" [height]="height"
  [arraySlicksFull]="arraySlicksFull" [selectedVideo]="selectedVideoUrls[0]" [totalAssets]="totalAssets"
  (close)="isModalOpen = false"></app-video-modal>